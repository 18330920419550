import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getPageText_url, token } from "../../constants/urls";
const intitalState = {
  success: false,
  error: false,
  data: null,
  loading: false,
};

export const getPageText = createAsyncThunk("page/text", async (url) => {
  const res = await axios.post(
    `${getPageText_url}`,
    {
      url: url,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  );
  const data = await res.data;
  return data;
});

export const getPageTextSlice = createSlice({
  name: "singProduct",
  initialState: intitalState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPageText.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getPageText.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.loading = false;
      state.success = true;
    });

    builder.addCase(getPageText.rejected, (state, action) => {
      state.error = true;
      state.errorMsg = action.error.message;
      state.loading = false;
      state.success = false;
    });
  },
});

export default getPageTextSlice.reducer;
