import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  deleteShippingAddressUrl,
  getCountryUrl,
  getStateUrl,
  getUserAddressUrl,
  saveShippingAddressUrl,
  updateShippingAddressUrl,
} from "../../constants/urls";
const initialState = {
  states: [],
  country: [],
  userAddresses: [],
  isLoading: false,
  error: null,
  addAddress: {
    success: false,
    isLoading: false,
    error: false,
  },
  updateAddress: {
    success: false,
    isLoading: false,
    error: false,
  },
  deleteAddress: {
    success: false,
    isLoading: false,
    error: false,
  },
};

const token = localStorage.getItem("token");

export const getState = createAsyncThunk(
  "address/state",
  async (countryCurrency) => {
    try {
      const res = await axios.get(
        `${getStateUrl}/${
          countryCurrency == "INR"
            ? "101"
            : countryCurrency == "USD"
              ? "231"
              : countryCurrency == "GBP"
                ? "230"
                : countryCurrency == "CAD"
                  ? "31"
                  : "101"
        }`,
      );
      return res.data.data;
    } catch (error) {
      console.error("Error state: ", error);
      throw error;
    }
  },
);
//   countryCurrency == "INR"
//     ? formatCurrency(data?.product_price)
//     : countryCurrency == "USD"
//     ? formatCurrency(data?.usa_price)
//     : countryCurrency == "GBP"
//     ? formatCurrency(data?.uk_price)
//     : formatCurrency(data?.usa_price),
export const getCountry = createAsyncThunk(
  "address/country",
  async (countryCurrency) => {
    try {
      const res = await axios.get(getCountryUrl);
      // return countryCurrency == "INR"
      //   ? res.data.data
      //   : countryCurrency == "USD"
      //   ? [
      //       {
      //         id: 231,
      //         name: "USA",
      //         code: "USD",
      //       },
      //     ]
      //   : countryCurrency == "GBP"
      //   ? [
      //       {
      //         id: 230,
      //         name: "UK",
      //         code: "GBP",
      //       },
      //     ]
      //   : res.data.data;
      return [
        ...res.data.data,
        {
          id: 231,
          name: "USA",
          code: "USD",
        },
        {
          id: 230,
          name: "UK",
          code: "GBP",
        },
        {
          id: 31,
          name: "Canada",
          code: "CAD",
        },
      ];
    } catch (error) {
      console.error("Error country: ", error);
      throw error;
    }
  },
);

export const getUserAddresses = createAsyncThunk(
  "address/userAddress",
  async () => {
    try {
      const res = await axios.get(getUserAddressUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.data;
    } catch (error) {
      console.error("Error user address: ", error);
      throw error;
    }
  },
);
export const addNewAddress = createAsyncThunk(
  "address/Add_new_Address",
  async (details) => {
    try {
      const res = await axios.post(saveShippingAddressUrl, details, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      return res.data.data;
    } catch (error) {
      throw error;
    }
  },
);

export const updateAddress = createAsyncThunk(
  "address/Update_new_Address",
  async ({ details, id }) => {
    try {
      const res = await axios.post(
        `${updateShippingAddressUrl}/${id}`,
        details,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        },
      );
      return res.data.data;
    } catch (error) {
      throw error;
    }
  },
);

export const deleteAddress = createAsyncThunk(
  "address/Delete_new_Address",
  async (id) => {
    try {
      const res = await axios.get(`${deleteShippingAddressUrl}/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      return res.data.data;
    } catch (error) {
      throw error;
    }
  },
);

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    resetState: (state, action) => {
      state.states = [];
    },
    resetAddNewAddress: (state) => {
      state.addAddress = {
        isLoading: false,
        error: false,
        success: false,
      };
    },
    resetUpdateAddress: (state) => {
      state.updateAddress = {
        isLoading: false,
        error: false,
        success: false,
      };
    },
    resetDeleteAddress: (state) => {
      state.deleteAddress = {
        isLoading: false,
        error: false,
        success: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getState.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getState.fulfilled, (state, action) => {
      state.isLoading = false;
      state.states = action.payload;
    });

    builder.addCase(getState.rejected, (state, action) => {
      state.error = action.payload;
    });

    builder.addCase(getCountry.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCountry.fulfilled, (state, action) => {
      state.isLoading = false;
      state.country = action.payload;
    });

    builder.addCase(getCountry.rejected, (state, action) => {
      state.error = action.payload;
    });

    builder.addCase(getUserAddresses.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getUserAddresses.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userAddresses = action.payload;
    });

    builder.addCase(getUserAddresses.rejected, (state, action) => {
      state.error = action.payload;
    });

    //  add nre Address
    builder.addCase(addNewAddress.pending, (state, action) => {
      state.addAddress.isLoading = true;
    });

    builder.addCase(addNewAddress.fulfilled, (state, action) => {
      state.addAddress.isLoading = false;
      state.addAddress.success = true;
    });

    builder.addCase(addNewAddress.rejected, (state, action) => {
      state.addAddress.isLoading = false;
      state.addAddress.error = true;
    });

    //  update Address'
    builder.addCase(updateAddress.pending, (state, action) => {
      state.updateAddress.isLoading = true;
    });

    builder.addCase(updateAddress.fulfilled, (state, action) => {
      state.updateAddress.isLoading = false;
      state.updateAddress.success = true;
    });

    builder.addCase(updateAddress.rejected, (state, action) => {
      state.updateAddress.isLoading = false;
      state.updateAddress.error = true;
    });
    //  delete Address
    builder.addCase(deleteAddress.pending, (state, action) => {
      state.deleteAddress.isLoading = true;
    });

    builder.addCase(deleteAddress.fulfilled, (state, action) => {
      state.deleteAddress.isLoading = false;
      state.deleteAddress.success = true;
    });

    builder.addCase(deleteAddress.rejected, (state, action) => {
      state.deleteAddress.isLoading = false;
      state.deleteAddress.error = true;
    });
  },
});

export const {
  resetState,
  resetAddNewAddress,
  resetUpdateAddress,
  resetDeleteAddress,
} = addressSlice.actions;

export default addressSlice.reducer;
