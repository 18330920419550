import React from "react";
import { CustomContainer } from "./global-display";

const GlobalTitle = ({
  title = "",
  color = "black",
  subTitle = "",
  description = "",
  subTitleDes = "",
  ...rest
}) => {
  return (
    <div className="my-7 text-center global-title">
      {subTitle && <p className="">{subTitle}</p>}
      <h2 {...rest} style={{ color: color }} className=" captilize-text my-3">
        {title}
      </h2>
      {subTitleDes && <h3 className="my-3">{subTitleDes}</h3>}
      {description && (
        <CustomContainer className="mt-0 mb-0">
          <p className="sub-line">{description}</p>
        </CustomContainer>
      )}
    </div>
  );
};

export default GlobalTitle;
