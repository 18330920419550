export const dropHintEmailModal = [
    {
        type: "email",
        name: "recipientEmail",
        label: "Recipient's Email",
        size: 12,
        required: true,
    },
    {
        type: "email",
        name: "email",
        label: "Your Email",
        size: 12,
        required: true,
    },
    { type: "text", name: "name", label: "Name", size: 12, required: true },
    // { type: "text", name: "note", label: "Add Note", size: 12, required: false },
    {
        type: "textarea",
        name: "note",
        label: "Add Note",
        size: 12,
        required: false,
        rows: "2",
    },
    // {
    //     type: "checkbox",
    //     name: "copyEmail",
    //     label: "Send me a copy of this email",
    //     size: 12,
    //     required: false,
    // },
    // {
    //     type: "checkbox",
    //     name: "emailUpdate",
    //     label: "I would like to receive updates by email",
    //     size: 12,
    //     required: false,
    // },
];

export const emailModal = [

    { type: "text", name: "firstName", label: "First Name", size: 12, required: true },
    { type: "text", name: "lastName", label: "Last Name", size: 12, required: true },
    {
        type: "email",
        name: "email",
        label: "Your Email",
        size: 12,
        required: true,
    },
    { type: "text", name: "phone", label: "Phone (Optional)", size: 12, required: false },
    {
        type: "textarea",
        name: "note",
        label: "Meassage",
        size: 12,
        required: false,
        rows: "2",
    },
    // {
    //     type: "checkbox",
    //     name: "emailUpdate",
    //     label: "Send me updates on new styles and special offers.",
    //     size: 12,
    //     required: false,
    // },
];
