import React from "react";

const AbountCounter = () => {
  return (
    <>
      <div className="my-6">
        <div
          className=" text-primary d-none d-xs-flex justify-content-center align-items-center text-center"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            marginBottom: "80px",
          }}
        >
          {/* console.log(first) */}
          <h5 className="p-4 px-4">
            Carbon-Neutral
            <br />
            Diamond Labs
          </h5>
          <h5 className="p-4 px-4 border-right border-left">
            Fully-Recyclable
            <br />
            Packaging
          </h5>
          <h5 className="p-4 px-4">Ethical</h5>
        </div>
        <div
          className="text-primary d-xs-none justify-content-center align-items-center text-center "
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            marginBottom: "80px",
          }}
        >
          <h2 className="p-4 px-5">
            Carbon-Neutral
            <br />
            Diamond Labs
          </h2>
          <h2 className="p-4 px-5 border-top-2 border-bottom-2">
            Fully-Recyclable
            <br />
            Packaging
          </h2>
          <h2 className="p-4 px-5">Ethical</h2>
        </div>
      </div>
    </>
  );
};

export default AbountCounter;
