import React from "react";

import { useDynamicText } from "../../Hooks/useDynamicText";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import { weddingReviewProduct } from "../../constants/utils";
import { imageURL2 } from "../../images";
import EngagementNoDealBreaker from "../Engagement/EngagementNoDEalBreaker";
import EngagementGridHomeRong from "../Engagement/EngagmentGridMetal";
import GlobalReview from "../common/global-review";
import GlobalTitle from "../common/global-title";
import PopularWeddingRings from "./PopularWeddingRings";
import WeddingEngageCatSlider from "./WeddingEngageCatSlider";
import WeddingFAQ from "./WeddingFAQ";
import FindSizeArea from "./findSizeArea";
import MenWomenArea from "./menWomenArea";
import RingParingArea from "./ringParingArea";

const Wedding = () => {
  const text = useDynamicText();
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");
  // const Reviews = useSelector((state) => state?.reviews?.data);
  const swipeData = [
    {
      link: "/anniversary-bands",
      name: "Annivercary",
      image: imageURL2.image_39,
      alt: "Annivercary",
    },
    {
      link: "/eternity-bands",
      name: "Eternity-bands",
      image: imageURL2.image_40,
      alt: "Eternity-bands",
    },
    {
      link: "/toi-et-moi",
      name: "Toi et moi",
      image: imageURL2.image_41,
      alt: "Toi et moi",
    },
  ];
  return (
    <>
      {/* Hero Section */}
      <img
        src={isAboveDesktop ? imageURL2.image_38 : imageURL2.image_149}
        className=" engagment-hero-section-master"
        alt="Hero bg"
      />
      <MenWomenArea />

      <div className=" my-6 engagement-list container position-relative ">
        {/* <h2 className="heading-55 text-center mb-5 my-7">{text?.text_28}</h2> */}
        <GlobalTitle title={text?.text_28} />
        {isAboveDesktop ? (
          <div className="row">
            {swipeData?.map((el, index) => {
              return (
                <div
                  key={`wed-${index}`}
                  className="col-md-4 col-sm-6 col-6 text-center"
                >
                  <div className="engageCat-box">
                    <a href={el?.link ?? "#"}>
                      <img
                        src={el?.image}
                        className="img-fluid"
                        alt={el?.alt}
                      />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <WeddingEngageCatSlider Products={swipeData} />
        )}
      </div>

      {/* Popular Weddings */}
      <PopularWeddingRings />

      {/* Shop By Metal for Wedding */}
      <EngagementGridHomeRong
        Products={[
          {
            id: 1,
            name: "WHITE GOLD",
            image: imageURL2.image_165,
            link: "/white-gold-engagement-rings",
          },
          {
            id: 2,
            name: "YELLOW GOLD",
            image: imageURL2.image_166,
            link: "/yellow-gold-engagement-rings",
          },
          {
            id: 3,
            name: "ROSE GOLD",
            image: imageURL2.image_167,
            link: "/rose-gold-engagement-rings",
          },
        ]}
        lg={4}
        title={text?.text_14 ?? "SHOP BY METAL FOR WEDDING"}
      />
      <RingParingArea />
      <GlobalReview
        Reviews={weddingReviewProduct(imageURL2)}
        title="OUR CUSTOMERS LOVE US"
      />
      <FindSizeArea />

      <EngagementNoDealBreaker />
      <WeddingFAQ />

      {/* <Footer /> */}
    </>
  );
};

export default Wedding;
export const Reviews = [
  {
    id: 1,
    first_name: "Elina",
    last_name: "Ross",
    sku: "AL3-JMR.PR-W4",
    star: 5,
    review: "Super happy with this ring. Very sparkly and secure.",
    status: 1,
    created_at: "2024-05-06T17:21:57.000000Z",
    updated_at: "2024-05-11T14:50:09.000000Z",
  },
  {
    id: 2,
    first_name: "Jenna",
    last_name: "Litt",
    sku: "AL3-JMR.PR-W4",
    star: 5,
    review:
      "This ring is absolutely gorgeous. Sparkles like crazy in the light and my best friend loves it so much she’s getting one too. The diamonds are bright and beautiful!",
    status: 1,
    created_at: "2024-05-06T17:23:02.000000Z",
    updated_at: "2024-05-11T14:49:38.000000Z",
  },
  {
    id: 3,
    first_name: "Jassica",
    last_name: "S",
    sku: "AL3-JMR.PR-W4",
    star: 5,
    review:
      "I loved this ring from the time I first saw it and I'm telling you, it is just as beautiful",
    status: 1,
    created_at: "2024-05-11T14:46:00.000000Z",
    updated_at: "2024-05-11T14:49:08.000000Z",
  },
];
