import React from "react";
import { useDynamicText } from "../Hooks/useDynamicText";
import useMediaQuery from "../Hooks/useMeadiaQuery";
import { imageURL2 } from "../images";
import Footer from "./element/Footer";
import EngagementFAQ from "./Engagement/EngagementFAQ";

const Category = () => {
  const text = useDynamicText();
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");
  return (
    <>
      <img
        src={isAboveDesktop ? imageURL2.image_147 : imageURL2.image_150}
        className=" engagment-hero-section-master"
        alt="Hero bg"
      />
      {/* <section className="enagement-hero">
      <Row className="align-items-center">
        <Col md="6 px-5">
          <Col lg="8 ps-md-5">
            <h1 className="mt-5 mt-md-0">{text?.text_14}</h1>
            <h4>{text?.text_15}</h4>
          </Col>
        </Col>
        <Col lg="12" md="12" className="text-end  engagement-hero">
          <img src={imageURL2.image_21} className="img-fluid" alt="Hero bg" />
        </Col>
      </Row>
    </section> */}

      <div className="category-list container">
        <div className="row">
          <div className="col-md-6 text-end">
            <div className="cat-card rings mb-5" data-aos="fade-right">
              <img src={imageURL2.image_58} className="img-fluid" alt="Ring" />
              <div
                className={` cat-body ${
                  isAboveDesktop ? "" : "cat-body-text-at-end"
                }`}
                data-aos="fade-up"
              >
                <h2 className="mb-lg-2 mb-2 text-dark1">{text?.text_43}</h2>
                <h3 className="fw-bold mb-0 text-dark1">{text?.text_44}</h3>
                <h4 className="mb-xxl-4 mb-2 text-dark1">{text?.text_45}</h4>
                <button
                  className="btn btn-theme btn-white-outline text-dark1 border-dark1"
                  onClick={() =>
                    (window.location.href = `${process.env.REACT_APP_WEB_URL}/new-arrivals-jewelry-rings`)
                  }
                >
                  Shop Now
                </button>
              </div>
            </div>
            {/* <img
              className="img-fluid d-block mx-auto rotate-animation"
              src={imageURL2.image_61}
              alt="Best Fashion"
            /> */}
          </div>
          <div className="col-md-6 text-center">
            <div className="cat-quotes mt-80">
              <h3 className="fw-bold">{text?.text_40}</h3>
              <p className="mx-auto">{text?.text_41}</p>
            </div>
            <div
              className="cat-card earring mb-5 text-center"
              data-aos="fade-left"
            >
              <img src={imageURL2.image_59} className="img-fluid" alt="Ring" />
              <div
                className={` cat-body ${
                  isAboveDesktop ? "" : "cat-body-text-at-end"
                }`}
                data-aos="fade-up"
              >
                <h2 className="mb-lg-2 mb-2 text-dark1">{text?.text_46}</h2>
                <h4 className="mb-xxl-4 mb-lg-2 mb-2 text-dark1">
                  {text?.text_47}
                </h4>
                <button
                  className="btn btn-theme btn-white-outline text-dark1 border-dark1"
                  onClick={() =>
                    (window.location.href = `${process.env.REACT_APP_WEB_URL}/new-arrivals-earrings`)
                  }
                >
                  Shop Now
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12 text-center">
            <div
              className="cat-card bracelet mb-5 text-center"
              data-aos="fade-up"
            >
              <img src={imageURL2.image_60} className="img-fluid" alt="Ring" />
              <div
                className={` cat-body ${
                  isAboveDesktop ? "" : "cat-body-text-at-end"
                }`}
                data-aos="fade-up"
              >
                <h2 className="mb-lg-2 mb-2 text-dark1">{text?.text_48}</h2>
                <h4 className="mb-xxl-4 mb-lg-2 mb-2 fw-normal text-dark1">
                  {text?.text_49}
                </h4>
                <button
                  className="btn btn-theme btn-white-outline text-dark1 border-dark1"
                  onClick={() =>
                    (window.location.href = `${process.env.REACT_APP_WEB_URL}/new-arrivals-bracelets`)
                  }
                >
                  Shop Now
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 text-center">
            <div className="cat-quotes text-center col-md-12 ps-xxl-5 ms-xxl-4 d-flex align-items-center flex-column">
              <h3 className="fw-bold ">{text?.text_50}</h3>
              <p className="text-center ">{text?.text_51}</p>
            </div>
            <div className="cat-card bracelet2 mb-5" data-aos="fade-right">
              <img src={imageURL2.image_63} className="img-fluid" alt="Ring" />
              <div
                className={` cat-body ${
                  isAboveDesktop ? "" : "cat-body-text-at-end"
                }`}
                data-aos="fade-up"
              >
                {/* <h2 className="mb-4">Ring</h2> */}
                <h4 className="mb-xxl-4 mb-lg-2 mb-2 text-dark1">
                  {text?.text_54}
                </h4>
                <button
                  className="btn btn-theme btn-white-outline text-dark1 border-dark1"
                  onClick={() =>
                    (window.location.href = `${process.env.REACT_APP_WEB_URL}/tennis-bracelets`)
                  }
                >
                  Shop Now
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 text-center">
            <div
              className="cat-card necklace mb-5 mt-80 text-center"
              data-aos="fade-left"
            >
              <img src={imageURL2.image_62} className="img-fluid" alt="Ring" />
              <div
                className={` cat-body ${
                  isAboveDesktop ? "" : "cat-body-text-at-end"
                }`}
                data-aos="fade-up"
              >
                <h2 className="mb-lg-2 mb-2 text-dark1">{text?.text_52}</h2>
                <h4 className="mb-xxl-4 mb-lg-2 mb-2 text-dark1">
                  {text?.text_53}
                </h4>
                <button
                  className="btn btn-theme btn-white-outline text-dark1 border-dark1"
                  onClick={() =>
                    (window.location.href = `${process.env.REACT_APP_WEB_URL}/new-arrivals-necklaces`)
                  }
                >
                  Shop Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EngagementFAQ
        faqs={[
          {
            question: "What are GIA, AGS, and IGI diamond certificates?",
            answer:
              "A Gemological Institute of America (GIA), American Gem Society (AGS), or International Gemological Institute (IGI) diamond certificate assures the value and quality of your diamond. GIA, AGS, and IGI are the gold standard in diamond grading. Their reports detail key characteristics of each diamond.",
          },

          {
            question: " What does 'Cut' mean?",
            answer:
              "A diamond's cut determines how effectively it returns light to the viewer's eye. A well-cut diamond appears brilliant and fiery, while poorly cut stones look dark and lifeless. Well-cut diamonds often appear larger than stones of the same carat weight. ",
          },
          {
            question: "What does the term 'eye-clean' mean?",
            answer:
              "'Eye-Clean' is a term used to describe a diamond that is clean to an unaided eye in a face-up position from a distance of 8 to 10 inches. Diamonds of VS2 or higher in quality will usually be eye-clean. Diamonds of SI1, SI2, and I1 need to be visually inspected to determine whether any inclusions are visible from the top. We can inspect any diamond at no charge.",
          },
          {
            question:
              "What is the quality of the diamonds used in your jewelry?",
            answer:
              "Smaller diamonds (often called melee) are cut in factories around the world and are available in a variety of qualities.",
          },
          {
            question: "Do the 18K white gold rings turn yellow over time?",
            answer:
              "Yes, very slightly. In order to give 18K White Gold a pure white color, it is plated with rhodium. This is a standard practice for 14K and 18K White Gold jewelry. Over time, the rhodium plating may wear off.",
          },
          {
            question: "How often do you add diamonds to your inventory?",
            answer: "We add new diamonds to our inventory on a daily basis.",
          },
        ]}
      />
      {/* <Footer /> */}
    </>
  );
};

export default Category;
