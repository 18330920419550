/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { getEngagementCollectionAction } from "../../features/Collections/collectionSlice";
import ProductCard from "../product/ProductCard";
import Loader from "../UI/Loader";
import {
  newTransformProductData,
  transformProductData,
} from "../../constants/function";
import {
  addToFavourite,
  getFavouriteList,
  removeFavoriteList,
} from "../../features/cart/cartSlice";
import { CustomContainer } from "../common/global-display";

function EngagementCollection() {
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state?.collectionsReducer?.engagement);
  useEffect(() => {
    dispatch(getEngagementCollectionAction());
  }, [dispatch]);

  const [listOfCollection, setListOfCollection] = useState([]);

  useEffect(() => {
    if (reducer?.success) {
      const data = reducer?.data?.data;
      setListOfCollection(data);
    }
  }, [reducer]);

  const [countryCurrency, setCountryCurrency] = useState("INR");

  useEffect(() => {
    setCountryCurrency(localStorage.getItem("currency"));
  }, []);

  const onAddToFavourite = async (data) => {
    // if (!isLogin()) return toast.error("Oops, Please login to add to wishlist");

    // eslint-disable-next-line no-restricted-globals
    alert("Product has been added to your wishlist.");
    // await dispatch(addToFavourite(produtInfo?.[selectedColor?.key]?.id));
    await dispatch(addToFavourite(data));
    await dispatch(getFavouriteList());
    await dispatch(getEngagementCollectionAction());
  };

  const handleRemove = async (id) => {
    // if (!isLogin())
    //   return toast.error("Oops, Please login to Remove to wishlist");

    const conf = confirm(
      "Are you sure you want to remove from your wishlist item?",
    );
    if (!conf) return;
    await dispatch(removeFavoriteList(id));
    await dispatch(getFavouriteList());
    dispatch(getEngagementCollectionAction());
  };

  return (
    <>
      <div className="container my-3">
        <Breadcrumb>
          <LinkContainer to="/" className="link-breadcrumb">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </LinkContainer>

          <Breadcrumb.Item active>Engagement Collection</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-title">
        <div
          style={{
            color: "#e77e50",
            fontSize: "22px",
            fontWeight: 700,
          }}
        >
          Engagement Collection
        </div>
        {/* <h5>Home - Rings Fashion - Rings</h5> */}
      </div>

      <CustomContainer className="mt-0 mb-0 product-container">
        <div className="row gx-4 product-listing listingpage-container">
          {reducer?.loading ? (
            <Loader />
          ) : (
            <>
              {listOfCollection.length == 0 ? (
                <>No Item</>
              ) : (
                <>
                  {listOfCollection?.map((item) => {
                    const produtInfo = item?.product_variation
                      ? transformProductData(item, countryCurrency)
                      : newTransformProductData(item, countryCurrency);

                    // const item_detail = JSON.parse(item?.item_detail);
                    // const produtInfo = {
                    //   name: item?.title,
                    //   type: item?.Necklaces,
                    //   imgUrl: item?.media_arr[0],
                    //   hoverImageUrl: !!item?.media_arr[1]
                    //     ? item?.media_arr[1]
                    //     : item?.media_arr[0],
                    //   id: item?.id,
                    //   price: item?.product_price,
                    //   price:
                    //     countryCurrency == "INR"
                    //       ? item?.product_price
                    //       : countryCurrency == "USD"
                    //       ? item?.usa_price
                    //       : countryCurrency == "GBP"
                    //       ? item?.uk_price
                    //       : item?.usa_price,
                    //   sku: item_detail?.SKU,
                    //   category: "",
                    //   currency: countryCurrency,
                    //   tag: item?.tag,
                    // };

                    return (
                      <>
                        <ProductCard
                          produtInfo={produtInfo}
                          key={item.id}
                          item={item}
                          onAddToFavourite={onAddToFavourite}
                          handleRemove={handleRemove}
                        />
                      </>
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      </CustomContainer>
    </>
  );
}

export default EngagementCollection;
