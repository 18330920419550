import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { currencyIcon, isLogin } from "../../constants/function";
import { getCartDetails } from "../../features/cart/cartSlice";
import formatCurrency from "../../helper/currencyFormater";
import ProductCartList from "../cart/ProductCartList";
import { CustomContainer } from "../common/global-display";

const Cart = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const countryCurrency = localStorage.getItem("currency") ?? "INR";
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      const totalAmount = cartItems.reduce((acc, curr) => {
        const qty = curr.qty;
        const price = +curr.amount;
        // const price = +curr.product.product_price;
        const itemTotal = qty * price;

        return acc + itemTotal;
      }, 0);

      // Assuming setTotal is a state updater function
      setTotal(totalAmount);
    }
  }, [cartItems]);

  useEffect(() => {
    dispatch(getCartDetails());
  }, []);

  return (
    <>
      <div className="page-title">
        <h2>Shopping Cart</h2>
        {/* <h5>Home - Cart</h5> */}
      </div>

      {/* Begin:: Cart */}
      <CustomContainer>
        <div className="row gx-xl-5">
          <div className="col-xxl-9 col-lg-8">
            <div className="cartItems-container">
              {cartItems &&
                cartItems?.length > 0 &&
                cartItems?.map((item) => {
                  const { SKU, Style } = JSON.parse(item?.product?.item_detail);

                  const produtInfo = {
                    name: item?.product?.title,
                    type: item?.product?.Necklaces,
                    imgUrl: item?.product?.featured_image[0]?.original_url,
                    hoverImageUrl:
                      item?.product?.featured_image[1]?.original_url,
                    id: item?.product?.id,
                    // price: item?.product?.product_price,
                    price: item?.amount,
                    currency: item?.currency,

                    // price:
                    //   countryCurrency == "INR"
                    //     ? formatCurrency(item?.product?.product_price)
                    //     : countryCurrency == "USD"
                    //     ? formatCurrency(item?.product?.usa_price)
                    //     : countryCurrency == "GBP"
                    //     ? formatCurrency(item?.product?.uk_price)
                    //     : formatCurrency(item?.product?.usa_price),
                    sku: SKU,
                    style: Style,
                    qty: item.qty,
                    cartProductId: item.id,
                    productCartId: item.id,
                    product_type: item?.product?.product_type,
                    cartId: item?.id,
                    size: item?.size ?? item?.product?.Productsize,
                    caption: item?.product?.caption,
                  };
                  return (
                    <ProductCartList
                      key={item.id}
                      {...produtInfo}
                      fullDeatials={{ ...item, ...item?.product }}
                    />
                  );
                })}

              {cartItems.length === 0 && (
                <div className="text-center mt-5">
                  <h3>Your Bag is Empty!</h3>
                  <Link to={"/"}>
                    <span className="">Let's Start Shopping</span>
                  </Link>
                </div>
              )}
            </div>
            <p
              className=" cursor-pointer text-primary h4"
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_WEB_URL}/`)
              }
              // onClick={() => window?.history?.back()}
            >
              {" "}
              <i className="fa-solid fa-arrow-left-long me-2"></i> continue to
              shopping
            </p>
          </div>
          <div className="col-xxl-3 col-lg-4 mt-5 mt-lg-0">
            <div className="order-summary" data-aos="fade-up">
              <h5 className="text-center fw-bold mb-5">Order Summary</h5>

              {/* <div className="form-group">
                <label>Coupon Code</label>
                <div className="d-flex coupon-field">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Coupon Code"
                  />
                  <button className="btn btn-secondary" type="button">
                    Apply
                  </button>
                </div>
              </div> */}

              <table className="table">
                <tbody>
                  <tr>
                    <td> Subtotal:</td>
                    <td className="">
                      {currencyIcon(
                        cartItems?.[0]?.currency ?? countryCurrency,
                      )}{" "}
                      {/* <LiaRupeeSignSolid /> */}
                      {cartItems.length > 0
                        ? formatCurrency(Number(total).toFixed(2))
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td> Shipping:</td>
                    <td className="text-right">
                      {/* {currencyIcon(
                        cartItems?.[0]?.currency ?? countryCurrency
                      )}{" "}
                      {formatCurrency(0.0)} */}
                      Free
                    </td>
                  </tr>
                  <tr>
                    <td> Tax:</td>
                    <td className="text-right">
                      {currencyIcon(
                        cartItems?.[0]?.currency ?? countryCurrency,
                      )}{" "}
                      {formatCurrency(0.0)}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Order Total:</td>
                    <td className="">
                      {currencyIcon(
                        cartItems?.[0]?.currency ?? countryCurrency,
                      )}{" "}
                      {/* <LiaRupeeSignSolid /> */}
                      {cartItems.length > 0
                        ? formatCurrency(Number(total).toFixed(2))
                        : 0}
                    </td>
                  </tr>
                </tbody>
              </table>

              <Link
                to={isLogin() ? "/checkout" : "/login"}
                type="button"
                className="btn btn-secondary btn-theme w-100"
              >
                Secure Checkout
              </Link>

              <div className="text-center mb-0 chat-detail d-flex flex-column ">
                <Link className="text-black" to="tel:+91 760-051-0097">
                  {" "}
                  <i className="fa-solid fa-phone"></i> {`(+91) 760-051-0097`}
                </Link>
                {/* <Link className="text-black mx-2" to="">
                  {" "}
                  <i className="fa-solid fa-message"></i> Live Chat{" "}
                </Link> */}
                <Link
                  className="text-black mx-2"
                  to="mailto:info@andorluxury.com"
                >
                  {" "}
                  <i className="fa-solid fa-envelope"></i> Email Us{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </CustomContainer>
      {/* End:: Cart */}
    </>
  );
};

export default Cart;
