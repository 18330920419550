import React from "react";
import GlobalTitle from "../common/global-title";
import { CustomContainer } from "../common/global-display";

const RingResizingInfo = () => {
  return (
    <CustomContainer className="mt-0 mb-0">
      <div className="my-6">
        <GlobalTitle
          title="Are There Any Types of Rings That Can't Be Resized?"
          description="Yes. Certain rings, such as eternity and open bands, and
              alternative metal styles aren't eligible for resizing due to the
              structure or material of their designs. If your ring cannot be
              resized, we'll include that information on the product detail page
              of your specific style. You may also exchange a ring that cannot
              be resized within our 30-day free exchange period."
        />
      </div>
      <div className="pb-4">
        <GlobalTitle
          title="Andor Luxury Free 30-Day Exchanges & 60-Day Resizes"
          description="Our commitment to you extends beyond delivery, and we're proud to
              offer free 30-day exchanges and free 60-day resizes to ensure that
              you can find the perfect fit. Most designs (even an eternity,
              open, or alternative metal style) can be exchanged for a new size
              within 30 days free of charge, while any resizable design can be
              resized within 60 days free of charge."
        />
      </div>
    </CustomContainer>
  );
};

export default RingResizingInfo;
