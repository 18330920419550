import { Container, Image } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import SliderComponent from "../Slider/sliderComponent";
import GlobalTitle from "../common/global-title";
import { CustomContainer } from "../common/global-display";

const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 769,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

const EngagementGridHome = ({ Products, title = "", lg = 3 }) => {
  // const isAboveDesktop = useMediaQuery("(min-width: 768px)");
  return Products?.length ? (
    <CustomContainer>
      {!!title && <GlobalTitle title={title} />}
      <div className="mb-4">
        <CustomContainer className="mt-0 mb-0">
          <div className="collection-slide abcd ">
            {/* <ProductSlider Products={Products} /> */}
            <SliderComponent
              slidesToShow={5}
              responsive={responsive}
              data={
                Products?.map((singleProduct) => {
                  return (
                    <div
                      key={singleProduct?.id}
                      className=" Rings_hover text-center w-100 h-100"
                    >
                      <div className="Rings_hover  mb-2">
                        <Link to={singleProduct?.link}>
                          <Image
                            src={singleProduct?.image}
                            alt={singleProduct?.name}
                            fluid
                            className="Rings_img_hover"
                          />
                        </Link>
                      </div>
                      <h5>{singleProduct?.name}</h5>
                    </div>
                  );
                }) ?? []
              }
            />
          </div>
        </CustomContainer>
      </div>
    </CustomContainer>
  ) : (
    ""
  );
};

export default EngagementGridHome;
