import React from "react";
import { Field, ErrorMessage } from "formik";
import classNames from "classnames";
import FileUpload from "./file-upload";

const FormField = ({
  type,
  name,
  label,
  size,
  required,
  extraClass,
  disabled,
  options,
  errors,
  touched,
  values,
  handleChange,
  extraText,
  placeholder,
  removeInputBox,
  rows
}) => {
  return (
    <div className={`${type !== "checkbox" && "mb-3"} ${size} ${extraClass}`}>
      {!removeInputBox && type !== "checkbox" && <label htmlFor={name} className="form-label ">
        {label}
        {required && <span className="text-danger">*</span>}
      </label>}
      {type === "select" ? (
        <>
          {" "}
          <Field
            as="select"
            name={name}
            className={classNames(`${removeInputBox ? "form-noborder-control" : "form-control"} form-select mb-3`, {
              "error-border": errors[name] && touched[name],
            })}
            placeholder={placeholder ?? label}
            value={values?.[name]}
            onChange={handleChange}
            // handleChange={handleChange}
            disabled={disabled}
          >
            <option value="" label="Select option" />
            {options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Field>
          {extraText && <span className="gray-color">{extraText}</span>}
        </>
      ) : type === "file" ? (
        <FileUpload
          name="fileUpload"
          className=""
          onChange={handleChange}
          errors={errors}
          touched={touched}
          disabled={disabled}
        />
      ) : type === "textarea" ? (
        <>
          <Field
            rows={rows}
            as="textarea"
            name={name}
            className={classNames(`form-control`, {
              "error-border": errors[name] && touched[name],
            })}
            value={values?.[name]}
            onChange={handleChange}
            placeholder={placeholder ?? label}
            disabled={disabled}
          />
          {extraText && <span className="gray-color">{extraText}</span>}
        </>
      ) : type === "checkbox" ? (
        <>
          <div className="d-flex">
            <Field
              type="checkbox"
              name={name}
              checked={values?.[name] || false} // Use checked attribute for checkboxes
              onChange={handleChange}

              className={classNames("form-check-input mb-1", {
                "error-border": errors[name] && touched[name],
              })}
              disabled={disabled}
            />
            <label htmlFor={name} className="mx-2 form-check-label">
              {label}
            </label>
          </div>
          {extraText && <span className="gray-color">{extraText}</span>}
        </>
      ) : (
        <>
          <Field
            type={type}
            name={name}
            onChange={handleChange}
            className={classNames(`${removeInputBox ? "form-noborder-control" : "form-control"} `, {
              [removeInputBox ? "error-noborder" : "error-border"]: errors[name] && touched[name],
            })}
            value={values?.[name]}
            placeholder={placeholder ?? label}
            disabled={disabled}
          />
          {extraText && <span className="gray-color">{extraText}</span>}
        </>
      )}
      <ErrorMessage name={name} component="div" className="text-danger" />
    </div>
  );
};

export default FormField;
