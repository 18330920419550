import React from "react";
import { Col, Row } from "react-bootstrap";
import { CustomContainer } from "./common/global-display";

const Account = () => {
  return (
    <>
      <div className="page-title">
        <h2>My Account</h2>
        <h5>Home - Account</h5>
      </div>

      <CustomContainer className="mt-100">
        <h5>
          Welcome, <strong> {localStorage.getItem("user_name")} </strong>
        </h5>
        <p>Use the options below to manage your account with us.</p>

        <Row className="mt-5">
          <Col lg={4}>
            <div className="card">
              <div className="card-body"></div>
            </div>
          </Col>
        </Row>
      </CustomContainer>
    </>
  );
};

export default Account;
