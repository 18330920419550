import { Form, Formik } from "formik";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import * as Yup from "yup";

// import CustomCountrySelect from "./CustomCountrySelect";
import { useDispatch } from "react-redux";
import { getState } from "../../features/address/addressSlice";
import FormField from "./formFields";

const userAddressListSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  addressLine1: Yup.string().required("Address 1 is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  postal_code: Yup.string().required("Postal code is required"),
  country: Yup.string().required("Country is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().matches(/^[0-9]{10}$/, "Invalid phone number"),
  file: Yup.mixed().required("File is required"),
});

const GlobalForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  fields,
  // isNew,
  // dispatch,
  // addNewAddress,
  // updateAddress,
  // editId,
  // setState,
  // initialValue,
  // setIsEdit,
  // setEditId,
  btnText,
  btnSet = "center",
  dynamicOption = {},
  footerExtra = <></>,
  removeInputBox = false,
  submitBtnclass = null
}) => {
  const dispatch = useDispatch();
  // console.log("first");
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(val) => onSubmit(val)}
    >
      {({ handleChange, setFieldValue, errors, touched, values }) => (
        <Form>
          <Row className="g-0 g-md-5">
            {fields?.map((field, index) => (
              <Col md={field?.size} key={index} className="mb-1 mt-2">
                <FormField
                  type={field?.type}
                  name={field?.name}
                  label={field?.label}
                  size={field?.size}
                  required={field?.required}
                  extraClass={field?.extraClass}
                  disabled={field?.disabled}
                  options={field?.options ?? dynamicOption?.[field?.name]}
                  extraText={field?.extraText ?? null}
                  errors={errors}
                  placeholder={field?.placeholder ?? null}
                  touched={touched}
                  values={values}
                  removeInputBox={removeInputBox}
                  rows={field?.rows ?? "5"}
                  handleChange={(e) => {
                    // console.log(e)
                    if (field?.name === "country_id") {
                      handleChange(e);
                      setFieldValue("state_id", "");
                      dispatch(
                        getState(
                          dynamicOption?.[field?.name]?.find(
                            (el) => el?.id == e?.target?.value,
                          )?.code,
                        ),
                      );
                    } else {
                      // console.log(e)
                      handleChange(e);
                    }
                  }}
                />
              </Col>
            ))}
          </Row>

          <div className="mt-4">
            <Row>
              {footerExtra}
              <Col className={`text-${btnSet}`}>
                <Button
                  variant="primary"
                  className={`${submitBtnclass ?? "btn btn-theme btn-white-outline btn-white-outline-hover text-dark1 border-dark1"}  px-5`}
                  type="submit"
                  onClick={() => console.log("onSubmit")}
                // style={{ color: "white" }}
                >
                  {btnText ?? "Submit"}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default GlobalForm;
