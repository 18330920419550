import React from "react";
import { imageURL2 } from "../../images";
import ImageWithDetailSec from "../common/image-with-detail-sec";

const FindSizeArea = () => {
  return (
    <>
      <ImageWithDetailSec
        title="Find Your Ring Size"
        description="Don't know your ring size? You can order our free ring sizer, or use our printable guide which determines your size by using another ring that you already own."
        buttonText="FIND YOUR RING SIZE"
        buttonLink="/buying-guide/ring-size/"
        imagePath={imageURL2.image_191}
        imageAlt="Find Your Ring Size"
        textPosition="left"
      />
    </>
  );
};

export default FindSizeArea;
