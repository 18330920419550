import React from "react";
import formatCurrency from "../../helper/currencyFormater";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { currencyIcon } from "../../constants/function";

const SummaryCartItem = ({
  name,
  type,
  imgUrl,
  id,
  hoverImageUrl,
  price,
  qty,
  item,
  currency,
  caption,
  sku,
}) => {
  const countryCurrency = localStorage.getItem("currency");
  return (
    <div className="cart-item">
      <img
        src={imgUrl}
        width={300}
        className="img-fluid object-fit-cover"
        alt="product"
      />
      <div className="item-detail">
        <h5 className="" style={{ fontSize: "16px" }}>
          {name}
        </h5>
        <p className="m-0">{caption}</p>
        <p className="m-0">{sku}</p>
        <div className="row  mb-2 mt-2 align-items-center">
          <div
            className="col-md-6 mb-0 "
            style={
              {
                // display: "flex",
                // alignItems: "center",
                // fontSize:'24px'
              }
            }
          >
            {currencyIcon(currency ?? countryCurrency)}

            {/* {currencyIcon(countryCurrency)} */}
            {/* <LiaRupeeSignSolid /> */}
            {formatCurrency(price)}
          </div>
          <div className="col-md-6 mb-0 ">Quantity: {qty}</div>
        </div>
        <p className="mb-0">
          <strong>Made-to-Order.</strong> Estimated Ship Date:{" "}
          {new Date(
            new Date().getTime() + 15 * 24 * 60 * 60 * 1000,
          ).toDateString()}
        </p>
      </div>
    </div>
  );
};

export default SummaryCartItem;
