import React from "react";

const HeartFill = ({
  fillColor = "none",
  strokeColor = "currentColor",
  ...props
}) => {
  return (
    <svg
      aria-hidden="true"
      role="img"
      width={20}
      height={20}
      viewBox="0 0 23 21"
      {...props}
    >
      <path
        d="M20.6748 10.7821C21.5496 9.7463 22.0808 8.40493 22.0808 6.93904C22.0808 3.65823 19.4375 1 16.1797 1C14.3675 1 12.749 1.82256 11.6659 3.11677C10.5827 1.82445 8.96426 1 7.15207 1C3.8922 1 1.25098 3.65823 1.25098 6.93904C1.25098 8.40493 1.78214 9.7463 2.65699 10.7821H2.63824L2.83405 10.9801C2.93195 11.0858 3.03192 11.1858 3.13607 11.2839L11.6638 19.8661L20.1915 11.2839C20.2957 11.1858 20.3957 11.0839 20.4936 10.9801L20.6894 10.7821H20.6748V10.7821Z"
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HeartFill;

// import React from "react";

// const HeartFill = (props) => {
//   return (
//     <svg aria-hidden="true" role="img" width="20" height="20" {...props}>
//       <use
//         href="#sv-wishlist-fill"
//         xmlns="http://www.w3.org/1999/xlink"
//         // xlink:href="#sv-wishlist-fill"
//       ></use>
//     </svg>
//     // <svg
//     //   stroke="currentColor"
//     //   fill="currentColor"
//     //   strokeWidth={0}
//     //   viewBox="0 0 512 512"
//     //   height="22px"
//     //   width="22px"
//     //   xmlns="http://www.w3.org/2000/svg"
//     //   {...props}
//     // >
//     //   <path d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z" />
//     // </svg>
//     // <svg
//     //   stroke="currentColor"
//     //   fill="currentColor"
//     //   strokeWidth="0"
//     //   viewBox="0 0 512 512"
//     //   height="22px"
//     //   width="22px"
//     //   xmlns="http://www.w3.org/2000/svg"
//     //   {...props}
//     // >
//     //   <path d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"></path>
//     //   <rect
//     //     x="100" // Adjust the x-coordinate
//     //     y="150" // Adjust the y-coordinate
//     //     width="312" // Adjust the width to fit within the heart shape
//     //     height="200" // Adjust the height to fit within the heart shape
//     //     fill="blue" // Example fill color for the rectangle
//     //     opacity="0.5" // Optional: Makes the rectangle semi-transparent
//     //     transform="rotate(-45 256 256)" // Adjust the rotation if necessary
//     //   ></rect>
//     // </svg>
//   );
// };

// export default HeartFill;
