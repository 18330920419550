import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getSingProductUrl } from "../../constants/urls";
import { isLogin } from "../../constants/function";
const intitalState = {
  loading: false,
  data: null,
  slug: "",
  success: false,
  error: false,
  errorMsg: "",
  isProduct: 0,
};

const token = localStorage.getItem("token");

export const getSingleProduct = createAsyncThunk(
  "content/singleProduct",
  async (details) => {
    const user = JSON?.parse(localStorage.getItem("user"));
    const res = await axios.post(
      `${getSingProductUrl}${user?.id ? `?user_id=${user?.id}` : ""}`,
      details,
      {
        headers: {
          ...(!!token && { Authorization: `Bearer ${token}` }),
        },
      },
    );
    if (isLogin()) {
      const data = await res.data;
      return data;
    } else {
      const data = await res.data;
      const localData = JSON.parse(localStorage.getItem("wishlist")) || [];
      const localItem = localData.find((i) => i?.id === data?.data?.id);
      data["data"]["favourite"] = localItem
        ? localItem.product?.favourite
        : data?.data?.favourite;
      // data = data.data.map((item) => {
      //   const localItem = localData.find((i) => i?.id === item?.id);
      //   const isFavourite = localItem ? localItem.product?.favourite : item?.favourite;

      //   return {
      //     ...item,
      //     favourite: isFavourite
      //   };
      // });

      return data;
    }
  },
);

export const singleProductSlice = createSlice({
  name: "singProduct",
  initialState: intitalState,
  reducers: {
    resetDetails: (state) => {
      state.loading = false;
      state.success = false;
      state.data = null;
      state.slug = "";
      state.error = false;
      state.errorMsg = "";
      state.isProduct = 0;
      state.product_variation_sku = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSingleProduct.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getSingleProduct.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.slug = action.payload.slug;
      state.product_variation_sku = action.payload.product_variation_sku;
      state.success = true;
      state.loading = false;
      state.isProduct = action.payload.is_product;
    });

    builder.addCase(getSingleProduct.rejected, (state, action) => {
      state.error = true;
      state.errorMsg = action.error.message;
    });
  },
});

export const { resetDetails } = singleProductSlice.actions;

export default singleProductSlice.reducer;
