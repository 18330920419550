/* eslint-disable no-restricted-globals */
import axios from "axios";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import toast from "react-hot-toast";
import { FiPlayCircle } from "react-icons/fi";
import { IoMdRefresh } from "react-icons/io";
import { IoCallOutline } from "react-icons/io5";
import { RiTruckLine } from "react-icons/ri";
import { SiAdguard } from "react-icons/si";
import { TfiEmail } from "react-icons/tfi";
import ReactImageMagnify from "react-image-magnify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import HeartFill from "../../assets/svgs/heartFill";
import {
  currencyIcon,
  getProductType,
  getProductTypeOptionData,
  getSuggestionProduct,
} from "../../constants/function";
import { addEmailProductDetail, baseUrl } from "../../constants/urls";
import { colorsVariant, detailFaq } from "../../constants/utils";
import {
  addToCart,
  addToFavourite,
  getCartDetails,
  getFavouriteList,
  removeFavoriteList,
} from "../../features/cart/cartSlice";
import {
  getSingleProduct,
  resetDetails,
} from "../../features/productsList/singleProductSlice";
import { convertToTitleCase } from "../../helper/constant";
import formatCurrency from "../../helper/currencyFormater";
import { dropHintEmailModal, emailModal } from "../../helper/formModal";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import GlobalBreadcrumb from "../common/global-breadcrumb";
import { CustomContainer } from "../common/global-display";
import EngagementFAQ from "../Engagement/EngagementFAQ";
import { generateYupSchema } from "../form/generate-yup-schema";
import ImageSliderNew from "../Slider/imageSlidernew";
import DealBreakerSection from "./deal-breaker-section";
import ProductDetailTab from "./detailtab/product-detail-tab";
import ReviewTab from "./detailtab/review-tab";
import DropHintModal from "./drop-hint-modal";

const initialHintDropValues = {
  recipientEmail: "",
  email: "",
  name: "",
  note: "",
  emailUpdate: false,
  copyEmail: false,
};

const initialEmailModalValues = {
  fisrtName: "",
  lastName: "",
  email: "",
  phone: "",
  note: "",
  emailUpdate: false,
};

const Detail = () => {
  const isAboveDesktop = useMediaQuery("(min-width: 991px)");
  const isAboveTablet = useMediaQuery("(min-width: 745px)");
  const isAboveMobile = useMediaQuery("(min-width: 520px)");

  const [modalFormData, setModalFormData] = useState(null);

  const [size, setSize] = useState(0);
  const [Productsize, setProductSize] = useState(null);

  const token = localStorage.getItem("token");
  const { sku, title } = useParams();
  // console.log(sku, title, 'as')
  const [image, setImage] = useState("");
  const [itemDetails, setItemDetails] = useState([]);
  const [isProduct, setIsProduct] = useState(0);
  const [isFaq, setIsFaq] = useState(0);

  const [diamondDetails, setDiamondDetails] = useState([]);
  const [sideDiamondDetails, setSideDiamondDetails] = useState([]);
  const [imageGallery, setImageGallery] = useState([]);
  const [videoDetails, setVideoDetails] = useState([]);
  const [productPrice, setProductPrice] = useState("");
  // console.log("imageGallery", imageGallery);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [productVariant, setProductVariant] = useState({});

  const [res_slug, setRes_slug] = useState("");
  const productDetailsReducer = useSelector((state) => state.singleProduct);

  const [reviewCount, setReviewCount] = useState(0);
  const [reviewList, setReviewList] = useState([]);
  const [avgReview, setAvgReview] = useState(0);
  useEffect(() => {
    if (!!product) {
      const getData = async () => {
        const res = await axios.get(
          `${baseUrl}/api/product-review?product_id=${product?.id}`
        );
        const data = await res.data;
        setReviewCount(data?.count);
        setReviewList(data?.reviews);
        const listOfReviewStar = data?.reviews.map((item) => item.star);
        const avg = Math.ceil(
          listOfReviewStar?.reduce((a, b) => a + b, 0) /
            listOfReviewStar?.length
        );
        setAvgReview(avg);
      };
      getData();
    }
  }, [product]);
  const [deliveryDate, setDeliveryDate] = useState(
    new Date(new Date().getTime() + 15 * 24 * 60 * 60 * 1000)
  );

  useEffect(() => {
    if (productDetailsReducer?.success) {
      setProduct(productDetailsReducer.data);
      setProductVariant({
        silver: productDetailsReducer?.product_variation_sku?.white,
        yellow: productDetailsReducer?.product_variation_sku?.yellow,
        rose: productDetailsReducer?.product_variation_sku?.rose,
      });
      setRes_slug(
        !!productDetailsReducer.slug ? productDetailsReducer.slug : ""
      );
      setIsProduct(productDetailsReducer?.isProduct);
      setIsFaq(productDetailsReducer?.data?.category_id);
      dispatch(resetDetails());
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } else if (productDetailsReducer?.error) {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [productDetailsReducer]);

  const location = useLocation();
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const [countryCurrency, setCountryCurrency] = useState("INR");

  useEffect(() => {
    setCountryCurrency(localStorage.getItem("currency"));
  }, []);

  useEffect(() => {
    setLoading(true);
    // console.log("first", location?.state?.slug);

    dispatch(getSingleProduct({ sku: sku, slug: location?.state?.slug ?? "" }));
  }, [sku, location?.state?.slug]);

  useEffect(() => {
    if (!!product) {
      const price =
        countryCurrency == "INR"
          ? product?.product_price
          : countryCurrency == "USD"
          ? product?.usa_price
          : countryCurrency == "GBP"
          ? product?.uk_price
          : countryCurrency == "CAD"
          ? product?.canada_price
          : product?.usa_price;
      const formatedPrice = formatCurrency(price);
      setProductPrice(formatedPrice);
    }
  }, [product]);

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          width: "25px",
          color: "#333",
          top: "-30px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
        onClick={onClick}
      >
        &lsaquo;
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          width: "25px",
          color: "#333",
          left: "50%",
          transform: "translateX(-50%)",
        }}
        onClick={onClick}
      >
        &rsaquo;
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    // adaptiveHeight: true,
    // prevArrow:  <PrevArrow /> ,
    // nextArrow:<NextArrow />,
    // swipe :true
    swipe: true,
    draggable: true,
    prevArrow:
      [...videoDetails, ...imageGallery]?.length > 4 ? <PrevArrow /> : <></>,
    nextArrow:
      [...videoDetails, ...imageGallery]?.length > 4 ? <NextArrow /> : <></>,
  };

  useEffect(() => {
    setDiamondDetails([]);
    setSideDiamondDetails([]);
    setItemDetails([]);
    if (!!product) {
      const itemDetailsObject = JSON.parse(product.item_detail);
      const itemDetails = Object.entries(itemDetailsObject).map(
        ([key, value]) => ({ key: key, value: value })
      );

      const diamondDetailsObj = JSON.parse(product.diamond_detail);
      if (Object.keys(diamondDetailsObj).length !== 0) {
        const diamondDetails = Object.entries(diamondDetailsObj)
          .filter(([key, value]) => !!value)
          .map(([key, value]) => ({ key: key, value: value }));
        setDiamondDetails(diamondDetails);
      }

      if (!!product.side_diamond_detail) {
        const sideDiamondDetailsObj = JSON.parse(product.side_diamond_detail);
        const flag = !!sideDiamondDetailsObj["SD1"]
          ? sideDiamondDetailsObj["SD1"]
          : sideDiamondDetailsObj; // tempory for old diamond details
        if (Object.keys(flag).length !== 0) {
          const diamondDetails = Object.entries(flag)
            .filter(([key, value]) => !!value)
            .map(([key, value]) => ({ key: key, value: value }));
          setSideDiamondDetails(diamondDetails);
        }
      }
      setImage(product.media[0].original);
      setItemDetails(itemDetails);

      setImageGallery(product.media);
      // setVideoDetails([...product?.video, ...product?.video] ?? []);
      setVideoDetails(product?.video ?? []);
    }
  }, [product, imageGallery]);

  const onAddToCartHandelar = async (redirect) => {
    // await dispatch(addToCart({ product_id: product.id, qty: 1 }));
    if (!Productsize && product?.product_type !== 1)
      return toast.error("Please select size");
    alert("Product has been added to your cart.");
    await dispatch(addToCart({ ...product, Productsize }));
    await dispatch(getCartDetails());
    if (redirect) {
      window.location.href = "/cart";
    }
  };

  const [suggestProduct, setSuggestProduct] = useState([]);

  const getSuggestionProductHandler = async (product) => {
    getSuggestionProduct(product).then((suggestionData) => {
      setSuggestProduct(suggestionData);
    });
  };

  useEffect(() => {
    if (!!product) {
      // const suggestionData = getSuggestionProduct(product);
      getSuggestionProductHandler(product);
    }
  }, [product]);

  const handleFavorite = async (productData) => {
    // if (!isLogin()) return toast.error("Oops, Please login to add to wishlist");
    alert("Product has been added to your wishlist.");
    await dispatch(addToFavourite(productData));
    await dispatch(getFavouriteList());
  };

  const handleRemove = async (id) => {
    // if (!isLogin())
    //   return toast.error("Oops, Please login to Remove to wishlist");

    const conf = confirm(
      "Are you sure you want to remove from your wishlist item?"
    );
    if (!conf) return;
    await dispatch(removeFavoriteList(id));
    await dispatch(getFavouriteList());
    // await dispatch(
    //   fetchProducts({ category: category, page: 1, userId: userdata?.id })
    // );
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const transformWrapperRef = useRef(null);
  const [isZoomedIn, setIsZoomedIn] = useState(false);
  const tapTimeoutRef = useRef(null);
  const zoomScale = 2;

  const handleTap = (e) => {
    if (tapTimeoutRef.current) {
      clearTimeout(tapTimeoutRef.current);
      tapTimeoutRef.current = null;
      handleDoubleClick(e);
    } else {
      tapTimeoutRef.current = setTimeout(() => {
        tapTimeoutRef.current = null;
      }, 300);
    }
  };

  const handleDoubleClick = (e) => {
    if (transformWrapperRef.current) {
      const { zoomIn, resetTransform, setTransform } =
        transformWrapperRef.current;

      if (isZoomedIn) {
        resetTransform(200);
      } else {
        const { clientX, clientY } = e;
        const rect = e.target.getBoundingClientRect();

        const offsetX = clientX - rect.left;
        const offsetY = clientY - rect.top;

        const newX = offsetX * (zoomScale - 1);
        const newY = offsetY * (zoomScale - 1);

        setTransform(-newX, -newY, zoomScale, 200);
      }
      setIsZoomedIn((prevZoom) => !prevZoom);
    }
  };
  useEffect(() => {
    if (transformWrapperRef.current) {
      const { zoomIn, resetTransform, setTransform } =
        transformWrapperRef.current;
      resetTransform(200);
    }
  }, [window?.location?.pathname]);

  const handleCloseHintModal = () => {
    setModalFormData(null);
  };

  const handleHintDropSubmit = (values) => {
    console.log("Form values:", values);
  };

  const handleEmailModalSubmit = async (values) => {
    console.log("Form values:", values);
    try {
      console.log(modalFormData, "kdfkdhfjd");
      const payload = {};
      payload["product_id"] = modalFormData?.emailsupport?.id;
      payload["first_name"] = values?.firstName;
      payload["last_name"] = values?.lastName;
      payload["email"] = values?.email;
      payload["phone"] = values?.phone;
      payload["message"] = values?.note;
      // payload["counry_code"] = values?.counry_code ?? "+91";
      const res = await axios.post(addEmailProductDetail, payload);
      console.log(res);
      if (res?.data?.result == 1) {
        setModalFormData(null);
        toast.success(
          res?.data?.message ??
            "Our jewelry specialist will reach you within 24 hours."
        );
        return res.data;
      }
    } catch (error) {
      console.error("error in login: ", error);
      throw error.response.data.error;
    }
  };

  if (!!product) {
    return (
      <>
        <GlobalBreadcrumb
          subTitle={convertToTitleCase(res_slug)}
          subTo={`/${res_slug}`}
        />

        {isProduct == 0 ? (
          <CustomContainer className="mt-5">
            <div
              style={{
                fontSize: "30px",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              This product is not available
            </div>
          </CustomContainer>
        ) : (
          <>
            {/* Begin:: Product Detail */}
            <CustomContainer className="mt-5">
              <div className="row gx-5 gx-xxl-4 justify-content-xxl-center">
                <div className="col-lg-7 col-xl-7 col-xxl-8 col-3xl-6 mb-4 mb-lg-0 p-0">
                  <div className="product-showcase">
                    {size > 991 && (
                      <div
                        className="img-thumbs "
                        style={{ marginTop: "2rem" }}
                        // style={{ display:"flex",justifyContent:"center",alignItems:"center" }}
                      >
                        <Slider {...settings}>
                          {videoDetails?.length > 0 &&
                            videoDetails?.map((vitem, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{ position: "relative" }}
                                >
                                  <div style={{ position: "relative" }}>
                                    <img
                                      src={imageGallery?.[index]?.thumb}
                                      className="img-fluid"
                                      alt={imageGallery?.[index]?.thumb}
                                      style={{
                                        cursor: "pointer",
                                        width: "100%",
                                        height: "auto",
                                        marginRight: "0px",
                                        marginBottom: "20px",
                                        position: "relative",
                                      }}
                                      onClick={() => {
                                        console.log(vitem?.original);
                                        setImage(vitem.original);
                                      }}
                                    />
                                    <FiPlayCircle
                                      style={{
                                        position: "absolute",
                                        top: "10%",
                                        right: "10%",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          {imageGallery?.map((item, index) => {
                            return (
                              <div key={index}>
                                <img
                                  src={item.thumb}
                                  className="img-fluid"
                                  alt={item.thumb}
                                  style={{
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "auto",
                                    marginRight: "0px",
                                    marginBottom: "20px",
                                  }}
                                  onClick={() => {
                                    setImage(item.original);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    )}

                    <div className="main-display" data-aos="zoom-in">
                      {image &&
                      ["mp4", "mkv", "wav", "mp3"].some((ext) =>
                        image.includes(ext)
                      ) ? (
                        <video
                          src={image}
                          alt="Video"
                          style={{ width: "100%" }}
                          autoPlay
                          controls
                          loop
                          muted // Optional: Start muted if you prefer
                        />
                      ) : isMobile ? (
                        <>
                          <div onClick={handleTap}>
                            <TransformWrapper
                              ref={transformWrapperRef}
                              doubleClick={{ disabled: true }}
                            >
                              <TransformComponent>
                                <img
                                  src={image}
                                  alt="Zoomable"
                                  style={{ width: "100%", cursor: "pointer" }}
                                  onClick={handleTap}
                                />
                              </TransformComponent>
                            </TransformWrapper>
                          </div>
                        </>
                      ) : (
                        <ReactImageMagnify
                          {...{
                            smallImage: {
                              alt: "productImage",
                              isFluidWidth: true,
                              src: image,
                            },
                            largeImage: {
                              src: image,
                              width: 2000,
                              height: 2000,
                            },
                          }}
                        />
                      )}
                    </div>
                    {size > 991 ? (
                      <div className="show-horizon img-thumbs"></div>
                    ) : (
                      <>
                        {/* For mobile  */}
                        <div className="img-thumbs all-thumbs d-flex">
                          {videoDetails?.length > 0 &&
                            videoDetails?.map((vitem, index) => {
                              return (
                                <div
                                  key={index}
                                  className="img"
                                  style={{
                                    position: "relative",
                                    minWidth: "100px",
                                    marginBottom: 20,
                                  }}
                                >
                                  <img
                                    src={imageGallery?.[index]?.thumb}
                                    // className="img-fluid"
                                    alt={imageGallery?.[index]?.thumb}
                                    onClick={() => {
                                      console.log(vitem?.original);
                                      setImage(vitem.original);
                                    }}
                                  />
                                  <FiPlayCircle
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "10px",
                                      fontSize: "20px",
                                    }}
                                  />
                                </div>
                              );
                            })}
                          {product?.media?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="img"
                                style={{
                                  position: "relative",
                                  marginBottom: 20,
                                }}
                              >
                                <img
                                  key={index}
                                  src={item.thumb}
                                  // className="img-fluid"
                                  alt={item.thumb}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (transformWrapperRef.current) {
                                      const { zoomIn, resetTransform } =
                                        transformWrapperRef.current;

                                      resetTransform();

                                      setIsZoomedIn(false);
                                    }
                                    setImage(item.original);
                                    // setIsZoomedIn(true);
                                    // handleDoubleClick();
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="col-lg-5 col-xl-5 col-xxl-4 col-3xl-4  "
                  style={{
                    padding: `${
                      !isAboveMobile
                        ? "0px 25px"
                        : !isAboveTablet
                        ? "0px 80px"
                        : !isAboveDesktop
                        ? "0px 180px"
                        : "0px"
                    }`,
                  }}
                >
                  <div className="product-detail">
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className="ratting d-flex mb-1"
                        style={{
                          fontSize: "12px",
                          color: "#777777",
                        }}
                      >
                        <div className="mb-0">
                          {[1, 2, 3, 4, 5]?.map((item) => {
                            if (avgReview == 0) {
                              return <i className="fa-solid fa-star"></i>;
                            } else if (item <= avgReview) {
                              return (
                                <i className="fa-solid fa-star text-primary"></i>
                              );
                            } else {
                              return <i className="fa-solid fa-star"></i>;
                            }
                          })}
                        </div>
                        <div className="mb-0 ms-2">{reviewCount} review</div>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn"
                          // onClick={() => {
                          //   handleFavorite(product?.id);
                          //   setProduct((pre) => {
                          //     return {
                          //       ...pre,
                          //       favourite: !product?.favourite,
                          //     };
                          //   });
                          // }}
                        >
                          {product?.favourite ? (
                            <HeartFill
                              className="wishlist-heart"
                              fillColor={"#e77e50"}
                              strokeColor={"#e77e50"}
                              onClick={() => {
                                handleRemove(product?.id);
                                setProduct((pre) => {
                                  return {
                                    ...pre,
                                    favourite: !product?.favourite,
                                  };
                                });
                              }}
                            />
                          ) : (
                            <HeartFill
                              className="wishlist-heart"
                              fillColor={"none"}
                              strokeColor={"#e77e50"}
                              onClick={() => {
                                handleFavorite(product);
                                setProduct((pre) => {
                                  return {
                                    ...pre,
                                    // favourite: true,
                                    favourite: !!!product?.favourite,
                                  };
                                });
                              }}
                            />
                          )}
                        </button>
                      </div>
                    </div>

                    <div
                      className="mb-1"
                      style={{
                        fontSize: "22px",
                        fontWeight: 600,
                      }}
                    >
                      {product.title}
                    </div>
                    {!!productPrice && (
                      <div
                        className="product-price mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "24px",
                        }}
                      >
                        {currencyIcon(countryCurrency)} {productPrice}
                      </div>
                    )}
                    <div className="mb-2 font-16">
                      <label className="text-bold">Carat weight:</label>{" "}
                      {JSON.parse(product.item_detail)?.["Carat Total Weight"]}
                    </div>
                    <div className="mb-2 font-16">
                      <label className="text-bold">Metal:</label>{" "}
                      {product.caption}
                    </div>

                    {!!productVariant && (
                      <div className="color-select justify-content-start">
                        {Object?.keys(productVariant)?.map((key, index) => {
                          if (!!!productVariant?.[key]) return;
                          return (
                            <div
                              key={index}
                              className={`main-color ${
                                productVariant?.[key] === sku ? "selected" : ""
                              } ${
                                productVariant?.[key]
                                  ? "cursor-pointer"
                                  : "not-allowed"
                              }`}
                            >
                              <div
                                key={index}
                                className={`color-circle ${
                                  productVariant?.[key] === sku
                                    ? "selected"
                                    : ""
                                }`}
                                style={{ background: colorsVariant?.[key] }}
                                onClick={() =>
                                  productVariant?.[key] &&
                                  navigate(`/${title}/${productVariant?.[key]}`)
                                }
                              ></div>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    <div>
                      <div className="form-group mb-3 fs-18">
                        <label htmlFor="" className="mb-2">
                          {getProductType(product?.product_type)}
                          {/* {product?.product_type == 0
                            ? "Ring Size"
                            : product?.product_type == 2
                            ? "Chain Length(Inches)"
                            : product?.product_type == 3
                            ? "Length(Inches)"
                            : ""} */}
                        </label>
                        <div className="d-flex flex-row gap-2 align-items-center ">
                          <select
                            className="form-select w-auto"
                            onChange={(e) => {
                              setProductSize(e.target.value);
                            }}
                            defaultValue={"Please Select"}
                          >
                            <option value={""}> Please Select</option>
                            {getProductTypeOptionData(product?.product_type)}
                          </select>
                          {/* {product?.product_type == 0 && (
                            <select
                              className="form-select w-auto"
                              onChange={(e) => {
                                setProductSize(e.target.value);
                              }}
                              defaultValue={"Please Select"}
                            >
                              <option value={""}> Please Select</option>
                              {getRingSize(countryCurrency)?.map((el) => {
                                return (
                                  <option value={el?.value}>{el?.label}</option>
                                );
                              })}
                            </select>
                          )}
                          {product?.product_type == 2 && (
                            <select
                              className="form-select w-auto"
                              onChange={(e) => {
                                setProductSize(e.target.value);
                              }}
                              defaultValue={"Please Select"}
                            >
                              <option value={""}> Please Select</option>
                              {necklesSize?.map((el) => {
                                return (
                                  <option value={el?.value}>{el?.label}</option>
                                );
                              })}
                            </select>
                          )}
                          {product?.product_type == 3 && (
                            <select
                              className="form-select w-auto"
                              onChange={(e) => {
                                setProductSize(e.target.value);
                              }}
                              defaultValue={"Please Select"}
                            >
                              <option value={""}> Please Select</option>
                              {braceletSize?.map((el) => {
                                return (
                                  <option value={el?.value}>{el?.label}</option>
                                );
                              })}
                            </select>
                          )} */}
                          <div className="guide-info mb-0 d-flex flex-row align-items-center ">
                            <a href="/buying-guide/ring-size/" className="mb-0">
                              <i className="fa-solid fa-chart-gantt"></i> Size
                              Guide
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 d-flex gap-2">
                        <div className="w-100">
                          <button
                            className=" btn-add-to-cart"
                            style={{ minWidth: "100%" }}
                            onClick={() => onAddToCartHandelar()}
                          >
                            Add to cart
                          </button>
                        </div>
                      </div>
                      <div
                        className="mb-4"
                        style={{
                          width: "100%",
                        }}
                      >
                        {!!productPrice ? (
                          <button
                            type="button"
                            className="btn btn-buy w-100 mb-0"
                            onClick={() => {
                              onAddToCartHandelar(true);
                            }}
                          >
                            Buy it Now
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-buy w-100 mb-0"
                          >
                            Enquire Now
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="guide-info border-top border-bottom py-4">
                      {/* <div className="mb-1">
                        <GrDiamond /> Andor created diamond
                      </div> */}
                      <div className="mb-1" href="#">
                        <i className="fa-regular fa-clock"></i> Made-to-order.
                        Ships by{" "}
                        <span className="text-muted">
                          {" "}
                          {deliveryDate.toDateString()}
                        </span>
                      </div>

                      <a href="/our-policy#lifetime">
                        <SiAdguard /> Lifetime warranty
                      </a>
                      <a href="/shipping-policy">
                        <RiTruckLine /> Shipping policy
                      </a>
                      <a href="/return">
                        <IoMdRefresh /> Return policy
                      </a>
                    </div>

                    <div className="contact-detail-card d-flex justify-content-between gap-3">
                      {/* <div
                        className="card-li text-center cursor-pointer"
                        onClick={() =>
                          setModalFormData({
                            dropHint: {
                              id: product.id,
                              title: product?.title,
                              image: product.media[0].original,
                            },
                          })
                        }
                      >
                        <MdOutlineAttachEmail size={24} />
                        <p className="m-0">Drop Hint</p>
                      </div> */}
                      <div
                        className="card-li text-center  cursor-pointer mx-4"
                        onClick={() =>
                          setModalFormData({
                            emailsupport: {
                              id: product.id,
                              title: product?.title,
                              image: product.media[0].original,
                            },
                          })
                        }
                      >
                        <TfiEmail size={24} />
                        <p className="m-0">Email</p>
                      </div>
                      <a
                        href="/contact"
                        className="card-li text-center cursor-pointer mx-4"
                        style={{ color: "black" }}
                      >
                        <IoCallOutline size={24} />
                        <p className="m-0">Contact Us</p>
                      </a>
                    </div>

                    {/* <p className="text-muted">
                  Made-to-Order | Free Shipping | Free Returns
                </p> */}
                  </div>
                </div>
              </div>
            </CustomContainer>
            {/* End:: Product Detail */}

            {/* Begin::  Long detail */}
            <CustomContainer className="product-desc">
              <Tabs
                defaultActiveKey="home"
                className="mb-sm-3 mb-4 flex-column flex-md-row"
                // className="custom-tab selling-tabs"
                variant="underline"
              >
                <Tab eventKey="home" title="Product Detail">
                  <ProductDetailTab
                    diamondDetails={diamondDetails}
                    itemDetails={itemDetails}
                    sideDiamondDetails={sideDiamondDetails}
                  />
                </Tab>

                <Tab eventKey="contact" title="Reviews">
                  <ReviewTab reviewList={reviewList} />
                </Tab>

                <Tab eventKey="faq" title="FAQ">
                  {isFaq !== 0 ? (
                    <EngagementFAQ
                      isHeaderShow={false}
                      faqs={detailFaq?.[isFaq]}
                    />
                  ) : null}
                </Tab>
              </Tabs>

              <div className="mt-5">
                {/* <h2>RELATED PRODUCTS</h2> */}
                {/* <GlobalTitle title="RELATED PRODUCTS" /> */}

                <ImageSliderNew
                  title="RELATED PRODUCTS"
                  data={suggestProduct}
                  productKey="suggestionProduct"
                  getOnclick={() => getSuggestionProductHandler(product)}
                />
              </div>

              {/* Begin:: Review form */}

              {!!token && (
                <div className="row col-lg-10 col-xl-8 mx-auto review-form">
                  <div className="col-md-12">
                    <h3 className="text-center mb-4">Write Your own review</h3>
                    <div className="review-ratting  mb-3">
                      Product Rating :
                      <div className="rating-group ms-2">
                        <label
                          aria-label="1 star"
                          className="rating__label"
                          htmlFor="rating3-1"
                        >
                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                        </label>
                        <input
                          className="rating__input"
                          name="rating3"
                          id="rating3-1"
                          value="1"
                          type="radio"
                        />
                        <label
                          aria-label="2 stars"
                          className="rating__label"
                          htmlFor="rating3-2"
                        >
                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                        </label>
                        <input
                          className="rating__input"
                          name="rating3"
                          id="rating3-2"
                          value="2"
                          type="radio"
                        />
                        <label
                          aria-label="3 stars"
                          className="rating__label"
                          htmlFor="rating3-3"
                        >
                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                        </label>
                        <input
                          className="rating__input"
                          name="rating3"
                          id="rating3-3"
                          value="3"
                          type="radio"
                        />
                        <label
                          aria-label="4 stars"
                          className="rating__label"
                          htmlFor="rating3-4"
                        >
                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                        </label>
                        <input
                          className="rating__input"
                          name="rating3"
                          id="rating3-4"
                          value="4"
                          type="radio"
                        />
                        <label
                          aria-label="5 stars"
                          className="rating__label"
                          htmlFor="rating3-5"
                        >
                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                        </label>
                        <input
                          className="rating__input"
                          name="rating3"
                          id="rating3-5"
                          value="5"
                          type="radio"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="" className="form-label">
                        Name*
                      </label>
                      <input className="form-control" type="text" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="" className="form-label">
                        Email*
                      </label>
                      <input className="form-control" type="text" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="" className="form-label">
                        Your Review*
                      </label>
                      <textarea className="form-control" rows={7}></textarea>
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button
                      type="button"
                      className="btn btn-secondary btn-theme"
                    >
                      {" "}
                      Submit
                    </button>
                  </div>
                </div>
              )}
              {/* Begin:: Review form */}
            </CustomContainer>
            {/*  End::  Long detail */}
          </>
        )}

        {/* NO DEALBREAKERS */}
        <DealBreakerSection />

        {/* Drop Hint Modal */}
        <DropHintModal
          modalTitle="Drop Hint"
          modalsubTitle="Love this design? Share a little hint."
          dropHintData={modalFormData?.dropHint}
          handleCloseModal={handleCloseHintModal}
          formFields={dropHintEmailModal}
          yupSchema={generateYupSchema(dropHintEmailModal)}
          initialValues={initialHintDropValues}
          btnText="Send"
          handleSubmit={handleHintDropSubmit}
          footerText="By submitting this form, you agree that Andor Luxury may use your email address in accordance with our Privacy Policy."
        />

        {/* Email Modal */}
        <DropHintModal
          modalTitle="Need Help?"
          modalsubTitle="Our jewelry specialists are happy to answer your questions about this product."
          dropHintData={modalFormData?.emailsupport}
          handleCloseModal={handleCloseHintModal}
          formFields={emailModal}
          yupSchema={generateYupSchema(emailModal)}
          initialValues={initialEmailModalValues}
          btnText="Submit"
          handleSubmit={handleEmailModalSubmit}
          footerText="By submitting this form, you agree that Andor Luxury may use your email address in accordance with our Privacy Policy."
          // footerText="Your privacy is important to us."
        />
      </>
    );
  }

  return (
    <div className="vw-100 vh-100 border d-flex justify-content-center align-items-center">
      {!!loading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </div>
  );
};

export default Detail;
