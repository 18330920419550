import { Row, Col, Image } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import GlobalTitle from "../common/global-title";
import { CustomContainer } from "../common/global-display";
// import { imageURL2 } from "../../images";

const ContainerGridHome = ({ text, imageURL2 }) => {
  const Products = [
    {
      id: 1,
      name: text?.text_195,
      image: imageURL2?.image_116,
      link: "/wedding-bands",
      description: text?.text_196,
    },
    {
      id: 2,
      name: text?.text_197,
      image: imageURL2?.image_117,
      link: "/anniversary-bands",
      description: text?.text_198,
    },
    {
      id: 3,
      name: text?.text_199,
      image: imageURL2?.image_118,
      link: "/eternity-bands",
      description: text?.text_200,
    },
    {
      id: 4,
      name: text?.text_201,
      image: imageURL2?.image_119,
      link: "/ring-enhancers",
      description: text?.text_202,
    },
  ];
  return (
    <CustomContainer>
      <GlobalTitle title={text?.text_194 ?? "WEDDING COLLECTION"} />
      <div className="my-0 mb-4">
        <div className="container small-container product-container-small ">
          <Row className="my-3 row-gap-4">
            {Products?.map((singleProduct) => {
              return (
                <Col lg={3} sm={6} xs={6} key={singleProduct?.id}>
                  <div className="aspect-ratio aspect-ratio-16/9 mb-2">
                    <Link to={singleProduct?.link}>
                      <Image
                        src={singleProduct?.image}
                        alt={singleProduct?.name}
                        fluid
                        style={{
                          aspectRatio: "9/12",
                        }}
                      />
                    </Link>
                  </div>
                  <h5 className="mt-0 mb-2 text-center">
                    {singleProduct?.name}
                  </h5>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </CustomContainer>
  );
};

export default ContainerGridHome;
