import axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import "./App.scss";

import AnnouncementBar from "./component/anouncement";
import Campaing from "./component/campaing";
import CookieConsent from "./component/cookieconsent";
import { changeCartCurrency, isLogin } from "./constants/function";
import RoutePage from "./constants/routes";
import { getAnnouncements, UpdateRegion } from "./constants/urls";
import {
  addToCart,
  addToFavourite,
  getCartDetails,
  getFavouriteList,
} from "./features/cart/cartSlice";
import { getPageText } from "./features/text/getTextSlice";
import { getUserdetails } from "./features/user/getUserDetailsSlice";
import { getCountryPrice } from "./helper/constant";
// import { getCountryPrice } from "./helper/constant";
// import { response } from "express";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [announcements, setannouncements] = useState([]);
  async function getBulkData() {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    if (cart?.length <= 0 || wishlist?.length <= 0) {
      await dispatch(getCartDetails());
      // await dispatch(getFavouriteList());
      return;
    }
    const wishlistPayload = wishlist?.map((item) => {
      return item?.id;
    });

    const cartData = cart?.map((item) => {
      return {
        ...item,
        id: item?.id,
        qty: item?.qty,
        size: +item?.product?.Productsize,
      };
    });
    await dispatch(addToCart(cartData));
    await dispatch(addToFavourite(wishlistPayload));
    await dispatch(getCartDetails());
    await dispatch(getFavouriteList());
    // Clear localStorage
    localStorage.removeItem("cart");
    localStorage.removeItem("wishlist");
  }
  useEffect(() => {
    if (!!localStorage.getItem("token")) {
      dispatch(getUserdetails());
    }
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  useLayoutEffect(() => {
    getCountryPrice().then(async (resp) => {
      localStorage.setItem("currency", resp);
      if (isLogin()) {
        const currency =
          resp === "INR"
            ? 0
            : resp === "USD"
            ? 1
            : resp === "GBP"
            ? 2
            : resp === "CAD"
            ? 3
            : 1;
        const resPosnseofUpdate = await axios.get(
          `${UpdateRegion}/${currency}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        getBulkData();
        // console.log(resPosnseofUpdate.data.success,"locastion")
        if (resPosnseofUpdate?.data?.success) {
          // window.location.reload()
        }
      } else {
        changeCartCurrency(resp);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(
      getPageText(
        location.pathname == "/"
          ? `${process.env.REACT_APP_WEB_URL}${location.pathname}`
          : `${process.env.REACT_APP_WEB_URL}${location.pathname}/`
      )
    );
  }, [location.pathname]);

  useEffect(() => {
    fetchannouncements();
    // if (!localStorage.getItem("token")) return;

    // getBulkData();
  }, []);
  const fetchannouncements = async () => {
    try {
      const res = await axios?.get(getAnnouncements);
      const data = res?.data;
      // console.log(data?.data?.map(el => el?.title) ?? [])
      // console.log({...data ,...data?.data})
      setannouncements(data?.data?.map((el) => el?.title) ?? []);
    } catch (error) {
      console?.error("Error fetching marketing campaign:", error);
    }
  };
  const ProtectedRoute = ({ children }) => {
    if (!localStorage.getItem("token")) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" replace />;
    }

    return children ? children : <Outlet />;
  };
  // const announcements = [
  //   '<strong>New Features:</strong> Check out our latest update!',
  //   '<em>Webinar:</em> Don’t miss our next session!',
  //   'Visit our <a href="https://example.com">website</a> for more information.'
  // ];
  return (
    <div className="App">
      <Campaing />
      <AnnouncementBar data={announcements} />
      <RoutePage />
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <CookieConsent />
    </div>
  );
}

export default App;
