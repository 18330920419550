const baseurl = process.env.REACT_APP_WEB_URL;
// const baseurl = "http://localhost:3000";

export const imageURL = {
  logo: baseurl + "/images/logo.png",
  bag: baseurl + "/images/bag.svg",
  logo1: baseurl + "/images/new-logo-1.png",
  client1: baseurl + "/images/client-1.jpg",
  catBracelet: baseurl + "/images/bracelet-category.jpg",
  about1: baseurl + "/images/about-1.jpg",
  about5: baseurl + "/images/about-5.jpg",
  earring2: baseurl + "/images/earring-2.jpg",
  earring3: baseurl + "/images/earring-3.jpg",
  earring4: baseurl + "/images/earring-4.jpg",
  round: baseurl + "/images/round.png",
  cushion: baseurl + "/images/cushon.png",
  emerald: baseurl + "/images/emerald.png",
  heart: baseurl + "/images/heart.png",
  princess: baseurl + "/images/princess.png",
  marquise: baseurl + "/images/marquise.png",
  radiant: baseurl + "/images/radient.png",
  "elongated cushion": "/images/marquise.png",
  baguette: "/images/baguette.png",
  oval: baseurl + "/images/Oval.png",
  pear: baseurl + "/images/Pear.png",
  asscher: baseurl + "/images/asscher.png",

  van: baseurl + "/images/van.png",
  security: baseurl + "/images/security.png",
  handshake: baseurl + "/images/handshake.png",
  return: baseurl + "/images/return.png",

  downArrow: baseurl + "/images/down-arrow.svg",

  prodImgLg6: baseurl + "/images/new-product/lg/6.png",
  prodImgLg7: baseurl + "/images/new-product/lg/7.png",
  prodImgLg8: baseurl + "/images/new-product/lg/8.png",

  accountPrf: baseurl + "/images/accountant.png",
  forgotPrf: baseurl + "/images/forgot.png",
  editUserPrf: baseurl + "/images/edit-profile.png",
  orderHistoryPrf: baseurl + "/images/order-history.png",
  wishlistPrf: baseurl + "/images/wishlist.png",
  logoutPrf: baseurl + "/images/logout.png",
};

const baseurl2 = `${process.env.REACT_APP_CDN_URL}/front_images`;
// const baseurl2 = "http://192.168.0.26:8080/storage/front_images";
const time = new Date().getTime();

export const imageURL2 = {
  image_1: `${baseurl2}/image_1.jpg?t=${time}`,
  video_1: `${baseurl2}/image_1.mp4?t=${time}`,
  video_79: `${baseurl2}/image_79.mp4?t=${time}`,
  image_2: `${baseurl2}/image_2.jpg?t=${time}`,
  image_3: `${baseurl2}/image_3.jpg?t=${time}`,
  image_4: `${baseurl2}/image_4.png?t=${time}`,
  image_5: `${baseurl2}/image_5.png?t=${time}`,
  image_6: `${baseurl2}/image_6.png?t=${time}`,
  image_7: `${baseurl2}/image_7.jpg?t=${time}`,
  image_8: `${baseurl2}/image_8.png?t=${time}`,
  image_9: `${baseurl2}/image_9.png?t=${time}`,
  image_10: `${baseurl2}/image_10.png?t=${time}`,
  image_11: `${baseurl2}/image_11.png?t=${time}`,
  image_12: `${baseurl2}/image_12.jpg?t=${time}`,
  image_13: `${baseurl2}/image_13.jpg?t=${time}`,
  image_14: `${baseurl2}/image_14.jpg?t=${time}`,
  image_15: `${baseurl2}/image_15.png?t=${time}`,
  image_16: `${baseurl2}/image_16.png?t=${time}`,
  image_17: `${baseurl2}/image_17.png?t=${time}`,
  image_18: `${baseurl2}/image_18.png?t=${time}`,
  image_19: `${baseurl2}/image_19.png?t=${time}`,
  image_20: `${baseurl2}/image_20.png?t=${time}`,
  image_21: `${baseurl2}/image_21.jpg?t=${time}`,
  image_22: `${baseurl2}/image_22.png?t=${time}`,
  image_23: `${baseurl2}/image_23.png?t=${time}`,
  image_24: `${baseurl2}/image_24.png?t=${time}`,
  image_25: `${baseurl2}/image_25.png?t=${time}`,
  image_26: `${baseurl2}/image_26.png?t=${time}`,
  image_27: `${baseurl2}/image_27.png?t=${time}`,
  image_28: `${baseurl2}/image_28.png?t=${time}`,
  image_29: `${baseurl2}/image_29.png?t=${time}`,
  image_30: `${baseurl2}/image_30.png?t=${time}`,
  image_31: `${baseurl2}/image_31.png?t=${time}`,
  image_32: `${baseurl2}/image_32.png?t=${time}`,
  image_33: `${baseurl2}/image_33.png?t=${time}`,
  image_34: `${baseurl2}/image_34.png?t=${time}`,
  image_35: `${baseurl2}/image_35.png?t=${time}`,
  image_36: `${baseurl2}/image_36.png?t=${time}`,
  image_37: `${baseurl2}/image_37.png?t=${time}`,
  image_38: `${baseurl2}/image_38.jpg?t=${time}`,
  image_39: `${baseurl2}/image_39.png?t=${time}`,
  image_40: `${baseurl2}/image_40.png?t=${time}`,
  image_41: `${baseurl2}/image_41.png?t=${time}`,
  image_42: `${baseurl2}/image_42.png?t=${time}`,
  image_43: `${baseurl2}/image_43.jpg?t=${time}`,
  image_44: `${baseurl2}/image_44.png?t=${time}`,
  image_45: `${baseurl2}/image_45.jpg?t=${time}`,
  image_46: `${baseurl2}/image_46.jpg?t=${time}`,
  image_47: `${baseurl2}/image_47.png?t=${time}`,
  image_48: `${baseurl2}/image_48.jpg?t=${time}`,
  image_49: `${baseurl2}/image_49.jpg?t=${time}`,
  image_50: `${baseurl2}/image_50.jpg?t=${time}`,
  image_51: `${baseurl2}/image_51.png?t=${time}`,
  image_52: `${baseurl2}/image_52.png?t=${time}`,
  image_53: `${baseurl2}/image_53.jpg?t=${time}`,
  image_54: `${baseurl2}/image_54.png?t=${time}`,
  image_55: `${baseurl2}/image_55.jpg?t=${time}`,
  image_56: `${baseurl2}/image_56.jpg?t=${time}`,
  image_57: `${baseurl2}/image_57.png?t=${time}`,
  image_58: `${baseurl2}/image_58.jpg?t=${time}`,
  image_59: `${baseurl2}/image_59.jpg?t=${time}`,
  image_60: `${baseurl2}/image_60.jpg?t=${time}`,
  image_61: `${baseurl2}/image_61.png?t=${time}`,
  image_62: `${baseurl2}/image_62.jpg?t=${time}`,
  image_63: `${baseurl2}/image_63.jpg?t=${time}`,
  image_64: `${baseurl2}/image_64.jpg?t=${time}`,
  image_65: `${baseurl2}/image_65.jpg?t=${time}`,
  image_66: `${baseurl2}/image_66.jpg?t=${time}`,
  image_67: `${baseurl2}/image_67.png?t=${time}`,
  image_68: `${baseurl2}/image_68.png?t=${time}`,
  image_69: `${baseurl2}/image_69.jpg?t=${time}`,
  image_70: `${baseurl2}/image_70.jpg?t=${time}`,
  image_71: `${baseurl2}/image_71.jpg?t=${time}`,
  image_72: `${baseurl2}/image_72.jpg?t=${time}`,
  image_73: `${baseurl2}/image_73.png?t=${time}`,
  image_74: `${baseurl2}/image_74.jpg?t=${time}`,
  image_75: `${baseurl2}/image_75.jpg?t=${time}`,
  image_76: `${baseurl2}/image_76.png?t=${time}`,
  image_79: `${baseurl2}/image_79.jpg?t=${time}`,
  image_80: `${baseurl2}/image_80.png?t=${time}`,
  image_81: `${baseurl2}/image_81.png?t=${time}`,
  image_82: `${baseurl2}/image_82.png?t=${time}`,
  image_83: `${baseurl2}/image_83.png?t=${time}`,
  image_84: `${baseurl2}/image_84.webp?t=${time}`,
  image_85: `${baseurl2}/image_85.png?t=${time}`,
  image_86: `${baseurl2}/image_86.png?t=${time}`,
  image_87: `${baseurl2}/image_87.png?t=${time}`,
  image_88: `${baseurl2}/image_88.png?t=${time}`,
  image_89: `${baseurl2}/image_89.png?t=${time}`,
  image_90: `${baseurl2}/image_90.png?t=${time}`,
  image_91: `${baseurl2}/image_91.png?t=${time}`,
  image_92: `${baseurl2}/image_92.png?t=${time}`,
  image_93: `${baseurl2}/image_93.jpg?t=${time}`,
  image_94: `${baseurl2}/image_94.png?t=${time}`,
  image_95: `${baseurl2}/image_95.png?t=${time}`,
  image_96: `${baseurl2}/image_96.png?t=${time}`,
  image_97: `${baseurl2}/image_97.png?t=${time}`,
  image_98: `${baseurl2}/image_98.png?t=${time}`,
  image_99: `${baseurl2}/image_99.png?t=${time}`,
  image_100: `${baseurl2}/image_100.png?t=${time}`,
  image_101: `${baseurl2}/image_101.png?t=${time}`,
  image_102: `${baseurl2}/image_102.png?t=${time}`,
  image_103: `${baseurl2}/image_103.png?t=${time}`,
  image_104: `${baseurl2}/image_104.png?t=${time}`,
  image_105: `${baseurl2}/image_105.png?t=${time}`,
  image_106: `${baseurl2}/image_106.png?t=${time}`,
  image_107: `${baseurl2}/image_107.webp?t=${time}`,
  image_108: `${baseurl2}/image_108.png?t=${time}`,
  image_109: `${baseurl2}/image_109.jpg?t=${time}`,
  image_110: `${baseurl2}/image_110.png?t=${time}`,
  image_111: `${baseurl2}/image_111.png?t=${time}`,
  image_112: `${baseurl2}/image_112.png?t=${time}`,
  image_113: `${baseurl2}/image_113.jpg?t=${time}`,
  image_114: `${baseurl2}/image_114.jpg?t=${time}`,
  image_115: `${baseurl2}/image_115.jpg?t=${time}`,
  image_116: `${baseurl2}/image_116.jpg?t=${time}`,
  image_117: `${baseurl2}/image_117.jpg?t=${time}`,
  image_118: `${baseurl2}/image_118.jpg?t=${time}`,
  image_119: `${baseurl2}/image_119.jpg?t=${time}`,
  image_120: `${baseurl2}/image_120.jpg?t=${time}`,
  image_121: `${baseurl2}/image_121.jpg?t=${time}`,
  image_122: `${baseurl2}/image_122.jpg?t=${time}`,
  image_123: `${baseurl2}/image_123.jpg?t=${time}`,
  image_124: `${baseurl2}/image_124.jpg?t=${time}`,
  image_125: `${baseurl2}/image_125.jpg?t=${time}`,
  image_126: `${baseurl2}/image_126.jpg?t=${time}`,
  image_127: `${baseurl2}/image_127.jpg?t=${time}`,
  image_128: `${baseurl2}/image_128.jpg?t=${time}`,
  image_129: `${baseurl2}/image_129.jpg?t=${time}`,
  image_130: `${baseurl2}/image_130.jpg?t=${time}`,
  image_131: `${baseurl2}/image_131.jpg?t=${time}`,
  image_132: `${baseurl2}/image_132.jpg?t=${time}`,
  image_133: `${baseurl2}/image_133.jpg?t=${time}`,
  image_134: `${baseurl2}/image_134.jpg?t=${time}`,
  image_135: `${baseurl2}/image_135.jpg?t=${time}`,
  image_136: `${baseurl2}/image_136.png?t=${time}`,
  image_137: `${baseurl2}/image_137.png?t=${time}`,
  image_138: `${baseurl2}/image_138.jpg?t=${time}`,
  image_139: `${baseurl2}/image_139.png?t=${time}`,
  image_140: `${baseurl2}/image_140.jpg?t=${time}`,
  image_141: `${baseurl2}/image_141.jpg?t=${time}`,
  image_142: `${baseurl2}/image_142.jpg?t=${time}`,
  image_143: `${baseurl2}/image_143.png?t=${time}`,
  image_144: `${baseurl2}/image_144.png?t=${time}`,
  image_145: `${baseurl2}/image_145.png?t=${time}`,
  image_146: `${baseurl2}/image_146.png?t=${time}`,
  image_147: `${baseurl2}/image_147.jpg?t=${time}`,
  image_148: `${baseurl2}/image_148.png?t=${time}`,
  image_149: `${baseurl2}/image_149.png?t=${time}`,
  image_150: `${baseurl2}/image_150.png?t=${time}`,
  image_151: `${baseurl2}/image_151.png?t=${time}`,
  image_152: `${baseurl2}/image_152.png?t=${time}`,
  image_153: `${baseurl2}/image_153.png?t=${time}`,
  image_154: `${baseurl2}/image_154.png?t=${time}`,
  image_155: `${baseurl2}/image_155.png?t=${time}`,
  image_156: `${baseurl2}/image_156.png?t=${time}`,
  image_157: `${baseurl2}/image_157.png?t=${time}`,
  image_158: `${baseurl2}/image_158.png?t=${time}`,
  image_159: `${baseurl2}/image_159.png?t=${time}`,
  image_160: `${baseurl2}/image_160.png?t=${time}`,
  image_161: `${baseurl2}/image_161.png?t=${time}`,
  image_162: `${baseurl2}/image_162.png?t=${time}`,
  image_163: `${baseurl2}/image_163.png?t=${time}`,
  image_164: `${baseurl2}/image_164.png?t=${time}`,
  image_165: `${baseurl2}/image_165.png?t=${time}`,
  image_166: `${baseurl2}/image_166.png?t=${time}`,
  image_167: `${baseurl2}/image_167.png?t=${time}`,
  image_168: `${baseurl2}/image_168.png?t=${time}`,
  image_169: `${baseurl2}/image_169.png?t=${time}`,
  image_170: `${baseurl2}/image_170.png?t=${time}`,
  image_171: `${baseurl2}/image_171.png?t=${time}`,
  image_172: `${baseurl2}/image_172.png?t=${time}`,
  image_173: `${baseurl2}/image_173.png?t=${time}`,
  image_174: `${baseurl2}/image_174.png?t=${time}`,
  image_175: `${baseurl2}/image_175.png?t=${time}`,
  image_176: `${baseurl2}/image_176.png?t=${time}`,
  image_177: `${baseurl2}/image_177.png?t=${time}`,
  image_178: `${baseurl2}/image_178.png?t=${time}`,
  image_179: `${baseurl2}/image_179.png?t=${time}`,
  image_180: `${baseurl2}/image_180.png?t=${time}`,
  image_181: `${baseurl2}/image_181.png?t=${time}`,
  image_182: `${baseurl2}/image_182.png?t=${time}`,
  image_183: `${baseurl2}/image_183.png?t=${time}`,
  image_184: `${baseurl2}/image_184.png?t=${time}`,
  image_185: `${baseurl2}/image_185.png?t=${time}`,
  image_186: `${baseurl2}/image_186.png?t=${time}`,
  image_187: `${baseurl2}/image_187.png?t=${time}`,
  image_188: `${baseurl2}/image_188.png?t=${time}`,
  image_189: `${baseurl2}/image_189.png?t=${time}`,
  image_190: `${baseurl2}/image_190.png?t=${time}`,
  image_191: `${baseurl2}/image_191.jpg?t=${time}`,
  image_192: `${baseurl2}/image_192.jpg?t=${time}`,
  image_193: `${baseurl2}/image_193.jpg?t=${time}`,
  image_194: `${baseurl2}/image_194.jpg?t=${time}`,
  // image_195: `${baseurl2}/image_195.png?t=${time}`,
  // image_178: `${baseurl2}/image_178.jpg?t=${time}`,
};
