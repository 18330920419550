import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import {
  isLogin,
  newTransformProductData,
  transformProductData,
} from "../../constants/function";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import { CustomContainer } from "../common/global-display";
import GlobalTitle from "../common/global-title";
import CardSlider from "../ourlook/card-slider";
import "../ourlook/CardSlider.css"; // Assuming you have a CSS file for the styles
import SummryProductJS from "../ourlook/Product.json";
const Ourlook = ({ imageURL2 }) => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");

  const countryCurrency = localStorage.getItem("currency");
  const navigate = useNavigate();
  const [formatData, setFormateData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const getFormatedData = () => {
    const formatDatafromSummary = SummryProductJS?.map((item) => {
      const produtInfo =
        item?.product_variation || item?.item?.product_variation
          ? transformProductData(item, countryCurrency)
          : newTransformProductData(item, countryCurrency);
      return {
        ...produtInfo,
      };
    });
    console.log(formatDatafromSummary, "formatDatafromSummary");
    if (isLogin()) {
      return formatDatafromSummary;
    } else {
      const localData = JSON.parse(localStorage.getItem("wishlist")) || [];
      return formatDatafromSummary?.map((item) => {
        const updatedItem = {};
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            const localItem = localData?.find((i) => i?.id === item?.[key]?.id);
            if (item?.[key]?.id) {
              const isFavourite = localItem
                ? localItem?.product?.favourite
                : item?.[key]?.favourite;
              updatedItem[key] = {
                ...item?.[key],
                favourite: isFavourite,
              };
            } else {
              updatedItem[key] = updatedItem[key];
            }
          }
        }
        return updatedItem;
      });
    }
  };
  // const formatData = SummryProductJS?.map((item) => {
  //   const produtInfo =
  //     item?.product_variation || item?.item?.product_variation
  //       ? transformProductData(item, countryCurrency)
  //       : newTransformProductData(item, countryCurrency);
  //   return {
  //     ...produtInfo,
  //   };
  // });
  // if (isLogin()) {
  //   return formatData;
  // } else {
  //   const localData = JSON.parse(localStorage.getItem("wishlist")) || [];
  //   return formatData?.map((item) => {
  //     const updatedItem = {};
  //     for (const key in item) {
  //       if (item.hasOwnProperty(key)) {
  //         const localItem = localData?.find((i) => i?.id === item?.[key]?.id);
  //         const isFavourite = localItem
  //           ? localItem?.product?.favourite
  //           : item?.[key]?.favourite;
  //         updatedItem[key] = {
  //           ...item?.[key],
  //           favourite: isFavourite,
  //         };
  //       }
  //     }
  //     return updatedItem;
  //   });
  // }
  useEffect(() => {
    // getFormatedData()
    setFormateData(getFormatedData());
  }, [refresh]);
  const handleClick = () => {
    // let formattedName = produtInfo?.[selectedColor?.key]?.name.toLowerCase();
    // Replace all white spaces with hyphens
    // formattedName = formattedName.replace(/\s+/g, "-");
    // navigate(`/${formattedName}/${produtInfo?.[selectedColor?.key]?.sku}`, {
    //   state: {
    //     slug: produtInfo?.[selectedColor?.key]?.category
    //       ? produtInfo?.[selectedColor?.key]?.category
    //       : "",
    //   },
    // });
    navigate("#");
  };
  // const slideDataforDesktop = [
  //   {
  //     image: image1,
  //     productData: [formatData[0], formatData[1], formatData[2], formatData[6]],
  //     points: [
  //       { left: "95px", top: "45%" },
  //       { left: "170px", top: "60%" },
  //       { left: "230px", top: "45%" },
  //       { left: "240px", top: "30%" },
  //     ],
  //   },
  //   {
  //     image: image2,
  //     productData: [formatData[2], formatData[3]],
  //     points: [
  //       { left: "115px", top: "65%" },
  //       { left: "275px", top: "68%" },
  //     ],
  //   },
  //   {
  //     image: image3,
  //     productData: [formatData[4], formatData[5], formatData[6]],
  //     points: [
  //       { left: "220px", top: "50%" },
  //       { left: "195px", top: "60%" },
  //       { left: "235px", top: "68%" },
  //     ],
  //   },
  // ];
  const slideDataforDesktop = [
    {
      image: imageURL2?.image_178,
      productData: [formatData[0], formatData[1], formatData[2]],
      points: [
        { left: "15%", top: "45%" },
        { left: "40%", top: "60%" },
        { left: "73%", top: "70%" },
        // { left: "80%", top: "30%" },
      ],
    },
    {
      image: imageURL2?.image_179,
      productData: [formatData[3], formatData[4]],
      points: [
        { left: "23%", top: "55%" },
        { left: "80%", top: "75%" },
      ],
    },
    {
      image: imageURL2?.image_180,
      productData: [formatData[5], formatData[6], formatData[7]],
      points: [
        { left: "60%", top: "40%" },
        { left: "45%", top: "60%" },
        { left: "70%", top: "75%" },
      ],
    },
  ];
  // const slideDataforMobile = [
  //   {
  //     image: image1,
  //     productData: [formatData[0], formatData[1], formatData[2], formatData[6]],
  //     points: [
  //       { left: "95px", top: "45%" },
  //       { left: "170px", top: "60%" },
  //       { left: "230px", top: "45%" },
  //       { left: "240px", top: "30%" },
  //     ],
  //   },
  //   {
  //     image: image2,
  //     productData: [formatData[2], formatData[3]],
  //     points: [
  //       { left: "115px", top: "65%" },
  //       { left: "275px", top: "68%" },
  //     ],
  //   },
  //   {
  //     image: image3,
  //     productData: [formatData[4], formatData[5], formatData[6]],
  //     points: [
  //       { left: "220px", top: "50%" },
  //       { left: "195px", top: "60%" },
  //       { left: "235px", top: "68%" },
  //     ],
  //   },
  // ];
  return (
    <>
      <CustomContainer className="collection-slider our-look-slider  ">
        <GlobalTitle title="OUR LOOK" />
        {/* {isAboveDesktop ? (
          <ProductSliderForDesktop
            imageURL2={imageURL2}
            Products={formatData}
            slideData={slideDataforDesktop}
          />
        ) : (
          <ProductSliderForMobile
            imageURL2={imageURL2}
            Products={formatData}
            slideData={slideDataforMobile}
          />
        )} */}
        {formatData?.length && (
          <MainComponent
            slideData={slideDataforDesktop}
            isAboveDesktop={isAboveDesktop}
            refresher={() => {
              setRefresh((prev) => !prev);
              console.log("refresher");
            }}
          />
        )}
      </CustomContainer>
    </>
  );
};

export default Ourlook;
export const MainComponent = ({ slideData, isAboveDesktop, refresher }) => {
  const [showModal, setShowModal] = useState(false);

  const mobileSettings = {
    dots: false,

    className: "center",
    centerMode: true,
    infinite: true,
    // centerPadding: "25px",
    swipe: !showModal, // Disable swipe if showModal is true
    draggable: !showModal,
    slidesToShow: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          centerMode: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          swipe: true,
          draggable: true,

          appendDots: (dots) => (
            <div className="dot-custom">
              <ul className="p-0 mb-0">{dots}</ul>
            </div>
          ),
          customPaging: (i) => (
            <div className="custom-dot">
              <span>{i + 1}</span>
            </div>
          ),
        },
      },
      {
        breakpoint: 425,
        settings: {
          centerPadding: "35px",
          arrows: false,
        },
      },
      {
        breakpoint: 375,
        settings: {
          centerPadding: "25px",
          arrows: false,
        },
      },
      {
        breakpoint: 320,
        settings: {
          centerPadding: "10px",
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <Slider
        {...mobileSettings}
        className="look-slider "
        // className="look-slider d-block d-md-none"
      >
        {slideData?.map((data, index) => (
          <div key={`look-slide-${index}`}>
            <CardSlider
              setShowModals={setShowModal}
              image={data?.image}
              points={data?.points}
              data={data?.productData}
              isAboveDesktop={isAboveDesktop}
              onAddToFavourite={console.log}
              handleRemove={console.log}
              refresher={refresher}
            />
          </div>
        ))}
      </Slider>
      {/* <Slider
        {...settings}
        className="look-slider d-none d-md-block"
      >
        {slideData?.map((data, index) => (
          <div key={`look-slide-${index}`}>
            <CardSlider
              image={data?.image}
              points={data?.points}
              setShowModals={setShowModals}
              data={data?.productData}
            />
          </div>
        ))}
      </Slider> */}
    </>
  );
};
