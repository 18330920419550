import axios from "axios";
import { RingSizes } from "../component/product/productUtility";
import formatCurrency from "../helper/currencyFormater";
import { baseUrl } from "./urls";
import { braceletSize, necklesSize } from "./utils";

export const transformProductData = (data, countryCurrency) => {
  const createEntry = (variation, media = null) => {
    // console.log(variation, "dsjhfijkdhbfjds")

    return {
      ...variation,
      id: variation?.id,
      name: variation?.name ?? variation?.title,
      title: variation?.title ?? variation?.name,
      sku: variation?.sku,
      tag: variation?.tag,
      favourite: variation?.favourite ?? null,
      product_price: variation?.product_price,
      price:
        countryCurrency == "INR"
          ? formatCurrency(variation?.product_price)
          : countryCurrency == "USD"
            ? formatCurrency(variation?.usa_price)
            : countryCurrency == "GBP"
              ? formatCurrency(variation?.uk_price)
              : countryCurrency == "CAD"
                ? formatCurrency(variation?.canada_price)
                : formatCurrency(variation?.usa_price),
      currency: countryCurrency,
      // uk_price: variation?.uk_price,
      // usa_price: variation?.usa_price,
      metatags: variation?.metatags,
      caption: variation?.caption,
      imgUrl: variation?.image_1
        ? variation?.image_1
        : variation?.media_arr?.[0]
          ? variation?.media_arr?.[0]
          : variation?.media_arr?.[1],
      hoverImageUrl: !!variation?.image_2
        ? variation?.image_2
        : variation?.image_1
          ? variation?.image_1
          : variation?.media_arr?.[1]
            ? variation?.media_arr?.[1]
            : variation?.media_arr?.[1]
              ? variation?.media_arr?.[0]
              : variation?.media_arr?.[0],
      featured_image: variation?.media_arr?.map((image, index) => ({
        // ...image,
        original_url: image,
        // thumb: image?.thumb
      })),

      // image_1: variation?.image_1,
      // image_2: variation?.image_2,
      imageGallery: variation?.media_arr,
      caption: variation?.caption,
      sku: variation?.sku,
      ...(media && {
        imgUrl: media?.[0],
        hoverImageUrl: !!variation?.media_arr?.[1]
          ? variation?.media_arr?.[1]
          : variation?.media_arr?.[0],
      }),
    };
  };
  // console.log(createEntry(data?.product_variation?.yellow), "gold")
  return {
    silver: createEntry(data, data?.media_arr),
    yellow: data?.product_variation?.yellow
      ? createEntry(data?.product_variation?.yellow)
      : null,
    rose: data?.product_variation?.rose
      ? createEntry(data?.product_variation?.rose)
      : null,
  };
};

export const newTransformProductData = (data, countryCurrency) => {
  const createEntry = (variation, media = null) => {
    // console.log(variation, "dsjhfijkdhbfjds")

    return {
      ...variation,
      id: variation?.id,
      name: variation?.name ?? variation?.title,
      title: variation?.title ?? variation?.name,
      sku: variation?.sku,
      tag: variation?.tag,
      product_price: variation?.product_price,
      price:
        countryCurrency == "INR"
          ? formatCurrency(variation?.product_price)
          : countryCurrency == "USD"
            ? formatCurrency(variation?.usa_price)
            : countryCurrency == "GBP"
              ? formatCurrency(variation?.uk_price)
              : countryCurrency == "CAD"
                ? formatCurrency(variation?.canada_price)
                : formatCurrency(variation?.usa_price),
      currency: countryCurrency,
      // uk_price: variation?.uk_price,
      // usa_price: variation?.usa_price,
      metatags: variation?.metatags,
      caption: variation?.caption,
      imgUrl: variation?.image_1
        ? variation?.image_1
        : variation?.media_arr?.[0],
      hoverImageUrl: !!variation?.image_2
        ? variation?.image_2
        : variation?.image_1
          ? variation?.media_arr?.[1]
            ? variation?.media_arr?.[1]
            : variation?.media_arr?.[0]
          : variation?.image_1,
      featured_image: variation?.media_arr?.map((image, index) => ({
        // ...image,
        original_url: image,
        // thumb: image?.thumb
      })),
      favourite: variation?.favourite ?? false,
      // image_1: variation?.image_1,
      // image_2: variation?.image_2,
      imageGallery: [variation?.image_1, variation?.image_2],
      // ...(media && {
      //     imgUrl: variation?.image_1, hoverImageUrl: !!variation?.media_arr?.[1]
      //         ? variation?.media_arr?.[1]
      //         : variation?.media_arr?.[0],
      // }),
    };
  };
  // console.log(createEntry(data?.product_variation?.yellow), "gold")
  return {
    white: createEntry(data?.white),
    yellow: data?.yellow ? createEntry(data?.yellow) : null,
    rose: data?.rose ? createEntry(data?.rose) : null,
  };
};

export const getAuthToken = () => {
  return localStorage.getItem("token") || null;
};

export const isLogin = () => {
  return getAuthToken() !== undefined && getAuthToken() !== null;
};

export const currencyIcon = (currency) => {
  return currency === "INR"
    ? "₹"
    : currency === "USD"
      ? "$"
      : currency === "GBP"
        ? "£"
        : currency === "CAD"
          ? "CA$"
          : currency == "0"
            ? "₹"
            : currency == "1"
              ? "$"
              : currency == "2"
                ? "£"
                : currency == "3"
                  ? "CA$"
                  : "$";
};
export const getRingSize = (currency) => {
  const filteredRingSizes = {
    INR: RingSizes?.[0]?.filter((size) => size?.label && size?.value),
    USD: RingSizes?.[1]?.filter((size) => size?.label && size?.value),
    CAD: RingSizes?.[1]?.filter((size) => size?.label && size?.value),
    GBP: RingSizes?.[2]?.filter((size) => size?.label && size?.value),
  };
  return currency === "INR"
    ? filteredRingSizes?.INR
    : currency === "USD"
      ? filteredRingSizes?.USD
      : currency === "CAD"
        ? filteredRingSizes?.CAD
        : currency === "GBP"
          ? filteredRingSizes?.GBP
          : currency == "0"
            ? filteredRingSizes?.INR
            : currency == "1"
              ? filteredRingSizes?.USD
              : currency == "2"
                ? filteredRingSizes?.GBP
                : currency == "3"
                  ? filteredRingSizes?.CAD
                  : filteredRingSizes?.INR;
};

export const currencyToKey = (currency) => {
  return currency === "INR"
    ? "0"
    : currency === "USD"
      ? "1"
      : currency === "GBP"
        ? "2"
        : currency === "CAD"
          ? "3"
          : "0";
};
export const addToCartLocal = (productData) => {
  let cart = JSON.parse(localStorage.getItem("cart")) || [];

  // Check if the product already exists in the cart
  const productIndex = cart?.findIndex(
    (item) =>
      item?.product_id === productData?.id &&
      item?.product?.Productsize === productData?.Productsize,
  );
  if (productIndex !== -1) {
    // If product exists, increment its quantity
    cart[productIndex].qty += 1;
  } else {
    // If product doesn't exist, add it to the cart
    const updatedImages = productData?.media?.map((image) => ({
      ...image,
      original_url: image?.original,
      thumb: image?.thumb,
    }));
    const localStorageCurrency = localStorage.getItem("currency");
    const amount =
      localStorageCurrency === "INR"
        ? productData?.product_price
        : localStorageCurrency === "USD"
          ? productData?.usa_price
          : localStorageCurrency === "GBP"
            ? productData?.uk_price
            : localStorageCurrency === "CAD"
              ? productData?.canada_price
              : productData?.usa_price;
    cart?.push({
      product_id: productData?.id,
      id: productData?.id,
      qty: 1,
      // amount: productData?.product_price,
      amount,
      product: { ...productData, featured_image: updatedImages },
    });
    // cart?.push({ ...productData, qty: 1, amount: productData?.product_price });
  }
  localStorage.setItem("cart", JSON.stringify(cart));
  return cart?.length;
};
export const changeCartCurrency = (currency) => {
  let cart = JSON.parse(localStorage.getItem("cart")) || [];
  // console.log(cart, "cart ABC");
  const updatedCart = cart?.map((el) => ({
    ...el,
    amount:
      currency === "INR"
        ? el?.product?.product_price
        : currency === "USD"
          ? el?.product?.usa_price
          : currency === "GBP"
            ? el?.product?.uk_price
            : currency === "CAD"
              ? el?.product?.canada_price
              : el?.product?.usa_price,
  }));
  localStorage.setItem("cart", JSON.stringify(updatedCart));
};

export const cartIncreaseQtyLocal = (cartProductData) => {
  let cart = JSON.parse(localStorage.getItem("cart")) || [];

  // Check if the product already exists in the cart
  const productIndex = cart?.findIndex(
    (item) =>
      +item.product_id === +cartProductData?.cartProductId &&
      +item.product.Productsize === +cartProductData?.size,
  );

  if (productIndex !== -1) {
    // If product exists, increment its quantity
    cart[productIndex].qty += 1;
  } else {
  }
  localStorage.setItem("cart", JSON.stringify(cart));
  return cart;
};

export const cartDecreaseQtyLocal = (cartProductData) => {
  let cart = JSON.parse(localStorage.getItem("cart")) || [];

  // Check if the product already exists in the cart
  const productIndex = cart.findIndex(
    (item) =>
      item.product_id === cartProductData.cartProductId &&
      item.product.Productsize == cartProductData.size,
  );

  if (productIndex !== -1) {
    // Decrease the quantity of the product
    cart[productIndex].qty -= 1;

    // If the quantity becomes zero, remove the product from the cart
    if (cart[productIndex].qty === 0) {
      cart = cart.filter(
        (item) =>
          !(
            item.product_id === cartProductData.cartProductId &&
            item.product.Productsize == cartProductData.size
          ),
      );
    }
  }

  localStorage.setItem("cart", JSON.stringify(cart));
  return cart;
};

// export const removeFromCartLocal = (productId, size) => {
//   let cart = JSON.parse(localStorage.getItem("cart")) || [];
//   console.log(cart, size, productId)
//   cart = cart?.filter((item) => !(item.product_id === productId && item.product.Productsize === size));
//   localStorage.setItem("cart", JSON.stringify(cart));
// };
export const removeFromCartLocal = (productId) => {
  let cart = JSON.parse(localStorage.getItem("cart")) || [];

  cart = cart?.filter(
    (item) =>
      !(
        +item.product_id === +productId?.productCartId &&
        item.product.Productsize == productId?.size
      ),
  );

  localStorage.setItem("cart", JSON.stringify(cart));
};

export const getCartLocal = () => {
  return JSON.parse(localStorage.getItem("cart")) || [];
};

export const addToWishlistLocal = (productData) => {
  let wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  let cartList = JSON.parse(localStorage.getItem("cart")) || [];
  // if (!wishlist.includes(product)) {
  //     wishlist.push(product);
  //     localStorage.setItem('wishlist', JSON.stringify(wishlist));
  // }

  // const wishListIndex = wishlist?.findIndex(item => item?.product_id === productData?.id);
  // if (wishListIndex !== -1) {
  //     // If product exists, increment its quantity
  //     wishlist[wishListIndex].qty += 1;
  // } else {
  //     console.log(productData)
  // If product doesn't exist, add it to the wishlist

  const updatedImages = productData?.media?.map((image) => ({
    ...image,
    original_url: image?.original,
    thumb: image?.thumb,
  }));
  const img = {
    imgUrl: productData?.imgUrl
      ? productData?.imgUrl
      : productData?.media?.[0]?.original
        ? productData?.media?.[0]?.original
        : productData?.imageGallery?.[0],
    hoverImg: productData?.hoverImageUrl
      ? productData?.hoverImageUrl
      : productData?.media?.[1]?.original
        ? productData?.media?.[1]?.original
        : productData?.imageGallery?.[1],
  };
  wishlist?.push({
    product_id: productData?.id,
    id: productData?.id,
    // qty: 1,
    amount: productData?.product_price,
    product: { ...productData, ...img, favourite: true },
  });
  if (cartList && cartList?.length > 0) {
    cartList = cartList?.map((item) => {
      if (item?.id === productData?.id) {
        item["product"]["favourite"] = true;
      }
      return item;
    });
    localStorage.setItem("cart", JSON.stringify(cartList));
  }

  // }
  localStorage.setItem("wishlist", JSON.stringify(wishlist));
  return wishlist;
};

export const removeFromWishlistLocal = (productId) => {
  let wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  let cartList = JSON.parse(localStorage.getItem("cart")) || [];
  wishlist = wishlist.filter((item) => item?.id !== productId);
  cartList = cartList?.map((item) => {
    if (item?.id === productId) {
      item["product"]["favourite"] = false;
    }

    return item;
  });
  localStorage.setItem("wishlist", JSON.stringify(wishlist));
  localStorage.setItem("cart", JSON.stringify(cartList));

  return wishlist;
};

export const getWishlistLocal = () => {
  return JSON.parse(localStorage.getItem("wishlist")) || [];
};

export const padZeros = (num, length) => {
  return num.toString().padStart(length, "0");
};
export function getQueryParam(param) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}

export const headerHoverEffect = ({
  isHovered,
  setIsSticky,
  setIsHovered,
  location,
}) => {
  const handleScroll = () => {
    if (!isHovered || location.pathname !== "/") {
      setIsSticky(window.scrollY > 0);
    }
  };

  const handleMouseEnter = () => {
    setIsSticky(true);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsSticky(window.scrollY > 0);
    setIsHovered(false);
  };

  // Attach the event listeners
  window.addEventListener("scroll", handleScroll);
  if (location.pathname === "/") {
    const headerElement = document.querySelector(
      ".header-section-hover-maintainer ",
    );

    if (headerElement) {
      headerElement.addEventListener("mouseenter", handleMouseEnter);
      headerElement.addEventListener("mouseleave", handleMouseLeave);
    }

    // Clean up the event listeners
    return () => {
      if (location.pathname === "/") {
        if (headerElement) {
          headerElement.removeEventListener("mouseenter", handleMouseEnter);
          headerElement.removeEventListener("mouseleave", handleMouseLeave);
        }
      }
      window.removeEventListener("scroll", handleScroll);
    };
  }
};
// export const headerHoverEffect = ({
//   isHovered,
//   setIsSticky,
//   setIsHovered,
//   location,
// }) => {
//   const handleScroll = () => {
//     if (!isHovered || location.pathname !== "/") {
//       setIsSticky(window.scrollY > 0);
//     }
//   };

//   const handleMouseEnter = () => {
//     setIsSticky(true);
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsSticky(window.scrollY > 0);
//     setIsHovered(false);
//   };

//   const handleMouseMove = (e) => {
//     const headerElement = document.querySelector(".header-section");

//     if (headerElement) {
//       const rect = headerElement.getBoundingClientRect();
//       const isInHeader = (
//         e.clientX >= rect.left &&
//         e.clientX <= rect.right &&
//         e.clientY >= rect.top &&
//         e.clientY <= rect.bottom
//       );

//       if (isInHeader) {
//         handleMouseEnter();
//       } else {
//         handleMouseLeave();
//       }
//     }
//   };

//   // Attach the event listeners
//   window.addEventListener("scroll", handleScroll);
//   if (location.pathname === "/") {
//     const headerElement = document.querySelector(".header-section");

//     if (headerElement) {
//       headerElement.addEventListener("mouseenter", handleMouseEnter);
//       headerElement.addEventListener("mouseleave", handleMouseLeave);
//       document.addEventListener("mousemove", handleMouseMove);
//     }

//     // Clean up the event listeners
//     return () => {
//       if (location.pathname === "/") {
//         if (headerElement) {
//           headerElement.removeEventListener("mouseenter", handleMouseEnter);
//           headerElement.removeEventListener("mouseleave", handleMouseLeave);
//         }
//         document.removeEventListener("mousemove", handleMouseMove);
//       }
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }
// };

export const getSuggestionProduct = async (product) => {
  const user = JSON?.parse(localStorage.getItem("user"));
  const res = await axios.get(
    `${baseUrl}/api/suggestion?product_id=${product?.id}${user?.id ? `&user_id=${user?.id}` : ""
    }`,
  );
  const data = await res.data;
  const formatData = await data?.data?.map((item) => {
    const produtInfo =
      item?.product_variation || item?.item?.product_variation
        ? transformProductData(item, localStorage.getItem("currency"))
        : newTransformProductData(item, localStorage.getItem("currency"));
    return {
      ...produtInfo,
    };
  });

  if (isLogin()) {
    return formatData;
  } else {
    const localData = JSON.parse(localStorage.getItem("wishlist")) || [];
    return formatData?.map((item) => {
      const updatedItem = {};
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          const localItem = localData?.find((i) => i?.id === item?.[key]?.id);
          const isFavourite = localItem
            ? localItem?.product?.favourite
            : item?.[key]?.favourite;
          updatedItem[key] = {
            ...item?.[key],
            favourite: isFavourite,
          };
        }
      }
      return updatedItem;
    });
  }
};

export const manageHeaderColor = (isSticky, path, isHeaderTextWhite) => {
  return isHeaderTextWhite ? "text-white" : "text-transperant";
  if (isSticky && path === "/") {
    return "text-white";
  }
  if (!isSticky && path === "/") {
    return "text-transperant";
  }
  return "text-white";
};


export const getProductType = (type) => {
  return +type === 0
    ? "Ring Size"
    : +type === 2
      ? "Chain Length(Inches)"
      : +type === 3
        ? "Length(Inches)"
        : ""
}

export const getProductTypeOptionData = (type) => {
  const countryCurrency = localStorage.getItem("currency")
  return +type === 0
    ? getRingSize(countryCurrency)?.map((el) => {
      return (
        <option value={el?.value}>{el?.label}</option>
      );
    }) : +type === 2 ?
      necklesSize?.map((el) => {
        return (
          <option value={el?.value}>{el?.label}</option>
        );
      }) : +type === 3 ?
        braceletSize?.map((el) => {
          return (
            <option value={el?.value}>{el?.label}</option>
          );
        }) : [];
}