import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import { getQueryParam } from "../../../../constants/function";
import Wishlist from "../../../product/Wishlist";
import Orders from "../../Orders";
import AddressPage from "./AddressPage";
import ChangePassword from "./ChangePassword";
import UpdateProfile from "./UpdateProfile";

function EditProfile() {
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("user_name");
    window.location.href = "/";
  };

  const location = useLocation();

  const [activeKey, setActiveKey] = useState("editProfile"); // Set the default active key
  const [isLogout, setIsLogout] = useState(false);

  useEffect(() => {
    if (activeKey === "logout") {
      setIsLogout(true);
    }
    // if(window.location.href && window.location.href?.split("?")[1]){
    //   setActiveKey(window.location.href?.split("?")[1])
    // }
    const KeyofTab = getQueryParam("tab");
    if (KeyofTab) {
      setActiveKey(KeyofTab);
    }
  }, [activeKey]);

  const navBarOptions = [
    {
      id: 1,
      title: "EDIT PROFILE",
      eventKey: "editProfile",
      element: <UpdateProfile />,
      bredCrumb: "Edit Profile",
    },
    {
      id: 2,
      title: "CHANGE PASSWORD",
      eventKey: "changePassword",
      element: <ChangePassword />,
      bredCrumb: "Change Password",
    },
    {
      id: 3,
      title: "ORDERS",
      eventKey: "orders",
      element: <Orders activeKey={activeKey} />,
      bredCrumb: "Orders",
    },
    {
      id: 4,
      title: "ADDRESS",
      eventKey: "address",
      element: <AddressPage activeKey={activeKey} />,
      bredCrumb: "Address",
    },
    // {
    //   id: 5,
    //   title: "RETURNS",
    //   eventKey: "returns",
    //   element: <Return />,
    //   onclick: handleClick,
    //   bredCrumb: "Return",
    // },
    {
      id: 6,
      title: "WISHLIST",
      eventKey: "wishlist",
      element: <Wishlist activeKey={activeKey} />,
      bredCrumb: "Wishlist",
    },
    {
      id: 7,
      title: "LOGOUT",
      eventKey: "logout",
      element: (
        <Modal
          show={isLogout}
          onHide={() => {
            setActiveKey("editProfile");
            setIsLogout(false);
          }}
          centered
        >
          <Modal.Header>
            <Modal.Title>Logout</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to logout?</Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              onClick={() => {
                setActiveKey("editProfile");
                setIsLogout(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleLogout}
              style={{ color: "white" }}
            >
              Logout
            </Button>
          </Modal.Footer>
        </Modal>
      ),
      bredCrumb: "Logout",
    },
  ];

  useEffect(() => {
    if (!!location?.state?.eventKey) {
      setActiveKey(location?.state?.eventKey);
    }
  }, [location.state]);

  useEffect(() => {
    if (!location?.search?.includes("order_id")) return;
    if (location?.search?.includes("order_id")) {
      setActiveKey("orders");
    }
  }, []);

  const handleChange = (e) => {
    setActiveKey(e.target.value);
  };

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  return (
    <>
      <div className="container my-3">
        <Breadcrumb>
          <LinkContainer to="/" className="link-breadcrumb">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </LinkContainer>
          <LinkContainer to="/profile" className="link-breadcrumb">
            <Breadcrumb.Item>My Account</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>
            {
              navBarOptions.find((item) => item.eventKey === activeKey)
                .bredCrumb
            }
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-title mb-5">
        <div
          style={{
            color: "#e77e50",
            fontSize: "22px",
            fontWeight: 700,
          }}
        >
          {navBarOptions.find((item) => item.eventKey === activeKey).title}
        </div>
        {/* <h5>Home - My Account - Edit Profile</h5> */}
      </div>

      <div
        className="my-3 container d-none d-md-block"
        style={{ fontSize: "14px" }}
      >
        <Tabs
          activeKey={activeKey}
          id="profile-tabs"
          className="mb-3   flex-nowrap"
          variant="pills"
          onSelect={handleSelect}
        >
          {navBarOptions.map((item) => {
            return (
              <Tab eventKey={item.eventKey} title={item.title} key={item.id}>
                {item.element}
              </Tab>
            );
          })}
        </Tabs>
      </div>

      <div className="container d-md-none">
        <div className="account-wrapper">
          <Form.Select
            aria-label="Default select example"
            value={activeKey}
            onChange={handleChange}
            className="form-select"
          >
            {navBarOptions.map((item) => {
              return (
                <option
                  value={item.eventKey}
                  key={item.id}
                  style={{ cursor: "pointer" }}
                >
                  <div className="options"> {item.title}</div>
                </option>
              );
            })}
          </Form.Select>
          <div className="account-content">
            {/* Content based on activeKey */}
            {navBarOptions.find((item) => item.eventKey === activeKey).element}
            {/* Add additional content rendering for other keys */}
          </div>
        </div>
      </div>
    </>
  );
}

export default EditProfile;
