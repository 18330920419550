import { Image } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import SliderComponent from "../Slider/sliderComponent";
import GlobalTitle from "../common/global-title";
import { CustomContainer } from "../common/global-display";

const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 769,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

const EngagementRingElegance = ({ Products, title = "", description = "" }) => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");

  return Products?.length ? (
    <CustomContainer>
      {!!title && <GlobalTitle title={title} />}
      {isAboveDesktop ? (
        <>
          {" "}
          <p className="text-secondary text-black  m-0 text-center">
            From round cut diamonds to princess cut diamonds and{" "}
          </p>
          <p className="text-secondary text-black p-0 m-0 text-center">
            everything in between, find the diamond
          </p>
          <p className="text-secondary text-black p-0 m-0 mb-3 text-center">
            shape that speaks to you.
          </p>{" "}
        </>
      ) : (
        <p className="text-secondary text-black pt-3 m-0 text-center">
          From round cut diamonds to princess cut diamonds and everything in
          between, find the diamond shape that speaks to you.
        </p>
      )}

      <div className="mb-4">
        <CustomContainer className=" mt-0 mb-0">
          <div className="   collection-slide abcd ">
            {/* <ProductSlider Products={Products} /> */}
            <SliderComponent
              slidesToShow={5}
              responsive={responsive}
              data={
                Products?.map((singleProduct) => {
                  return (
                    <div
                      key={singleProduct?.id}
                      className="text-center Rings_hover_on w-100 h-100"
                    >
                      <div className="Rings_hover_on  mb-2">
                        <Link
                          to={singleProduct?.link}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <Image
                            src={singleProduct?.image}
                            alt={singleProduct?.name}
                            style={{
                              width: "112px",
                              objectFit: "cover",
                            }}
                            fluid
                            className="Rings_img_hover"
                          />
                        </Link>
                      </div>
                      <h5>{singleProduct?.name}</h5>
                    </div>
                  );
                }) ?? []
              }
            />
          </div>
        </CustomContainer>
      </div>
    </CustomContainer>
  ) : (
    ""
  );
};

export default EngagementRingElegance;
