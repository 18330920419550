import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import SliderComponent from "../Slider/sliderComponent";
const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 5.5,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 769,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];
const WeddingEngageCatSlider = ({ Products }) => {
  return (
    <div className="container">
      <SliderComponent
        slidesToShow={3}
        responsive={responsive}
        data={
          Products?.map((singleProduct) => {
            return (
              <div key={singleProduct?.id} className="text-center">
                <div className="Rings_hover  mb-2">
                  <Link to={singleProduct?.link}>
                    <Image
                      src={singleProduct?.image}
                      alt={singleProduct?.name}
                      fluid
                      className="Rings_img_hover"
                    />
                  </Link>
                </div>
              </div>
            );
          }) ?? []
        }
      />
    </div>
  );
};

export default WeddingEngageCatSlider;
