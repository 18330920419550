import React from "react";
import { CustomContainer } from "./global-display";
import { Breadcrumb } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const GlobalBreadcrumb = ({ subTitle = "", subTo = null }) => {
  return (
    <>
      <CustomContainer className="my-3 page-title text-start">
        <Breadcrumb>
          <LinkContainer to="/" className="link-breadcrumb">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </LinkContainer>
          {subTo ? (
            <LinkContainer to={subTo} className="link-breadcrumb">
              <Breadcrumb.Item>{subTitle}</Breadcrumb.Item>
            </LinkContainer>
          ) : (
            <Breadcrumb.Item active>{subTitle}</Breadcrumb.Item>
          )}
        </Breadcrumb>
      </CustomContainer>
    </>
  );
};

export default GlobalBreadcrumb;
