import React from "react";
import GlobalTitle from "../common/global-title";
import { CustomContainer } from "../common/global-display";

const Terms = () => {
  return (
    <>
      <CustomContainer>
        <CustomContainer className="static-page">
          <GlobalTitle title="Terms & Conditions" />
          <div className="">
            <p>
              <a href={`${process.env.REACT_APP_WEB_URL}`}>Andorluxury.com</a>{" "}
              operated by VABS Diamond (“Andor Luxury” or “VABS Diamond” or
              “website” or “we” or “our”) is the shopping website of VABS
              Diamond that allows consumers to browse, select and purchase Lab
              Grown Diamond Jewelry, precious stones and or precious metal
              jewelry and accessories (“Products” or “Goods”) from the website.
            </p>
            <p>
              andorluxury.com provides services to you subject to the terms and
              conditions included in this Terms of Use and other customer
              service pages to help make your shopping experience with
              andorluxury.com as enjoyable and problem-free as possible. Please
              read them carefully. By visiting or using this website or service,
              you acknowledge that you have read and understood, and agree to be
              bound by, these Terms of Use. You also agree to comply with all
              applicable laws and regulations, including Copyright and Trademark
              laws.
            </p>
            <p>
              If you do not agree to these terms, please do not use
              andorluxury.com website.
            </p>
            <p>
              If you would like to provide feedback about any andorluxury.com
              website, or recommend a way we can improve the buying experience,
              please write to{" "}
              <a href="mailto:info@andorluxury.com">info@andorluxury.com</a>
            </p>
            <p>
              If you have any questions about these Terms and Conditions, please
              contact on{" "}
              <a href="mailto:info@andorluxury.com">info@andorluxury.com</a>
            </p>
            <h2 className="sub-hd1 mb-2 pt-3">
              ACCEPTANCE OF TERMS & CONDITIONS :
            </h2>
            <h3 className="sub-hd1 mb-2 pt-3">PRIVACY</h3>
            <p>Your privacy is important to us and we will protect it.</p>
            <p>
              andorluxury.com collects, stores, processes and uses your
              information in accordance with andorluxury.com Privacy Policy. By
              using the website and/ or by providing your information, you
              consent to the collection and use of the information you disclose
              on the website by andorluxury.com in accordance with
              andorluxury.com Privacy Policy. Please review andorluxury.com
              Privacy Policy.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">OWNERSHIP & COPYRIGHT</h3>
            <p>
              This andorluxury.com web site is owned and operated by VABS
              Diamond Unless otherwise noted, all design and content included on
              this website, including text, graphics, logos, icons, images,
              artwork, products, audio and video clips and software is the
              property of VABS Diamond (or is used under license to VABS
              Diamond) and is protected by Indian Copyright Act, 1957 and
              international copyright laws.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">USE OF WEBSITE & LICENSE</h3>
            <p>
              andorluxury.com web site and content are intended solely for
              personal and non-commercial use by visitors and on-line shoppers.
              Any use of andorluxury.com website or its content other than for
              personal and non-commercial purposes is prohibited. You agree not
              to reproduce, publish, transmit, distribute, modify, create
              derivative works from, or commercially exploit in any way the
              content of andorluxury.com website. However, you may download,
              electronically copy and print any of the content of
              andorluxury.com website for your personal, non-commercial use
              only. This is a revocable license, not a transfer of title, and is
              subject to the restrictions that you may not (a) modify the
              content or use it for any commercial purpose, or any public
              display, performance, sale or rental, (b) decompile, reverse
              engineer, or disassemble the content, or (c) remove any copyright,
              trademark registration, or other proprietary notices from the
              content. You further agree not to access or use any Andor Luxury
              website in any manner that may be harmful to the operation of the
              web site or its content.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">USAGE ELIGIBILITY</h3>
            <p>
              Use of the website is available only to persons who can form
              legally binding contracts under Indian Contract Act, 1872 and any
              other applicable international law for this purpose. Persons who
              are “incompetent to contract” within the meaning of the Indian
              Contract Act, 1872 and any other applicable International law for
              this purpose including minors, un-discharged insolvents etc. are
              not eligible to use the website. If you are under 18 years old,
              you may use andorluxury.com only with the involvement of a parent
              or legal guardian. andorluxury.com reserves the right to refuse
              service, terminate accounts, remove or edit content or cancel
              orders in its sole discretion. If you do not qualify, you may not
              use the andorluxury.com service or the website. Additionally, you
              are prohibited from selling, trading, or otherwise transferring
              their andorluxury.com account to another party.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">YOUR ACCOUNT & REGISTRATION</h3>
            <p>
              If you access or use andorluxury.com website, you are responsible
              for maintaining the confidentiality of your account and for
              restricting access to your computer. You agree to be responsible
              for all activities that occur under your account or password. You
              agree, inter alia, to provide true, accurate, current and complete
              information about yourself as prompted by website registration
              form or provided by you as a visitor or user of a third party site
              through which you access the Website. You agree to notify
              andorluxury.com immediately of any unauthorized use of your
              account or any other breach of security. andorluxury.com reserves
              the right to refuse service, terminate accounts, or remove or edit
              content in its sole discretion. You hereby grant us a
              non-exclusive, worldwide, perpetual, irrevocable, royalty-free,
              sub-licensable (through multiple tiers) right to exercise the
              copyright, publicity, and database rights you have in your
              information, in any media or medium now known or developed,
              produced, invented or used in future, with respect to your
              information.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">PRODUCT DESCRIPTION</h3>
            <p>
              andorluxury.com makes all reasonable efforts to display the
              products listed for sale on its website as accurately as possible.
              However, the colors we use, as well as the display and color
              capabilities of your computer monitor, will affect the colors that
              you actually see on your screen. andorluxury.com cannot guarantee
              that your monitor’s display of any product color, texture or
              detail will be accurate. In addition, andorluxury.com does not
              warrant that product descriptions or other content are accurate,
              complete, reliable current or error free. While andorluxury.com
              makes every effort to ensure that our products are described and
              priced accurately, in the event that an item is deemed to be
              priced incorrectly, andorluxury.com reserves the right to refuse
              sale that item. If the pricing error is discovered after payment
              has been finalized, andorluxury.com reserves the right to cancel
              the sale and refund the transaction in full. If a product offered
              by andorluxury.com is not as described, your sole remedy is to
              return it in unused condition.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">EQUIPMENT</h3>
            <p>
              Website user shall be responsible for obtaining and maintaining
              all telephone, computer hardware and other equipment needed for
              access to and use of this website and all charges related thereto.
              andorluxury.com shall not be liable for any damages to the website
              visitor’s equipment resulting from the use of this website.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">CONTENT YOU SUBMIT</h3>
            <p>
              andorluxury.com appreciates hearing from you. Blogs, community
              forums, chat rooms and other discussion sites may be made
              available to our customers and users. You acknowledge and agree,
              that as to any content you, as a user, may submit via the Online
              Mediums become public information and it shall be deemed to be
              provided on a non-confidential basis, except for resumes submitted
              solely for consideration for employment with andorluxury.com which
              may be shared with andorluxury.com on a “need to know” basis. You
              should always use caution when submitting personal identifiable
              information about yourself or third parties in any online mediums.
              andorluxury.com is free to use or disseminate such contributed
              content on an unrestricted basis for any purpose, without any
              notice or compensation to you. andorluxury.com is under no
              obligation to use the contributed content and your submissions and
              may remove any such content or submission at any time at its sole
              discretion. andorluxury.com reserves the right to terminate your
              access to online mediums at any time, without notice, for any
              reason whatsoever.
            </p>
            <p>
              You represent and warrant that you own or otherwise have
              permission to submit any such materials and grant andorluxury.com
              the rights described herein
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">USE NO LAWFUL OR PROHIBITED</h3>
            <p>
              You agree not to upload, post or otherwise transmit via the online
              mediums any content that: (i) is misleading, harmful, threatening,
              abusive, harassing, defamatory, offensive, violent, obscene,
              pornographic, vulgar, libelous, racially, ethnically, religiously
              or otherwise objectionable; (ii) constitutes unauthorized
              disclosure of personal or confidential information; (iii)
              infringes any patent, trademark, trade secret, publicity right,
              privacy right, copyright or other intellectual property or any
              rights of any party; (iv) contains viruses, Trojan horses, Worms,
              corrupted files or code, files and programs designed to impede or
              destroy the functionality of any computer software or hardware;
              spyware and malware designed for phishing and with a view to
              compromise the data security and integrity, and obtain sensitive
              personal or financial information.
            </p>
            <p>
              You also agree not to use the andorluxury.com website or online
              mediums to: (i) impersonate an employee or a representative of
              andorluxury.com, its divisions and subsidiaries; (ii) misrepresent
              your identity or affiliation with a person or entity; (iii) send
              bulk mail, spam, “chain letters” and other unsolicited and
              unauthorized communication; (iv) attempt to gain unauthorized
              access to any portion or feature of the andorluxury.com or any
              other system or networks connected to the andorluxury.com or any
              other services offered through the andorluxury.com, and/or other
              accounts not belonging to you, or violate security of any portion
              of andorluxury.com, by hacking, password mining or any other
              means; (v) obtain or attempt to obtain any information, materials
              or documents not purposely made available through the
              andorluxury.com or online mediums through any means; (vi) attempt
              to interfere with the proper working of the andorluxury.com or any
              transaction being conducted on the andorluxury.com or to restrict
              or inhibit any other user from accessing or using the
              andorluxury.com, by means of hacking or defacing any portion of
              the andorluxury.com; (vii) violate any applicable local, state,
              national or international law, including without limitation
              regulations under Indian Court of Law.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">DISCLAIMER OF WARRANTY</h3>
            <p>
              He Materials and Information Provided By andorluxury.com On Its
              Website Is Provided On An “As Is” and “As Available” Basis.
              andorluxury.com Makes No Representations or Warranties of any Kind
              , Express or Implied, As to the Operation of Its Website or The
              Information, Content , Materials or Products Included on Its
              WebSite. You Agree That Your Access To, And Use Of andorluxury.com
              Websites is at your Own Risk.
            </p>
            <p>
              To The Fullest Extent Permissible By Applicable Law,
              andorluxury.com Disclaims All Warranties, Express or Implied,
              Including, Without Limitation ,Implied Warranties of
              Merchantability And Fitness For a Particular Purpose.
              andorluxury.com Does Not Warrant that Its Website ,Its Servers ,
              or Email sent from andorluxury.com is Free Of Viruses Or Other
              Harmful Components. andorluxury.com Will Not Be Liable For any
              Damages of any Kind Arising From Use of the Website ,Including,
              Without Limitation , Incidental , Punitive or Consequential
              Damages
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">LIMITATION OF LIABILITY</h3>
            <p>
              This disclaimer of liability applies to any damages or injury
              caused by any failure or performance, error, omission,
              interruption, deletion, defect, delay in operation or
              transmission, computer virus, act of God, communication line
              failure, theft or destruction or unauthorized access to,
              alteration of, or use of record, whether for breach of contract,
              tortuous behavior, negligence, or under any other cause of action.
              You also specifically acknowledge that andorluxury.com is not
              liable for your defamatory, offensive, infringing or illegal
              materials or conduct or that of third parties.
            </p>
            <p>
              We as a merchant shall be under no liability whatsoever in respect
              of any loss or damage arising directly or indirectly out of the
              decline of authorization for any Transaction, on Account of the
              Cardholder having exceeded the preset limit mutually agreed by us
              with our acquiring bank from time to time.
            </p>
            <p>
              The law of certain countries may not allow limitations on
              warranties or damages as described above. If such law applies to
              you, some or all of the above disclaimers, exclusions or
              warranties may not apply to you and you may have additional
              rights. However, in no event shall andorluxury.com’s aggregate
              liability to you for all claims, damages, losses, and causes of
              action exceed the amount paid to andorluxury.com by you for
              accessing andorluxury.com web site.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">INDEMNITY</h3>
            <p>
              You agree to defend, indemnify, and hold harmless andorluxury.com
              and its officers, directors, shareholders, affiliates, employees
              and agents from and against any claims, actions, or demands,
              including without limitation reasonable legal and accounting fees,
              arising from your use of andorluxury.com website or your breach of
              these Terms of Use or your violation of any law or the rights of a
              third party including but not limited to breach of any warranties,
              representations or undertakings or in relation to the
              non-fulfillment of any of your obligations under this Agreement or
              arising out of violation of any applicable laws, regulations
              including but not limited to Intellectual Property Rights, payment
              of statutory dues and taxes, claim of libel, defamation, violation
              of rights of privacy or publicity, loss of service by other
              subscribers and infringement of intellectual property or other
              rights. This clause shall survive the expiry or termination of
              this Terms of Use.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">COUPONS TERMS & CONDITIONS</h3>
            <p>
              The offer contained in the electronic communication (“eCoupons”)
              is made by andorluxury.com Jewels and is open to targeted
              andorluxury.com registered users (“Users”). These terms and
              conditions are in addition to the existing andorluxury.com Terms
              of Use. eCoupons are also not offered to, and shall not be used
              by, such users who under any statutory obligations are prohibited
              to use eCoupons. eCoupon is the property of Andor Luxury has the
              right to cancel the eCoupons at will and at any time (including
              during the validity period of the eCoupon) without giving any
              prior intimation to the User. eCoupon is a privilege from Andor
              Luxury and not a right or claim against Andor Luxury. eCoupons can
              also be cancelled in case the user is in violation of
              andorluxury.com Terms of Use.
            </p>
            <p>
              User using the eCoupons in any manner shall be deemed to have
              read, understood and accepted these terms and conditions.
            </p>
            <p>
              The value and the mode of using the eCoupon are mentioned in the
              electronic communication. The validity of eCoupon is subject to
              the condition that the User remains a registered user and is
              neither suspended nor in violation of andorluxury.com Terms of
              Use.
            </p>
            <p>
              eCoupons can be redeemed only on purchases made through
              andorluxury.com on those listings which provides for an option of
              eCoupon payments. eCoupons shall be invalidated in case of
              rejection of payment by Andor Luxury due to Andor Luxury risk
              containment measures. eCoupons cannot be used to buy any item
              which is illegal or prohibited by Terms of Use.
            </p>
            <p>
              eCoupon carries no cash value and cannot be transferred,
              negotiated or sold and cannot be redeemed against cash. eCoupons
              cannot be combined with any other coupons or any other offer or
              gift vouchers/cards or discounts or promotions.
            </p>
            <p>
              One eCoupon can be used only for one purchase irrespective of the
              value of such purchase and the unutilized or underutilized value
              of the eCoupons shall be forfeited and the User cannot claim any
              benefit or a substitute eCoupon in lieu of the same. The purchase
              value of the item in excess of the value of the eCoupon shall be
              paid by the User along with using the eCoupon.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">GRIEVANCE OFFICER</h3>
            <p>
              In accordance with Information Technology Act 2000 and rules made
              there under, the Grievance Officer for the purpose of your
              personal sensitive information as governed by the Privacy Policy
              is Pierce and can be reached at info@andorluxury.com
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">GOVERNMENT LAW</h3>
            <p>
              This user agreement and all the rules and policies contained
              herein and any of your usage of website and your dealings at
              andorluxury.com shall be governed and construed in accordance with
              the laws of India. By visiting andorluxury.com, you agree that the
              law of India, without regard to principles of conflict of laws,
              shall govern these Terms of Use and any dispute that may arise
              between you and Andor Luxury. If any court of competent
              jurisdiction determines that any provision, or any portion
              thereof, contained in these Terms of Use is unenforceable in any
              respect, such provision shall be deemed limited to the extent that
              such court deems it enforceable, and as so limited shall remain in
              full force and effect. If such court shall deem any such
              provision, or portion thereof, wholly unenforceable, the remaining
              provisions of these Terms of Use shall nevertheless remain in full
              force and effect. These Terms of Use, together with the Privacy
              Policy, constitute the entire agreement between you and Andor
              Luxury relating to your access to and use of andorluxury.com
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">DISPUTE RESOLUTION</h3>
            <p>
              Any dispute arising with respect to this Agreement shall be
              resolved by negotiation between the parties or, if necessary, by
              resort to an appropriate court located in Surat, India.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">MODIFICATION</h3>
            <p>
              andorluxury.com reserves the right, in its sole discretion, to
              change, modify, add, or remove portions of these Terms of Use at
              any time. Please check this page periodically for changes. You
              agree that each time you visit andorluxury.com website, your visit
              to and use of such website will be subject to and bound by the
              then current version of these Terms of Use.
            </p>
            Date of Last Update of these Terms of Use: Jan. 13, 2022.
            <p>
              andorluxury.com website is operated by Andor Luxury from its
              offices in Surat, India. Andor Luxury makes no representations
              that the contents of any of its website are appropriate or
              available for use in other locations. Visitors who choose to
              access andorluxury.com from other locations do so on their own
              initiative and are responsible for compliance with applicable
              local law.
            </p>
            <p>
              Once again, we thank you for your interest in andorluxury.com We
              hope that your experience at andorluxury.com is enjoyable.
            </p>
            <p>
              This document is an electronic record under Information Technology
              Act, 2000 and rules there under as applicable and the amended
              provisions pertaining to electronic records in various statutes
              under applicable Indian laws. This electronic record is generated
              by a computer system and does not require any physical or digital
              signatures.
            </p>
            <p>
              This document is published and shall be construed in accordance
              with the provisions of Rule 3 (1) of the Information Technology
              (Intermediaries guidelines) Rules, 2011 that require publishing
              the rules and regulations, privacy policy and user agreement for
              access or usage of andorluxury.com website.
            </p>
          </div>
        </CustomContainer>
      </CustomContainer>
    </>
  );
};

export default Terms;
