import React from "react";
import { Button, Image, Modal } from "react-bootstrap";
import GlobalForm from "../form/globalForm";
import { IoClose } from "react-icons/io5";

const DropHintModal = ({
  dropHintData,
  handleCloseModal,
  formFields,
  yupSchema,
  initialValues,
  btnText,
  handleSubmit,
  modalTitle,
  modalsubTitle,
  footerText,
}) => {
  return (
    <>
      <Modal
        className="drop-hint-modal"
        centered
        dialogClassName="drop-hint-modal-dialog"
        show={!!dropHintData}
        onHide={handleCloseModal}
        backdropClassName="drop-hint-modal-backdrop bg-black "
        // size="lg"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="p-4 p-md-5 relative">
          <div className=" drop-hint-modal-close ">
            <Button
              className=" mb-3 bg-transparent border-0 "
              // variant="secondary"
              onClick={handleCloseModal}
            >
              <IoClose size={24} />
            </Button>
          </div>
          <div className="drop-hint-modal-header">
            <h3 className="">{modalTitle ?? "Drop a Hint"}</h3>
            <h7 className="mb-2 text-mute text-start d-flex">
              {modalsubTitle ?? "Love this design? Share a little hint."}
            </h7>
          </div>
          <div className="d-flex align-items-center gap-3 mb-5">
            <Image
              src={
                dropHintData?.image ??
                "https://image.brilliantearth.com/media/diamond_ring_vto/03/BE18959_yellow_Oval_top_2_carat.png"
              }
              style={{
                width: "90px",
                height: "90px",
                objectFit: "contain",
              }}
            />
            <p className="text-bold font-16">
              {dropHintData?.title ??
                "18K Yellow Gold Petite Elodie 1.5mm Ring"}
            </p>
          </div>

          <div className="drop-hint-modal-form">
            <GlobalForm
              initialValues={initialValues}
              validationSchema={yupSchema}
              onSubmit={handleSubmit}
              fields={formFields}
              submitBtnclass="width-100 btn-add-to-cart"
              btnText={btnText}
              removeInputBox
            />
          </div>
          {footerText && (
            <p className="text-muted fs-7 my-2">
              {footerText}{" "}
              <a
                className="text-decoration-underline text-muted"
                href="/privacy-policy"
              >
                View privacy policy
              </a>
              .
            </p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DropHintModal;
