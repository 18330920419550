import React from "react";
import TableRingSize from "./TableRingSize";
import MeasureRingatHome from "./MeasureRingatHome";
import MeasureRingSection1 from "./MeasureRingSection1";
import MeasureRingSection2 from "./MeasureRingSection2";
import RingFit from "./RingFit";
import RingResizingInfo from "./RingResizingInfo";
import RingSizeFAQ from "./RingSizeFAQ";
import Footer from "../element/Footer";
import HeroRingSize from "./HeroRingSize";

const BuyRingSize = () => {
  return (
    <>
      <HeroRingSize />
      <TableRingSize />
      <MeasureRingatHome />
      <MeasureRingSection1 />
      <MeasureRingSection2 />
      <RingFit />
      <RingResizingInfo />
      <RingSizeFAQ />
      {/* <Footer /> */}
    </>
  );
};

export default BuyRingSize;
