import GlobalTitle from "../common/global-title";
import GlobalFaq from "../common/global-faq";
import { CustomContainer } from "../common/global-display";

const RingSizeFAQ = () => {
  const faqs = [
    {
      question: "Does Andor Luxury offer half-ring sizes?",
      answer:
        "Yes! You can always order half-size rings to ensure you find the perfect fit. You can also request a design in a quarter size by contacting us via phone or email.",
    },
    {
      question: "What if I order the wrong ring size?",
      answer:
        "If you order the wrong size, contact us to begin the complimentary resize process within 60 days of when your piece was initially shipped out to you or first available for pick up in the showroom. We'll confirm a new size with you and resize your ring free of charge. If you ordered an eternity ring, open band, alternative metal design, or any other style that cannot be resized, you may exchange it for the correct size within our 30-day policy.",
    },
    {
      question: "What happens when my ring is resized?",
      answer:
        "To resize your ring to the perfect fit, our jewelers will add or remove a small strip of metal from the back of the band and solder it back together – you won't be able to tell it's been resized.",
    },
    {
      question: "What is the average ring size for women?",
      answer:
        "The average ring size for women is between size 5 and 7, though this can vary greatly.",
    },
    {
      question: "What is the average ring size for men?",
      answer:
        "The average ring size for men is between 8 and 10. Use our printable chart or order a free ring sizer to determine the perfect size.",
    },
    {
      question: "How do you secretly get ring size?",
      answer:
        "If you need to know your partner's ring size without them knowing, selecting a standard size or slightly larger size is the best choice. The average size for women's rings is 6, and for men's rings 9. You can also try measuring a ring they already wear or asking a friend or family member if they know their size. Whichever method you go with, always double-check the product detail page to confirm that your specific design can be resized to ensure you can eventually find the perfect fit.",
    },
    {
      question: "Does Andor Luxury offer inclusive ring sizing?",
      answer:
        "Yes, Andor Luxury offers inclusive ring sizing options. If you need a ring size that is not listed on our site, please contact us to inquire about special orders.",
    },
  ];

  return (
    <CustomContainer className="mt-0 mb-0">
      <GlobalTitle title="Ring Size FAQs" />
      <GlobalFaq data={faqs} />
    </CustomContainer>
  );
};

export default RingSizeFAQ;
