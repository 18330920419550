import React from "react";
import Wishlist from "./Wishlist";

const WhislistPage = () => {
  return (
    <div className="container my-3">
      <div className="account-wrapper">
        <div className="account-content">
          <Wishlist />
        </div>
      </div>
    </div>
  );
};

export default WhislistPage;
