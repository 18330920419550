import React, { useEffect, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getBraceletJewelry,
  getEarringJewelry,
  getNecklessJewelry,
  getRingsJewelry,
} from "../../features/jewelry/jewelrySlicer";
import {
  newTransformProductData,
  transformProductData,
} from "../../constants/function";
import ImageSliderNew from "../Slider/imageSlidernew";
import { CustomContainer } from "../common/global-display";

const RelatedProduct = () => {
  const dispatch = useDispatch();
  const earringData = useSelector((state) => state.jewelryReducer.earrings);
  const braceletData = useSelector((state) => state.jewelryReducer.bracelet);
  const ringData = useSelector((state) => state.jewelryReducer.rings);
  const necklessData = useSelector((state) => state.jewelryReducer.neckless);
  const userdata = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    dispatch(getEarringJewelry(userdata?.id));
  }, []);

  const [countryCurrency, setCountryCurrency] = useState("INR");
  // const countryCurrency = localStorage.getItem("currency");

  useEffect(() => {
    setCountryCurrency(localStorage.getItem("currency"));
  }, []);

  const [listOfEarring, setListOfEarring] = useState([]);
  const [listOfBracelet, setListOfBracelet] = useState([]);
  const [listOfRing, setListOfRing] = useState([]);
  const [listOfNeckless, setListOfNeckless] = useState([]);

  useEffect(() => {
    if (!!earringData?.data) {
      const data = earringData?.data?.data.map((data) => {
        const produtInfo = data?.product_variation
          ? transformProductData(data, countryCurrency)
          : newTransformProductData(data, countryCurrency);
        return {
          // ...data,
          ...produtInfo,
        };
      });
      setListOfEarring(data);
    }
  }, [earringData.data]);
  useEffect(() => {
    if (!!braceletData?.data) {
      const data = braceletData?.data?.data.map((data) => {
        const produtInfo = data?.product_variation
          ? transformProductData(data, countryCurrency)
          : newTransformProductData(data, countryCurrency);
        return {
          // ...data,
          ...produtInfo,
        };
      });
      setListOfBracelet(data);
    }
  }, [braceletData.data]);
  useEffect(() => {
    if (!!ringData?.data) {
      const data = ringData?.data?.data.map((data) => {
        const produtInfo = data?.product_variation
          ? transformProductData(data, countryCurrency)
          : newTransformProductData(data, countryCurrency);
        return {
          // ...data,
          ...produtInfo,
        };
      });
      setListOfRing(data);
    }
  }, [ringData.data]);
  useEffect(() => {
    if (!!necklessData?.data) {
      const data = necklessData?.data?.data.map((data) => {
        const produtInfo = data?.product_variation
          ? transformProductData(data, countryCurrency)
          : newTransformProductData(data, countryCurrency);
        return {
          // ...data,
          ...produtInfo,
        };
      });
      setListOfNeckless(data);
    }
  }, [necklessData.data]);

  const getBraceletData = () => {
    if (!braceletData.success) {
      dispatch(getBraceletJewelry(userdata?.id));
    }
  };
  const getNecklessData = () => {
    if (!necklessData.success) {
      dispatch(getNecklessJewelry(userdata?.id));
    }
  };
  const getRingData = () => {
    if (!ringData.success) {
      dispatch(getRingsJewelry(userdata?.id));
    }
  };

  const tabData = [
    {
      title: "Earrings",
      data: listOfEarring,
      onclick: () => {},
    },
    {
      title: "Bracelet",
      data: listOfBracelet,
      onclick: getBraceletData,
    },
    {
      title: "Rings",
      data: listOfRing,
      onclick: getRingData,
    },
    {
      title: "Necklaces",
      data: listOfNeckless,
      onclick: getNecklessData,
    },
  ];

  return (
    <>
      {/* Begin:: Categories */}
      <CustomContainer className="about-sec5 position-relative">
        <Tab.Container id="category-tabs" defaultActiveKey="Earrings">
          <Row className="">
            <Col md={12} className="">
              <Nav
                // variant="pills"
                variant="underline"
                className="my-7 d-flex justify-content-center align-items-center category-tab-list"
              >
                {tabData?.map((data, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link eventKey={data?.title} onClick={data?.onclick}>
                      {data?.title}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col md={12}>
              <Tab.Content className="category-content">
                {tabData?.map((data, index) => (
                  <Tab.Pane eventKey={data?.title} key={index}>
                    <div className="row">
                      <ImageSliderNew data={data?.data} />
                    </div>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </CustomContainer>
      {/* End:: Categories */}
    </>
  );
};

export default RelatedProduct;
