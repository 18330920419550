import React from "react";
import GlobalTitle from "../common/global-title";
import { CustomContainer } from "../common/global-display";

const Refund = () => {
  return (
    <>
      <CustomContainer>
        <CustomContainer className="static-page">
          <GlobalTitle title="Return Policy" />
          <div className="">
            <h3 className="sub-hd1 mb-3">DETAILED TERMS & CONDITIONS:</h3>
            <p>
              THE 30 DAYS RETURN / CASHBACK AND / LIFETIME EXCHANGE POLICIES ARE
              SUBJECT TO TERMS AND CONDITIONS AS MENTIONED BELOW:
            </p>
            <ul>
              <li>
                You will receive complimentary return shipping for all eligible
                products for return/cashback within the 30 Days Exchange policy.
                Shipping charges for all other returns/exchange/cashback shall
                be borne by the Customer.
              </li>
              <li>
                Once your returned item is received, our Quality Assurance
                department will review the returned item. Any items that show
                signs of wear or have been altered, resized (by a jeweller other
                than Andor Luxury , or damaged cannot be accepted for return/
                cashback.
              </li>
              <li>
                The product should be accompanied with Original Product
                Certificate and Original/Copy of Invoice for all
                returns/exchange/cashback.
              </li>
              <li>
                In case the customer cannot produce the original certificate and
                invoice, then cashback policy shall not be entertained under any
                circumstances.
              </li>
              <li>
                Products with any physical damage on Lab Grown Diamond shall not
                be considered by any returns/cashback at any price.
              </li>
              <li>
                The return/cashback amount will be as determined by our team at
                Andor Luxury. In case, any Coupon, Discount, or Promo Codes are
                used during the original purchase, the amount will be reduced by
                the amount equivalent to the Coupon, Discount, or Promo Codes.
              </li>
              <li>
                For all Cashback transactions, GST at the applicable rate shall
                be collected from the cashback value. The prevailing rate is 3%.
              </li>
              <li>
                In case of Cashback, the payment shall be made by way of an
                account payee cheque or bank transfer in the same name as of
                invoice within 30 days of receipt of the product along with
                Certificate and Invoice subject to quality confirmation and
                acceptance.
              </li>
              <li>
                Andor Luxury reserves the right to change the exchange/cashback
                price of the product based on the Quality Inspection.
              </li>
              <li>
                In case of an exchange, the customer will have to pay the
                difference between the new product chosen and the valuation of
                the old product before dispatch.
              </li>
              <li>
                For the 30 days Exchange/Return policy, if the product is
                purchased at a discounted price under any promotion, then we
                will only refund the discounted price as per the invoice.
              </li>
            </ul>
            <h3>ORDER CANCELLATIONS:</h3>
            <ul>
              <li>
                In the event, you cancel your order after we have started making
                your jewellery but before it has shipped, the applicable
                restocking fee (20%) will be charged.
              </li>
              <li>
                To cancel an order, you must contact Andor Luxury Customer
                Service to obtain a Return Authorization Number (RA#) or email
                us on{" "}
                <a href="mailto:supprt@andorluxury.com">
                  supprt@andorluxury.com
                </a>{" "}
                Once an item has shipped, the order cannot be cancelled.
              </li>
            </ul>
          </div>
        </CustomContainer>
      </CustomContainer>
    </>
  );
};

export default Refund;
