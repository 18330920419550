import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import SliderComponent from "../Slider/sliderComponent";
import { CustomContainer } from "../common/global-display";
import GlobalTitle from "../common/global-title";

const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: true,
      dots: false,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      initialSlide: 2,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

const CollectionDontMiss = ({ text, imageURL2 }) => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");
  const data = [
    {
      url: "/engagement-collection",
      src: imageURL2.image_12,
      title: "Engagement Collection",
      buttonText: "Shop Now",
      alt: "Engagement Collection",
    },
    {
      url: "/wedding-collection",
      src: imageURL2.image_13,
      title: "Wedding Collection",
      buttonText: "Shop Now",
      alt: "Wedding Collection",
    },
    {
      url: "/jewelry-collection",
      src: imageURL2.image_14,
      title: "Jewelry Collection",
      buttonText: "Shop Now",
      alt: "Jewelry Collection",
    },
  ];

  return (
    <CustomContainer className="">
      <section
        className="collection-sec small-container product-container-small "
        id="topCollection"
      >
        <div className="col-xxl-9 col-xl-10 col-md-12 mx-auto">
          <GlobalTitle title={text?.text_10 ?? "SHOP BY CATEGORY"} />
          {isAboveDesktop ? (
            <Row className="mt-5  ">
              {data?.map((el, index) => {
                return (
                  <Col key={`cat-${el.index}`} md={4}>
                    <Link to={el?.url ?? "#"} className="coll-card">
                      <img src={el?.src} className="img-fluid" alt={el?.alt} />
                      <h5 className="text-center">{el?.title}</h5>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          ) : (
            // <CollectionSlider imageURL2={imageURL2} />
            <SliderComponent
              slidesToShow={5}
              responsive={responsive}
              data={
                data?.map((el) => {
                  return (
                    <div key={`category-${el?.id}`} className="mt-80">
                      <Link to={el?.url ?? "#"} className="coll-card">
                        <img
                          src={el?.src}
                          className="img-fluid mb-2"
                          alt={el?.alt}
                        />
                        <h5 className="text-center">{el?.title}</h5>
                        <Button className="btn btn-theme btn-outline text-secondary m-0 btn-hoverable">
                          {el?.buttonText}
                        </Button>
                      </Link>
                    </div>
                  );
                }) ?? []
              }
            />
          )}
        </div>
      </section>
    </CustomContainer>
  );
};

export default CollectionDontMiss;
