import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { CustomToggle } from "../pages/OurPolicy";

const GlobalFaq = ({ data }) => {
  const [isOpen, setIsOpen] = React.useState("0");
  return (
    <div className="">
      <Accordion defaultActiveKey="0">
        {data?.map((item, index) => {
          return (
            <Card className="border-0" key={index}>
              <Card.Header
                style={{
                  backgroundColor: "white",
                  borderBottom: "1px solid #ccc",
                }}
              >
                <CustomToggle
                  eventKey={index}
                  handleClick={() => setIsOpen(isOpen === index ? "" : index)}
                  open={isOpen === index}
                >
                  <h4 className="text-black">
                    {item?.title ?? item?.question ?? ""}
                  </h4>
                </CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey={index}>
                <Card.Body
                  style={{
                    color: "#333",
                  }}
                >
                  {item?.des && item?.des?.length > 0 ? (
                    item?.des?.map((desc, index) => {
                      return (
                        <div className="mb-2">
                          <p>{desc}</p>
                        </div>
                      );
                    })
                  ) : (
                    <p>{item?.answer || item?.desc || ""}</p>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        })}
      </Accordion>
    </div>
  );
};

export default GlobalFaq;
