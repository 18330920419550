import { Row, Col } from "react-bootstrap";
import { CustomContainer } from "./common/global-display";

const ComingSoon = () => {
  return (
    <>
      <CustomContainer className="py-5 text-center">
        <Row>
          <Col>
            <h1 className="display-4">Coming Soon</h1>
            <p className="lead">Our website is under construction.</p>
          </Col>
        </Row>
      </CustomContainer>
    </>
  );
};

export default ComingSoon;
