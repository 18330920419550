import React from "react";
import * as Yup from "yup";
import { CustomContainer } from "../common/global-display";
import GlobalForm from "../form/globalForm";
import "./contact.css";

const initialValues = {
  name: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  country: "",
  postal_code: "",
  email: "",
  phone: "",
  file: null,
};

const fields = [
  { type: "text", name: "name", label: "Name", size: 12, required: true },
  { type: "email", name: "email", label: "Email", size: 12, required: true },
  { type: "text", name: "phone", label: "Phone", size: 12, required: false },
  {
    type: "select",
    name: "topic",
    label: "Topic",
    size: 12,
    required: true,

    options: [
      {
        label: "Diamonds",
        value: "diamonds",
      },
      {
        label: "Engagement Rings",
        value: "engagement_Rings",
      },
      {
        label: "Jewelry",
        value: "jewelry",
      },
      {
        label: "Home Preview",
        value: "home_Preview",
      },
      {
        label: "policies",
        value: "Policies",
      },
    ],
  },

  {
    type: "textarea",
    name: "askaQuestion",
    label: "Ask a Question",
    size: 12,
    required: false,
  },

  // {
  //   type: "file",
  //   name: "file",
  //   label: "File Upload",
  //   size: 12,
  //   required: true,
  // },
];

const userAddressListSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  topic: Yup.string().required("Topic is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().matches(/^[0-9]{10}$/, "Invalid phone number"),
  // file: Yup.mixed().required("File is required"),
});

const ContactForm = ({
  state,
  handleChange,
  isError,
  errorMsg,
  handleContact,
}) => {
  const handleSubmit = (values) => {
    console.log("Form values:", values);
  };
  return (
    <CustomContainer className="mt-0 mb-0">
      <div className="contact-form-section pt-5 pb-5">
        <div className="contact-form-wrapper">
          <div className="mb-5">
            <h2 className="text-center mb-4">Send us a message</h2>
            <h6 className="text-center">
              Contact one of our expert gemologists and we'll get back to you as
              soon as possible. You'll be amazed at how helpful we can be.
            </h6>
          </div>
          <CustomContainer
            className="mb-0 contact-form position-relative"
            // style={{ marginTop: "80px" }}
          >
            <GlobalForm
              initialValues={initialValues}
              validationSchema={userAddressListSchema}
              onSubmit={handleSubmit}
              fields={fields}
              // isNew={true}
              // dispatch={() => {}}
              // addNewAddress={() => {}}
              // updateAddress={() => {}}
              // editId={null}
              // setState={() => {}}
              // initialValue={initialValues}
              // setIsEdit={() => {}}
            />
          </CustomContainer>
        </div>
      </div>
    </CustomContainer>
  );
};

export default ContactForm;
