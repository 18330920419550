import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const SignIn = (props) => {
  const [show, setShow] = useState(false);
  const handleSignUp = () => {
    props.onHide();
    setShow(true);
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        className="auth-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Sign In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-4">
            <label htmlFor="" className="form-label">
              Email
            </label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="" className="form-label">
              Password
            </label>
            <input type="password" className="form-control" />
            <a href="#" className="text-primary text-end d-block mt-2">
              Forgot password ?
            </a>
          </div>
          <button
            type="button"
            className="btn btn-secondary btn-theme d-block w-100 me-0"
          >
            Submit
          </button>
          <p className="text-center mt-5 mb-0">
            Don't have an Account?{" "}
            <Button
              onClick={handleSignUp}
              className="btn btn-link text-primary"
            >
              {" "}
              Create an Account
            </Button>
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SignIn;
