import React from "react";
import { Alert } from "react-bootstrap";
import Slider from "react-slick";
// import 'slick-carousel/slick/slick-theme.css';
// import 'slick-carousel/slick/slick.css';

const AnnouncementBar = ({ data }) => {
  if (!data || data?.length === 0) return null;

  const settings = {
    dots: false,
    infinite: true,
    navigation: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    adaptiveHeight: true,
  };

  return (
    <div className="announcement-bar">
      {data?.length > 1 ? (
        <Slider {...settings}>
          {data?.map((announcement, index) => (
            <Alert
              key={index}
              variant="info"
              className="announcement-alert m-0 py-2 px-0"
            >
              <div
                className="announcement-content"
                dangerouslySetInnerHTML={{ __html: announcement }}
              />
            </Alert>
          ))}
        </Slider>
      ) : (
        <Alert variant="info" className="announcement-alert m-0 py-2 px-0">
          <div
            className="announcement-content"
            dangerouslySetInnerHTML={{ __html: data[0] }}
          />
        </Alert>
      )}
    </div>
  );
};

export default AnnouncementBar;
