import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// import img2 from "../../assets/images/elegant/79832604-2100x1575-d2cbf2af-e7d2-4946-8921-387c9617b390_2_1512x.png";
// import img3 from "../../assets/images/elegant/build---your-engagement-ring_780x.png";
// import img1 from "../../assets/images/elegant/earrings.png";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import { imageURL2 } from "../../images";
import { CustomContainer } from "../common/global-display";
import GlobalTitle from "../common/global-title";
import SliderComponent from "../Slider/sliderComponent";
import "./Home.css"; // Import the CSS file

const responsive = [
  {
    breakpoint: 625,
    settings: {
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 425,
    settings: {
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 375,
    settings: {
      slidesToShow: 1,
      centerPadding: "25px",
    },
  },
  {
    breakpoint: 320,
    settings: {
      slidesToShow: 1,
      centerPadding: "10px",
    },
  },
];

const ElegantArea = () => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");

  const sliderData = [
    {
      url: "/engagement-collection",
      src: imageURL2?.image_168,
      title: "Earring Collection",
      alt: "Earring Collection",
    },
    {
      url: "/engagement-collection",
      src: imageURL2?.image_169,
      title: "Bracelet Collection",
      alt: "Bracelet Collection",
    },
    {
      url: "/engagement-collection",
      src: imageURL2?.image_170,
      title: "Jewelry Collection",
      alt: "Jewelry Collection",
    },
  ];
  return (
    <>
      <CustomContainer className="elegant-container ">
        <div className="small-container w-100 elegant-container">
          <GlobalTitle
            title="ELEGANT VERSATILITY"
            description="Discover our collection of timeless pieces with a modern twist, perfect for every occasion."
          />

          {isAboveDesktop ? (
            <Row className="">
              <Col xs={12} sm={12} md={12} lg={3}>
                <Row className="gy-2">
                  <Col xs={12} sm={6} md={6} lg={12} className="mb-md-2">
                    <img
                      src={imageURL2?.image_168}
                      className="elegant-img"
                      alt="Earring"
                    />
                    <p className="text-center fw-bold mt-2 mb-md-2 mb-lg-0">
                      Earring Collection
                    </p>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={12}>
                    <img
                      src={imageURL2?.image_169}
                      className="elegant-img"
                      alt="Bracelet"
                    />
                    <p className="text-center fw-bold mt-2 mb-0">
                      Bracelet Collection
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={12} lg={9}>
                <img
                  src={imageURL2?.image_170}
                  className="elegant-main-img"
                  alt="Jewelry"
                />
                <p className="text-center fw-bold mt-2 mb-0">
                  Jewelry Collection
                </p>
              </Col>
            </Row>
          ) : (
            <div className="">
              {/* <ElegantAreaSlider /> */}
              <SliderComponent
                slidesToShow={3}
                responsive={responsive}
                buttonPosY={"40%"}
                data={
                  sliderData?.map((singleProduct) => {
                    return (
                      <Link
                        key={`elegant-${singleProduct?.id}`}
                        to={singleProduct?.url ?? "#"}
                        className="coll-card  px-2"
                      >
                        <img
                          src={singleProduct?.src}
                          style={{
                            objectFit: "cover",
                            aspectRatio: "1/1",
                          }}
                          className="img-fluid mb-2"
                          alt={singleProduct?.alt}
                        />
                        <h5 className="text-center">{singleProduct?.title}</h5>
                      </Link>
                    );
                  }) ?? []
                }
              />
            </div>
          )}
        </div>
      </CustomContainer>
    </>
  );
};

export default ElegantArea;
