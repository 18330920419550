import axios from "axios";

export const monthDateList = [
  { month: "January", date: Array.from({ length: 31 }, (_, i) => i + 1) },
  { month: "February", date: Array.from({ length: 29 }, (_, i) => i + 1) }, // for a non-leap year
  { month: "March", date: Array.from({ length: 31 }, (_, i) => i + 1) },
  { month: "April", date: Array.from({ length: 30 }, (_, i) => i + 1) },
  { month: "May", date: Array.from({ length: 31 }, (_, i) => i + 1) },
  { month: "June", date: Array.from({ length: 30 }, (_, i) => i + 1) },
  { month: "July", date: Array.from({ length: 31 }, (_, i) => i + 1) },
  { month: "August", date: Array.from({ length: 31 }, (_, i) => i + 1) },
  { month: "September", date: Array.from({ length: 30 }, (_, i) => i + 1) },
  { month: "October", date: Array.from({ length: 31 }, (_, i) => i + 1) },
  { month: "November", date: Array.from({ length: 30 }, (_, i) => i + 1) },
  { month: "December", date: Array.from({ length: 31 }, (_, i) => i + 1) },
];

export const convertToTitleCase = (str) => {
  // Split the string into an array of words
  const words = str.split("-");

  // Capitalize the first letter of each word and join them back with spaces
  const titleCased = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return titleCased;
};
export const validCurrency = {
  INR: true,
  USD: true,
  GBP: true,
  CAD: true,
};
export const getCountryPrice = async () => {
  try {
    // const response = await axios.get('https://ipapi.co/json/');
    // return response.data.currency;
    let currency = localStorage.getItem("currency");
    if (
      !!!currency ||
      currency === null ||
      currency === "null" ||
      !!!validCurrency[currency]
    ) {
      const response = await axios.get("https://ipapi.co/json/");
      if (validCurrency[response.data.currency]) {
        currency = response.data.currency;
        localStorage.setItem("currency", currency);
        window.location.reload(); 
      } else {
        currency = "USD";
      }
    }

    return currency;
    // return "GBP"
  } catch (error) {
    console.error("Error fetching location data:", error);
  }
};
