/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import HeartFill from "../../assets/svgs/heartFill";
import { currencyIcon } from "../../constants/function";
import { colorsVariant } from "../../constants/utils";
import { addToCart, getCartDetails } from "../../features/cart/cartSlice";
import formatCurrency from "../../helper/currencyFormater";
import "../Home/Home.css";

const ProductCard = ({
  produtInfo,
  onAddToFavourite,
  handleRemove,
  defaultSelcted,
  className = "col-lg-3 col-md-4 col-sm-6 col-6",
}) => {
  const formatedPrice = formatCurrency(produtInfo?.white?.price);
  const [selectedColor, setSelectedColor] = useState(
    defaultSelcted ?? {
      color: colorsVariant?.[produtInfo?.silver ? "silver" : "white"],
      key: produtInfo?.silver ? "silver" : "white",
    },
  );
  const availableColors = Object?.keys(produtInfo)?.filter(
    (key) => produtInfo?.[key],
  );
  const handleSelect = (color, key) => {
    setSelectedColor({ color, key });
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // const { category } = useParams();

  const product = {
    product_id: produtInfo?.[selectedColor?.key]?.id,
    qty: 1,
  };

  const onAddToCartHandelar = async () => {
    await dispatch(addToCart(product));
    await dispatch(getCartDetails());
  };
  const handleClick = () => {
    let formattedName = produtInfo?.[selectedColor?.key]?.name.toLowerCase();
    // Replace all white spaces with hyphens
    formattedName = formattedName.replace(/\s+/g, "-");
    navigate(`/${formattedName}/${produtInfo?.[selectedColor?.key]?.sku}`, {
      state: {
        slug: produtInfo?.[selectedColor?.key]?.category
          ? produtInfo?.[selectedColor?.key]?.category
          : "",
      },
    });
  };

  return (
    <div className={className}>
      <div className="card product-card" data-aos="fade-up">
        <div
          className="product-display"
          style={{
            aspectRatio: "1/1",
          }}
        >
          <div
            onClick={handleClick}
            style={{
              position: "relative",
            }}
          >
            <img
              src={produtInfo?.[selectedColor?.key]?.imgUrl}
              className="img-fluid"
              alt="product"
            />
            <img
              src={produtInfo?.[selectedColor?.key]?.hoverImageUrl}
              className="img-fluid hover"
              alt="product"
            />
            {!!produtInfo?.[selectedColor?.key]?.tag && (
              <div
                style={{
                  position: "absolute",
                  top: "7px",
                  left: "8px",
                  fontSize: "14px",
                  color: "#ffffff",
                  padding: "3px 10px",
                  background: "#e77e50",
                  borderRadius: "5px",
                }}
              >
                {produtInfo?.[selectedColor?.key]?.tag}
              </div>
            )}
          </div>
          <div
            style={{
              position: "absolute",
              top: "5px",
              right: "0px",
              fontSize: "14px",
              color: "#e77e50",
              padding: "3px 10px",
              borderRadius: "5px",
            }}
          >
            {produtInfo?.[selectedColor?.key]?.favourite ? (
              <div
                className="btn-heart cursor-pointer"
                onClick={() =>
                  handleRemove(produtInfo?.[selectedColor?.key]?.id)
                }
              >
                <HeartFill
                  className="wishlist-heart"
                  fillColor={"#e77e50"}
                  strokeColor={"#e77e50"}
                />
              </div>
            ) : (
              <div
                className="btn-heart fill-border cursor-pointer "
                onClick={() => {
                  onAddToFavourite(produtInfo?.[selectedColor?.key]);
                }}
              >
                <HeartFill
                  className="wishlist-heart"
                  fillColor={"none"}
                  strokeColor={"#e77e50"}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className="card-body"
          style={{
            textAlign: "center",
          }}
        >
          {/* <h5 className="text-primary">{type}</h5> */}
          <h6>
            <Link
              to={`/${produtInfo?.[selectedColor?.key]?.name
                ?.toLowerCase()
                ?.replace(/\s+/g, "-")}/${
                produtInfo?.[selectedColor?.key]?.sku
              }`}
              state={{
                slug: produtInfo?.[selectedColor?.key]?.category
                  ? produtInfo?.[selectedColor?.key]?.category
                  : "",
              }}
              className="text-black"
            >
              {produtInfo?.[selectedColor?.key]?.name}
            </Link>
          </h6>
          <div className="color-select">
            {Object.keys(produtInfo)?.map((key, index) => (
              <div
                key={index}
                className={`main-color ${
                  selectedColor?.color === colorsVariant?.[key]
                    ? "selected"
                    : ""
                }`}
              >
                <div
                  key={index}
                  className={`color-circle ${
                    selectedColor?.color === colorsVariant?.[key]
                      ? "selected"
                      : ""
                  } ${produtInfo?.[key] ? "cursor-pointer" : "not-allowed"}`}
                  style={{ background: colorsVariant?.[key] }}
                  onClick={() =>
                    produtInfo?.[key] && handleSelect(colorsVariant?.[key], key)
                  }
                ></div>
              </div>
            ))}
          </div>
          {!!formatedPrice && (
            <div
              className="product-text-center"
              style={{
                fontSize: "20px",
                justifyContent: "center",
              }}
            >
              {currencyIcon(produtInfo?.[selectedColor?.key]?.currency)}{" "}
              {formatedPrice}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
