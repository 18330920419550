import React from "react";
import * as Yup from "yup";
import GlobalForm from "../form/globalForm";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { generateYupSchema } from "../form/generate-yup-schema";
import { requestFormFields } from "../../constants/utils";
import { CustomContainer } from "../common/global-display";
import GlobalTitle from "../common/global-title";

const initialValues = {
  name: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  country: "",
  postal_code: "",
  email: "",
  phone: "",
  file: null,
};

const userAddressListSchema = {
  "general-help": Yup.object().shape({
    name: Yup.string().required("Name is required"),
    topic: Yup.string().required("Topic is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().matches(/^[0-9]{10}$/, "Invalid phone number"),
    // file: Yup.mixed().required("File is required"),
  }),
};

const RequestForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const formType = location?.pathname?.split("/contact/")?.[1];

  const handleSubmit = (values) => {
    console.log("Form values:", values);
  };
  return (
    <>
      <CustomContainer className="">
        <div className="small-container">
          {/* <h1 className="captilize-text text-center">Submit a request</h1> */}
          <GlobalTitle title={"Submit a request"} />
          <div className="contact-form mt-4 pb-5">
            <select
              name={"type"}
              defaultValue={formType ? formType : null}
              className={classNames("form-control form-select")}
              onChange={(e) => {
                navigate(`/contact/${e.target.value}`);
              }}
              // handleChange={(e) => {
              //   console.log(e);
              //   navigate(`/contact/${formType}`);
              // }}
            >
              <option value="" label="Select option" />
              <option key={"general-help"} value={"general-help"}>
                General Help
              </option>
              <option key={"retrun-my-item"} value={"retrun-my-item"}>
                Return My Item(s)
              </option>
              <option
                key={"i-need-a-different-size"}
                value={"i-need-a-different-size"}
              >
                I Need a Different Size
              </option>
            </select>
          </div>
          <div className="pb-5 pt-3 contact-form position-relative m-0">
            <GlobalForm
              initialValues={initialValues}
              validationSchema={generateYupSchema(
                requestFormFields?.[formType]
              )}
              onSubmit={handleSubmit}
              fields={requestFormFields?.[formType]}
            />
          </div>
        </div>
      </CustomContainer>
    </>
  );
};

export default RequestForm;
