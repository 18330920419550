import React, { useState } from "react";
import { IoStarSharp } from "react-icons/io5";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import EllipsisText from "../Home/EllipseText";
import SliderComponent from "../Slider/sliderComponent";
import { CustomContainer } from "./global-display";

const response = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: true,
      dots: false,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      // initialSlide: 3,
      infinite: true,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
];

const GlobalReview = ({ Reviews, title }) => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");
  const [length, setLength] = useState(3);
  const ReviewsSlice = Reviews?.slice(0, length);
  return (
    <CustomContainer className="review-card">
      {isAboveDesktop ? (
        <SliderComponent
          title={title ?? "Hear From Our Happy Customers"}
          infinite={true}
          // responsive={responsive}
          responsive={response}
          slidesToShow={3}
          slidesToScroll={3}
          data={Reviews?.map((singleTestimonial, index) => {
            const positionInGroup = (index + 1) % 3;
            let quotePosition = null;
            if (positionInGroup === 1) {
              quotePosition = "top";
            } else if (positionInGroup === 0) {
              quotePosition = "bottom";
            }
            return (
              <>
                <div
                  className={`py-5 px-4 my-3 h-100 position-relative ${
                    quotePosition === "top"
                      ? "card-review-top"
                      : quotePosition === "bottom" && "card-review-bottom"
                  } `}
                  style={{ background: "#efefef",minHeight:"300px" }}
                  key={singleTestimonial?.id}
                >
                  <div className="text-start">
                    <p>
                      {Array(singleTestimonial?.star ?? 1)
                        ?.fill(1)
                        ?.map((el, i) => {
                          return (
                            <IoStarSharp
                              key={`${singleTestimonial?.id}_${i}`}
                              className=" text-primary "
                            />
                          );
                        })}
                    </p>
                    {/* {isAboveDesktop ? (
                      <p className="">{singleTestimonial?.review}</p>
                    ) : (
                      <EllipsisText
                        maxLines={3}
                        text={singleTestimonial?.review}
                      />
                    )} */}
                     <EllipsisText
                        maxLines={3}
                        text={singleTestimonial?.review}
                      />
                    <span>{`${singleTestimonial?.first_name} ${singleTestimonial?.last_name}`}</span>
                  </div>
                </div>
              </>
            );
          })}
        />
      ) : (
        <>
          <div className="">
            <p className="d-flex align-items-center justify-content-between m-0 p-0">
              <h2 className="captilize-text text-center my-4">
                Hear From Our Happy Customers
              </h2>
            </p>

            {ReviewsSlice?.map((singleTestimonial, index) => {
              const isSvgVisible = index % ReviewsSlice?.length === 0;
              return (
                <div
                  className={`p-1 position-relative ${
                    isSvgVisible ? "card-review-top" : "card-review-bottom"
                  }`}
                  key={singleTestimonial?.id}
                >
                  <div
                    className="py-5 px-4 text-start"
                    style={{
                      background: "#f2f2f2",
                      borderRadius: "5pt",
                      // minHeight: "25px",
                    }}
                    key={singleTestimonial?.id}
                  >
                    <p>
                      {Array(singleTestimonial?.star ?? 1)
                        ?.fill(1)
                        ?.map((el, i) => {
                          return (
                            <IoStarSharp
                              key={`${singleTestimonial?.id}_${i}`}
                              className=" text-primary "
                            />
                          );
                        })}
                    </p>

                    {/* {isAboveDesktop ? (
                      <p className="">{singleTestimonial?.review}</p>
                    ) : (
                      <EllipsisText
                        maxLines={3}
                        text={singleTestimonial?.review}
                      />
                    )} */}
                     <EllipsisText
                        maxLines={3}
                        text={singleTestimonial?.review}
                      />
                    <h6>{`${singleTestimonial?.first_name} ${singleTestimonial?.last_name}`}</h6>
                  </div>
                </div>
              );
            })}
            <section className="d-flex align-items-center  justify-content-center">
              {length < Reviews?.length && (
                <button
                  className="btn btn-theme btn-outline text-dark1 border-dark1 mt-4"
                  onClick={() => {
                    setLength(Reviews?.length);
                  }}
                >
                  See More
                </button>
              )}
              {length > 3 && (
                <button
                  className="btn btn-theme btn-outline text-dark1 border-dark1  mt-4"
                  onClick={() => {
                    setLength(3);
                  }}
                >
                  See Less
                </button>
              )}
            </section>
          </div>
        </>
      )}
    </CustomContainer>
  );
};

export default GlobalReview;
