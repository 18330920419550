import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { inspiredDummyData } from "../../constants/utils";
import { getCartDetails } from "../../features/cart/cartSlice";
import { getPageReview } from "../../features/review/ReviewSlice";
import { useDynamicText } from "../../Hooks/useDynamicText";
import { imageURL2 } from "../../images";
import GlobalReview from "../common/global-review";
import CollectionDontMiss from "./CollectionDontMiss";
import ContainerGridHome from "./ContainerGridHome";
import ElegantArea from "./ElegantArea";
import GetInspired from "./getInspired";
import HomeHeroSection from "./HomeHeroSection";
import ImageWithLinkHome from "./ImageWithLinkHome";
import NoDealBreaker from "./NoDealBreaker";
import Ourlook from "./Ourlook";
import ShopByCategory from "./shopByCategory";
import TabWithProductHome from "./TabWithProductHome";

const Home = () => {
  const dispatch = useDispatch();
  const Reviews = useSelector((state) => state?.reviews?.data);

  const text = useDynamicText();
  useEffect(() => {
    dispatch(getPageReview());
  }, []);
  // console.log("text", text);
  useEffect(function getInitialCartDetails() {
    dispatch(getCartDetails());
  }, []);

  return (
    <>
      <HomeHeroSection text={text} image={imageURL2} />
      <ShopByCategory />
      <NoDealBreaker />
      <ImageWithLinkHome
        link={"/eternity-bands"}
        imagelink={imageURL2.image_140}
        imageMobilelink={imageURL2.image_161}
      />
      <CollectionDontMiss text={text} imageURL2={imageURL2} />
      <ElegantArea />
      {/* <CustomerReviewSection Reviews={Reviews} /> */}
      <ImageWithLinkHome
        link={"/three-stone-engagement-rings"}
        imagelink={imageURL2.image_141}
        imageMobilelink={imageURL2.image_162}
      />
      <ContainerGridHome text={text} imageURL2={imageURL2} />
      <Ourlook imageURL2={imageURL2} />
      <ImageWithLinkHome
        link={"/tennis-bracelets"}
        imagelink={imageURL2.image_115}
        imageMobilelink={imageURL2.image_163}
      />
      {/* OUR LOOK */}
      {/* <BestNBeloved text={text} imageURL2={imageURL2} /> */}
      <TabWithProductHome text={text} imageURL2={imageURL2} />
      <ImageWithLinkHome
        link={"/jewelry-collection"}
        imagelink={imageURL2.image_142}
        imageMobilelink={imageURL2.image_164}
      />
      {/* <CustomerReviewSection Reviews={Reviews} /> */}
      <GlobalReview Reviews={Reviews} title={"Hear From Our Happy Customers"} />
      <GetInspired inspiredDummyData={inspiredDummyData} />
      {/* <HomeReviewSection Reviews={Reviews} /> */}
    </>
  );
};

export default Home;
