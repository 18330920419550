import React from "react";
import { IoMdArrowBack } from "react-icons/io";
import GlobalForm from "../form/globalForm";
import {
  addNewAddress,
  getUserAddresses,
  resetUpdateAddress,
} from "../../features/address/addressSlice";
import { useDispatch } from "react-redux";
import { CustomContainer } from "../common/global-display";

const NewShippingForm = ({
  setIsNewShipping,
  setIsNewShippingForm,
  newShippingAddress,
  userAddressListSchema,
  stateList,
  countryList,
  setNewShippingAddress,
  setSelectedShippingAddressId,
  setSaveShippingAddress,
  setIsEditShipping,
  cartShippingFields,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <button
        className="btn btn-link fw-bold text-primary d-flex align-items-center gap-2"
        onClick={() => {
          // setSaveShippingAddress(false);
          // setSelectedShippingAddressId(false);
          // setSelectedShippingAddress(null);
          // setIsSameShipping(false);
          // setSaveBillingAddress(false);
          // setSelectedBillingAddress(false);
          setIsNewShipping(false);
          setIsNewShippingForm(false);
        }}
      >
        <IoMdArrowBack size={20} /> Existing Shipping Address
      </button>
      <CustomContainer
        className="contact-form position-relative mb-0   pb-4"
        style={{ marginTop: "80px" }}
      >
        <GlobalForm
          initialValues={newShippingAddress}
          validationSchema={userAddressListSchema}
          dynamicOption={{
            state_id: stateList?.map((stat) => ({
              ...stat,
              label: stat.name,
              value: stat.id,
            })),
            country_id: countryList?.map((stat) => ({
              ...stat,
              label: stat.name,
              value: stat.id,
            })),
          }}
          onSubmit={async (values) => {
            // setState(values);
            // console.log("on data submited", values);

            const details = {
              firstname: values?.firstname,
              lastname: values?.lastname,
              name: `${values?.firstname} ${values?.lastname}`,
              address1: values?.address1,
              address2: values?.address2,
              city: values?.city,
              state_id: values?.state_id,
              country_id: values?.country_id,
              postal_code: values?.postal_code,
              address_type: 0, // keep static 0
            };
            const newAddress = await dispatch(addNewAddress(details));
            await dispatch(getUserAddresses());
            await dispatch(resetUpdateAddress());
            await setNewShippingAddress({ ...values, ...details });
            await setSelectedShippingAddressId(newAddress?.payload?.id);
            setSaveShippingAddress(true);
            setIsNewShippingForm(false);
            setIsEditShipping(false);
          }}
          fields={cartShippingFields}
          btnText="Save & Continue"
          btnSet="start"
        />
      </CustomContainer>
      {/* <DetailsForm
                        state={newShippingAddress}
                        stateList={stateList}
                        countryList={countryList}
                        initialValue={initialValue}
                        setState={async (values) => {
                          const details = {
                            name: values?.name,
                            address1: values?.address1,
                            address2: values?.address2,
                            city: values?.city,
                            state_id: values?.state,
                            country_id: values?.country,
                            postal_code: values?.postal_code,
                            address_type: 0, // keep static 0
                          };
                          const newAddress = await dispatch(
                            addNewAddress(details)
                          );
                          await dispatch(getUserAddresses());
                          await dispatch(resetUpdateAddress());
                          await setNewShippingAddress(values);
                          await setSelectedShippingAddressId(
                            newAddress?.payload?.id
                          );
                        }}
                        setIsNewForm={setIsNewShippingForm}
                        setSaveAddress={setSaveShippingAddress}
                      /> */}
    </>
  );
};

export default NewShippingForm;
