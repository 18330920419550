import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getEarringsJewelry } from "../../constants/urls";
import { isLogin } from "../../constants/function";

const initialState = {
  earrings: {
    loading: false,
    success: false,
    data: null,
    error: false,
  },
  bracelet: {
    loading: false,
    success: false,
    data: null,
    error: false,
  },
  rings: {
    loading: false,
    success: false,
    data: null,
    error: false,
  },
  neckless: {
    loading: false,
    success: false,
    data: null,
    error: false,
  },
};

// neckles : 68
// earring : 67
// rings : 66
// bracelet : 69
export const getEarringJewelry = createAsyncThunk(
  "jewelry/earring",
  async (userId) => {
    const res = await axios.get(`${getEarringsJewelry}/67?user_id=${userId}`);
    // const data = await res.data;
    // return data;
    if (isLogin()) {
      const data = await res.data;
      return data;
    } else {
      const data = await res.data;

      const localData = JSON.parse(localStorage.getItem("wishlist")) || [];
      data.data = data.data.map((item) => {
        // Iterate over the keys (e.g., "white", "yellow", "rose")
        const updatedItem = {};
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            const localItem = localData.find((i) => i.id === item[key].id);

            const isFavourite = localItem
              ? localItem.product?.favourite
              : item[key].favourite;

            updatedItem[key] = {
              ...item[key],
              favourite: isFavourite,
            };
          }
        }
        return updatedItem;
      });
      return data;
    }
  },
);

export const getBraceletJewelry = createAsyncThunk(
  "jewelry/bracelet",
  async (userId) => {
    const res = await axios.get(`${getEarringsJewelry}/69?user_id=${userId}`);
    // const data = await res.data;
    // return data;
    if (isLogin()) {
      const data = await res.data;
      return data;
    } else {
      const data = await res.data;

      const localData = JSON.parse(localStorage.getItem("wishlist")) || [];
      data.data = data.data.map((item) => {
        // Iterate over the keys (e.g., "white", "yellow", "rose")
        const updatedItem = {};
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            const localItem = localData.find((i) => i.id === item[key].id);

            const isFavourite = localItem
              ? localItem.product?.favourite
              : item[key].favourite;

            updatedItem[key] = {
              ...item[key],
              favourite: isFavourite,
            };
          }
        }
        return updatedItem;
      });
      return data;
    }
  },
);

export const getRingsJewelry = createAsyncThunk(
  "jewelry/rings",
  async (userId) => {
    const res = await axios.get(`${getEarringsJewelry}/66?user_id=${userId}`);
    // const data = await res.data;
    // return data;
    if (isLogin()) {
      const data = await res.data;
      return data;
    } else {
      const data = await res.data;

      const localData = JSON.parse(localStorage.getItem("wishlist")) || [];
      data.data = data.data.map((item) => {
        // Iterate over the keys (e.g., "white", "yellow", "rose")
        const updatedItem = {};
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            const localItem = localData.find((i) => i.id === item[key].id);

            const isFavourite = localItem
              ? localItem.product?.favourite
              : item[key].favourite;

            updatedItem[key] = {
              ...item[key],
              favourite: isFavourite,
            };
          }
        }
        return updatedItem;
      });
      return data;
    }
  },
);

export const getNecklessJewelry = createAsyncThunk(
  "jewelry/neckless",
  async (userId) => {
    const res = await axios.get(`${getEarringsJewelry}/68?user_id=${userId}`);
    // const data = await res.data;
    // return data;
    if (isLogin()) {
      const data = await res.data;
      return data;
    } else {
      const data = await res.data;

      const localData = JSON.parse(localStorage.getItem("wishlist")) || [];
      data.data = data.data.map((item) => {
        // Iterate over the keys (e.g., "white", "yellow", "rose")
        const updatedItem = {};
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            const localItem = localData.find((i) => i.id === item[key].id);

            const isFavourite = localItem
              ? localItem.product?.favourite
              : item[key].favourite;

            updatedItem[key] = {
              ...item[key],
              favourite: isFavourite,
            };
          }
        }
        return updatedItem;
      });
      return data;
    }
  },
);

export const jewelrySlicer = createSlice({
  name: "jewelry",
  initialState,
  reducers: {
    // setEngagementData: (state, action) => {
    //   state.navbarData.engagement = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getEarringJewelry.pending, (state) => {
      state.earrings.loading = true;
    });
    builder.addCase(getEarringJewelry.fulfilled, (state, action) => {
      state.earrings.loading = false;
      state.earrings.data = action.payload;
      state.earrings.success = true;
    });
    builder.addCase(getEarringJewelry.rejected, (state, action) => {
      state.earrings.loading = false;
      state.earrings.error = action.error.message;
    });

    //    bracelet
    builder.addCase(getBraceletJewelry.pending, (state) => {
      state.bracelet.loading = true;
    });
    builder.addCase(getBraceletJewelry.fulfilled, (state, action) => {
      state.bracelet.loading = false;
      state.bracelet.success = true;
      state.bracelet.data = action.payload;
    });
    builder.addCase(getBraceletJewelry.rejected, (state, action) => {
      state.bracelet.loading = false;
      state.bracelet.error = action.error.message;
    });

    //   rings
    builder.addCase(getRingsJewelry.pending, (state) => {
      state.rings.loading = true;
    });
    builder.addCase(getRingsJewelry.fulfilled, (state, action) => {
      state.rings.loading = false;
      state.rings.success = true;
      state.rings.data = action.payload;
    });
    builder.addCase(getRingsJewelry.rejected, (state, action) => {
      state.rings.loading = false;
      state.rings.error = action.error.message;
    });

    //  getNecklessJewelry
    builder.addCase(getNecklessJewelry.pending, (state) => {
      state.neckless.loading = true;
    });
    builder.addCase(getNecklessJewelry.fulfilled, (state, action) => {
      state.neckless.loading = false;
      state.neckless.success = true;
      state.neckless.data = action.payload;
    });
    builder.addCase(getNecklessJewelry.rejected, (state, action) => {
      state.neckless.loading = false;
      state.neckless.error = action.error.message;
    });
  },
});

export const {} = jewelrySlicer.actions;
export default jewelrySlicer.reducer;
