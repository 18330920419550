import { Row, Col, Image } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import GlobalTitle from "../common/global-title";
import SliderComponent from "../Slider/sliderComponent";
import { CustomContainer } from "../common/global-display";

const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 769,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];
const EngagementGridHomeRong = ({ Products, title = "", lg = 3 }) => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");

  return Products?.length ? (
    <CustomContainer>
      {!!title && <GlobalTitle title={title} />}
      <div className="mb-4">
        <CustomContainer className="mt-0 mb-0">
          {isAboveDesktop ? (
            <CustomContainer className="mt-0 pt-2 pb-4 small-container product-container-small ">
              <Row>
                {Products?.map((singleProduct) => {
                  return (
                    <Col
                      key={singleProduct?.id}
                      className="text-center"
                      xs={12}
                      sm={4}
                      // md={6}
                      lg={4}
                    >
                      <div className="Rings_hover  mb-2">
                        <Link to={singleProduct?.link}>
                          <Image
                            src={singleProduct?.image}
                            alt={singleProduct?.name}
                            fluid
                            style={{
                              maxHeight: isAboveDesktop ? "100%" : "100px",
                            }}
                            className="Rings_img_hover"
                          />
                        </Link>
                      </div>
                      <h4 className="mt-0 mb-2">{singleProduct?.name}</h4>
                    </Col>
                  );
                })}
              </Row>
            </CustomContainer>
          ) : (
            // <ProductSlider Products={Products} />
            <SliderComponent
              slidesToShow={5}
              responsive={responsive}
              data={
                Products?.map((singleProduct) => {
                  return (
                    <div key={singleProduct?.id} className="text-center">
                      <div className="Rings_hover  mb-2">
                        <Link to={singleProduct?.link}>
                          <Image
                            src={singleProduct?.image}
                            alt={singleProduct?.name}
                            fluid
                            className="Rings_img_hover"
                          />
                        </Link>
                      </div>
                      <h4 className="mt-0 mb-2">{singleProduct?.name}</h4>
                    </div>
                  );
                }) ?? []
              }
            />
          )}
        </CustomContainer>
      </div>
    </CustomContainer>
  ) : (
    ""
  );
};

export default EngagementGridHomeRong;
