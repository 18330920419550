import React from "react";
import { Col, Row } from "react-bootstrap";
import { imageURL, imageURL2 } from "../../images";
import { Link } from "react-router-dom";
import GlobalBreadcrumb from "../common/global-breadcrumb";
import { CustomContainer } from "../common/global-display";
import GlobalTitle from "../common/global-title";

const RingSize = () => {
  return (
    <>
      {/* <Container className="page-title text-start">
        <h5>Home - Engagement Ring Style</h5>
      </Container> */}
      <GlobalBreadcrumb subTitle=" Engagement Ring Style" />

      {/* Begin:: Hero section */}
      <CustomContainer className="mt-0 mb-0 ringStyle-hero">
        <Row className="justify-content-between align-items-center">
          <Col lg="5" md="6">
            <h1>
              Engagement <br />
              Ring Styles
            </h1>
            <h4 className="mt-4 mb-lg-5 mb-4">
              A Complete Guide to Engagement Rings with Lab Grown Diamonds
            </h4>
            <h3 className="fst-italic">
              “The engagement ring plays a crucial part in one of the most
              significant events in one’s life, a marriage proposal. This
              complete guide to selecting an engagement ring will help you
              understand the many types, shapes, and styles to make the best
              choice.”
            </h3>
          </Col>
          <Col lg="6" md="6" className="text-center d-none d-md-block">
            <img src={imageURL2.image_108} className="img-fluid" alt="Images" />
          </Col>
        </Row>
      </CustomContainer>
      {/* Begin:: Hero section */}

      {/* Begin:: History of engagement ring */}
      <CustomContainer className=" history-box">
        <Row className="gx-lg-5 align-items-center">
          <Col lg="4" className="d-none d-lg-block">
            <img
              src={imageURL2.image_107}
              className="img-fluid rounded-2"
              alt="Images"
            />
          </Col>
          <Col lg="8">
            <h2 className="heading-55 mb-lg-5 mb-4 fst-italic">
              History of Engagement Rings
            </h2>
            <p className="h5">
              This long history began in 1477 when Archduke Maximilian of
              Austria married Mary of Burgundy in Vienna's Imperial courts; he
              created the first well-documented diamond ring to signify his
              engagement to her. <br /> <br />
              This occasion made giving a diamond ring as an engagement gift
              more common and kicked off the development of what would come to
              be known as a new tradition. Diamond rings were increasingly
              sought after by European royalty and aristocracy, who made them a
              popular choice.
              <br /> <br />
              The tradition is still practiced today and not just in Europe, but
              also in other parts of the world, especially in North America
              where people adore this style of ring and its heartfelt
              connotations. Diamonds are still used to celebrate unions and
              treasured memories, making them the ideal choice for a proposal.
            </p>
          </Col>
        </Row>
      </CustomContainer>
      {/* End:: History of engagement ring */}

      {/* Begin:: Discover Collection */}
      <CustomContainer>
        <h2 className="heading-55 mb-4 text-center fst-italic">
          Discover Collection
        </h2>
        <h5 className="text-center col-10 mx-auto">
          The style you choose for your ring is a personal choice that
          represents what your relationship means to you. Here at Grown
          Brilliance, you can find a variety of designs, from a classic diamond
          to a more modern, minimalistic look, from traditional to more
          contemporary engagement rings, to match the personal style choice that
          suits you. We want to make sure that your moment is not only special,
          but also brilliant.
        </h5>

        <Row className="mt-100">
          <Col lg={3}>
            <h2 className="d-lg-none">Solitaire</h2>
            <img
              src={imageURL2.image_110}
              className="img-fluid mb-3 mb-lg-0"
              alt="Images"
            />
          </Col>
          <Col lg={9}>
            <h2 className="d-none d-lg-block">Solitaire</h2>
            <p>
              A solitaire engagement ring is an excellent choice for the
              traditional bride-to-be. Solitaire rings are easily one of the
              most popular settings available, with a traditionally appealing
              style that places emphasis on the center stone. <br />
              <br />
              There are a variety of settings available to personalize the
              solitaire style. For example the prong setting holds the diamond
              in place by small “claws,” and is most popular when stylized with
              either 4 or 6 prongs. The 6 prong setting, also known as the
              Tiffany Setting, has 6 minimalistic “claws” that hold the diamond
              above the band, whereas the 4 prong setting, also known as the
              Classic Setting, offers a more brilliant display of the center
              diamond by showing it off with only 4 prongs to hold it in place.
              The double prong features a set of prongs where the claws wrap
              around offering the illusion that the claws holding the diamond
              are slimmer. Another type of setting is the bezel setting which
              displays the center diamond with a single ring of metal around its
              girdle. Whatever setting you choose should be a reflection of your
              own personal choice and style that represents both you and your
              love.
            </p>
            <button className="btn btn-theme btn-secondary mt-2">Browse</button>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg={9} className="order-1 order-lg-0">
            <h2 className="d-none d-lg-block">Halo</h2>
            <p>
              The most classic of halo settings features a center stone
              surrounded by a narrow clean band of diamonds, which both add
              additional sparkle on their own merit and contribute to the added
              shining brilliance of the middle stone. You can personalize this
              setting by adding to the number of rings surrounding the middle
              diamond, the color of the pave stones that make up the diamond
              halo, and whether or not to add pave diamonds around the shank of
              the ring. In addition to giving the illusion of a bigger diamond,
              these pave stones that encircle the diamond flash with light and
              focus the attention back on the center stone.
              <br />
              <br />
              With a more 1920s, art deco style, the halo engagement ring offers
              a classic setting with a stylish twist for the brides-to-be who
              want to show off a little.
            </p>
            <button className="btn btn-theme btn-secondary mt-2">Browse</button>
          </Col>
          <Col lg={3} className="order-0 order-lg-1">
            <h2 className="d-lg-none">Halo</h2>
            <img
              src={imageURL2.image_112}
              className="img-fluid mb-3 mb-lg-0"
              alt="Images"
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg={3}>
            <h2 className="d-lg-none">Hidden Halo</h2>
            <img
              src={imageURL2.image_88}
              className="img-fluid mb-3 mb-lg-0"
              alt="Images"
            />
          </Col>
          <Col lg={9}>
            <h2 className="d-none d-lg-block">Hidden Halo</h2>
            <p>
              Instead of framing the center stone, the hidden halo setting sits
              below it and with the function of being the diamond’s base in a
              “hidden” way. The center stone is brighter from above than from
              any other angle because of the small stones visible. Hidden halo
              can be done in different shapes and styles,for example a round
              hidden halo, a drape hidden halo, which is a more ornate, soft and
              flowy design, a double hidden halo, or, one of the most
              traditional hidden halo rings, a halo on the basket, which is the
              part of the ring that holds the center stone.
              <br />
              <br />
              Another type of hidden halo is the shadow halo. The shadow halo is
              hidden 80% of the way but it still sits under the center stone,
              more specifically, most of the halo is under the table of the
              center stone.
            </p>
            <button className="btn btn-theme btn-secondary mt-2">Browse</button>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg={9} className="order-1 order-lg-0">
            <h2 className="d-none d-lg-block">Three Stone</h2>
            <p>
              A three stone ring setting features a dazzling center stone
              surrounded by two smaller side stones. The two side stones flank
              the center stone highlighting its beauty and giving the illusion
              of a larger stone. A three stone ring is a great choice for those
              who treasure timeless style and optimum beauty. Whoever said three
              stones are better than one knew the value of this stylish setting.
            </p>
            <button className="btn btn-theme btn-secondary mt-2">Browse</button>
          </Col>
          <Col lg={3} className="order-0 order-lg-1">
            <h2 className="d-lg-none">Three Stone</h2>
            <img
              src={imageURL2.image_89}
              className="img-fluid mb-3 mb-lg-0"
              alt="Images"
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg={3}>
            <h2 className="d-lg-none">Custom Designs</h2>
            <img
              src={imageURL2.image_110}
              className="img-fluid mb-3 mb-lg-0"
              alt="Images"
            />
          </Col>
          <Col lg={9}>
            <h2 className="d-none d-lg-block">Custom Designs</h2>
            <p>
              Custom designs are the perfect way to make your jewelry something
              even more unique, and it’s the perfect way to design anything your
              heart desires. With a custom design, you can make your dream ring
              a reality.
            </p>
            <button className="btn btn-theme btn-secondary mt-2">Browse</button>
          </Col>
        </Row>
      </CustomContainer>
      {/* End:: Discover Collection */}

      <CustomContainer>
        <Row className="align-items-center gx-5">
          <Col lg="5">
            <img
              src={imageURL2.image_93}
              className="img-fluid mb-3 mb-lg-0"
              alt="Images"
            />
          </Col>
          <Col lg="7">
            <h2 className="heading-50">
              The Hero - The center diamond on your engagement ring.
            </h2>
            <p className="mt-5">
              The diamond's shape will determine how brilliantly and in what
              ways your engagement ring will sparkle, making it essential to
              your ring's design. After all, they serve as the diamond's "face,"
              and have a significant impact on the design of your ring.
              <br />
              <br />
              In addition, you can also have lab grown diamonds in any shape,
              such as princess cut, pear cut, round cut, marquise cut, cushion
              cut, oval cut, asscher cut, radiant cut, emerald cut, and heart
              cut – check it out!
            </p>
            {/* <button className="btn btn-theme btn-secondary mt-2">Browse</button> */}
          </Col>
        </Row>
      </CustomContainer>

      {/* Shop by shape */}
      <CustomContainer fluid className="bg-secondary shopbyShape">
        <CustomContainer className="mt-0 mb-0">
          <h2 className="heading-55 mb-5 text-center text-white">
            Discover Collection
          </h2>

          <div className="d-flex flex-wrap justify-content-center">
            <div className="engage-shape text-center">
              <img
                src={imageURL2.image_94}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">asscher</h5>
            </div>
            <div className="engage-shape text-center">
              <img
                src={imageURL2.image_95}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">asscher</h5>
            </div>
            <div className="engage-shape text-center">
              <img
                src={imageURL2.image_97}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">asscher</h5>
            </div>
            <div className="engage-shape text-center">
              <img
                src={imageURL2.image_98}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">asscher</h5>
            </div>
            <div className="engage-shape text-center">
              <img
                src={imageURL2.image_99}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">asscher</h5>
            </div>
            <div className="engage-shape text-center">
              <img
                src={imageURL2.image_100}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">asscher</h5>
            </div>
            <div className="engage-shape text-center">
              <img
                src={imageURL2.image_101}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">asscher</h5>
            </div>
            <div className="engage-shape text-center">
              <img
                src={imageURL2.image_102}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">asscher</h5>
            </div>
            <div className="engage-shape text-center">
              <img
                src={imageURL2.image_103}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">asscher</h5>
            </div>
            <div className="engage-shape text-center">
              <img
                src={imageURL2.image_104}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">asscher</h5>
            </div>
            <div className="engage-shape text-center">
              <img
                src={imageURL2.image_105}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">asscher</h5>
            </div>
          </div>
        </CustomContainer>
      </CustomContainer>

      <CustomContainer>
        <GlobalTitle title="Shop Now" />
        <div className="row single-row-products">
          <div className="col-xl-3 col-md-4 col-sm-6 mb-3">
            <div className="card product-card product-lg" data-aos="fade-up">
              <div className="product-display">
                <img
                  src={imageURL.prodImgLg7}
                  className="img-fluid"
                  alt="product"
                />
                <img
                  src={imageURL.prodImgLg6}
                  className="img-fluid hover"
                  alt="product"
                />
                <div className="product-actions">
                  <button type="button" className="btn">
                    <i className="fa-solid fa-basket-shopping"></i>
                  </button>
                  <button type="button" className="btn">
                    <i className="fa-solid fa-heart"></i>
                  </button>
                  <button type="button" className="btn">
                    <i className="fa-solid fa-eye"></i>
                  </button>
                </div>
              </div>
              <div className="card-body">
                <h5 className="text-primary">Rings</h5>
                <h2>
                  <Link to="/detail" className="text-black">
                    Deko Diamond Ring
                  </Link>
                </h2>
                <h6 className="">$440.00</h6>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-4 col-sm-6 mb-3">
            <div className="card product-card product-lg" data-aos="fade-up">
              <div className="product-display">
                <img
                  src={imageURL.prodImgLg7}
                  className="img-fluid"
                  alt="product"
                />
                <img
                  src={imageURL.prodImgLg6}
                  className="img-fluid hover"
                  alt="product"
                />
                <div className="product-actions">
                  <button type="button" className="btn">
                    <i className="fa-solid fa-basket-shopping"></i>
                  </button>
                  <button type="button" className="btn">
                    <i className="fa-solid fa-heart"></i>
                  </button>
                  <button type="button" className="btn">
                    <i className="fa-solid fa-eye"></i>
                  </button>
                </div>
              </div>
              <div className="card-body">
                <h5 className="text-primary">Rings</h5>
                <h2>
                  <Link to="/detail" className="text-black">
                    Deko Diamond Ring
                  </Link>
                </h2>
                <h6 className="">$440.00</h6>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-4 col-sm-6 mb-3">
            <div className="card product-card product-lg" data-aos="fade-up">
              <div className="product-display">
                <img
                  src={imageURL.prodImgLg7}
                  className="img-fluid"
                  alt="product"
                />
                <img
                  src={imageURL.prodImgLg6}
                  className="img-fluid hover"
                  alt="product"
                />
                <div className="product-actions">
                  <button type="button" className="btn">
                    <i className="fa-solid fa-basket-shopping"></i>
                  </button>
                  <button type="button" className="btn">
                    <i className="fa-solid fa-heart"></i>
                  </button>
                  <button type="button" className="btn">
                    <i className="fa-solid fa-eye"></i>
                  </button>
                </div>
              </div>
              <div className="card-body">
                <h5 className="text-primary">Rings</h5>
                <h2>
                  <Link to="/detail" className="text-black">
                    Deko Diamond Ring
                  </Link>
                </h2>
                <h6 className="">$440.00</h6>
              </div>
            </div>
          </div>
          <div className="col-xl-3 d-none col-sm-6 mb-3 d-xl-block">
            <div className="card product-card product-lg" data-aos="fade-up">
              <div className="product-display">
                <img
                  src={imageURL.prodImgLg7}
                  className="img-fluid"
                  alt="product"
                />
                <img
                  src={imageURL.prodImgLg6}
                  className="img-fluid hover"
                  alt="product"
                />
                <div className="product-actions">
                  <button type="button" className="btn">
                    <i className="fa-solid fa-basket-shopping"></i>
                  </button>
                  <button type="button" className="btn">
                    <i className="fa-solid fa-heart"></i>
                  </button>
                  <button type="button" className="btn">
                    <i className="fa-solid fa-eye"></i>
                  </button>
                </div>
              </div>
              <div className="card-body">
                <h5 className="text-primary">Rings</h5>
                <h2>
                  <Link to="/detail" className="text-black">
                    Deko Diamond Ring
                  </Link>
                </h2>
                <h6 className="">$440.00</h6>
              </div>
            </div>
          </div>
        </div>
      </CustomContainer>
    </>
  );
};

export default RingSize;
