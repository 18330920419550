import axios from "axios";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { baseUrl } from "../constants/urls";
import { contactUsFAQ } from "../constants/utils";
import { imageURL2 } from "../images";
import EngagementFAQ from "./Engagement/EngagementFAQ";
import ContactForm from "./contact/contact-form";
import OrderHelp from "./contact/order-help";
import Footer from "./element/Footer";
import GlobalTitle from "./common/global-title";
import { CustomContainer } from "./common/global-display";

const Contact = () => {
  const initialValue = {
    name: "",
    phone: "",
    email: "",
    message: "",
  };
  const [state, setState] = useState(initialValue);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleChange = (e) => {
    setState((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setIsError(false);
    setErrorMsg("");
  };

  const handleContact = async () => {
    try {
      const res = await axios.post(`${baseUrl}/api/contact-us`, state);
      setState(initialValue);
    } catch (error) {
      setIsError(true);
      setErrorMsg("Enter Valid Details");
    }
  };
  return (
    <>
      <img
        src={imageURL2.image_64}
        className=" engagment-hero-section-master"
        alt="Hero bg"
      />

      <ContactForm
        state={state}
        handleChange={handleChange}
        isError={isError}
        errorMsg={errorMsg}
        handleContact={handleContact}
      />
      <CustomContainer className="mt-0 mb-0">
        <GlobalTitle
          title="We're always here to help."
          description="A commitment to sustainability, transparency and jewelry made to fit
            beautifully into your big moments."
        />
      </CustomContainer>

      <CustomContainer>
        <OrderHelp />
      </CustomContainer>

      <div className="pb-5 ">
        <EngagementFAQ faqs={contactUsFAQ()} />
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Contact;
