import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  // const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem("cookieConsent");
    if (!cookieConsent) {
      setIsVisible(true);
    }
    //  else {
    //   setCookiesAccepted(cookieConsent === "true");
    // }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    // setCookiesAccepted(true);
    setIsVisible(false);
  };

  const handleReject = () => {
    localStorage.setItem("cookieConsent", "false");
    // setCookiesAccepted(false);
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div
          className="alert alert-info fixed-bottom mb-0 bg-white"
          role="alert"
          style={{
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            borderColor: "#e77e500b", // Change border color
            backgroundColor: "#e77e500a", // Ensure the background remains white
            // color: '#e77e50' // Optional: Change text color to match the theme
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0">
              Our website uses cookies and similar technologies, including to
              remember user preferences, perform analytics, and for targeted
              advertising. Your use of our website is subject to our
              <Link to="/privacy-policy" className="text-primary">
                {" "}
                Privacy Policy
              </Link>{" "}
              and
              <Link to="/terms-and-conditions" className="text-primary">
                {" "}
                Terms & Conditions
              </Link>
              .
            </p>

            <div className="d-flex">
              <button
                className="btn btn-black-outline btn-outline-to-secondary"
                onClick={handleAccept}
              >
                Accept All
              </button>
              <button
                className="btn  btn-black-outline btn-outline-to-secondary"
                onClick={handleReject}
              >
                Reject All
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
