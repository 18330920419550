import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { baseUrl, getMarketingCampaing } from "../../constants/urls";

const Campaign = () => {
  const [showCampaign, setShowCampaign] = useState(false);
  const [marketingData, setMarketingData] = useState(null);
  const location = useLocation();
  useEffect(() => {
    if (location?.pathname === "/") {
      const campaign = sessionStorage?.getItem("campaign");
      console.log(campaign, "campaign");
      if (!campaign) {
        fetchMarketingCampaign();
        setTimeout(() => {
          setShowCampaign(true);
        }, 5000);
      }
    }
  }, []);

  useEffect(() => {
    if (showCampaign && marketingData?.marketing_image) {
      // Disable scroll on body when the campaign is shown
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable scroll when the campaign is closed
      document.body.style.overflow = "auto";
    }

    // Cleanup function to reset the scroll state when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showCampaign, marketingData]);

  const fetchMarketingCampaign = async () => {
    try {
      const res = await axios?.get(getMarketingCampaing);
      const data = res?.data;
      // console.log({...data ,...data?.data})
      setMarketingData({...data ,...data?.data});
    } catch (error) {
      console?.error("Error fetching marketing campaign:", error);
    }
  };

  const handleClose = () => {
    sessionStorage?.setItem("campaign", "true");
    setShowCampaign(false);
  };
  const handleSubscribe = async (email) => {
    try {
      const res = await axios.post(`${baseUrl}/api/subscribe-newsletter`, {
        email: email,
      });
      toast.success(res?.data?.data?.message);
      handleClose()
      // setEmail("");
    } catch (error) {
      // console.log(error);
      // setIsError(true);
      toast.error(error?.response?.data?.error?.[0]?.message);
      // setErrorMsg("Enter Valid Email");
    }
  };
  //   console.log(marketingData, "marketingData");

  return showCampaign && marketingData && marketingData?.marketing_image ? (
    <div
      className="campaign-modal  bg-black bg-opacity-50"

      // style={{ zIndex: 9999, height: "100vh", width: "100vw" }}
    >
      <div
        className="campaign-content position-relative d-flex align-items-center justify-content-center "
        style={{
          backgroundImage: `url(${marketingData?.marketing_image})`,
          // backgroundImage: `url(https://andorluxury.blr1.cdn.digitaloceanspaces.com/11880/image_3.png?t=0.26461206952618577)`,
          // backgroundImage: `url(https://live.visually-io.com/media/RENAISSANCEFMI/thumb_ee25de19-7464-4b93-ae67-d99586483d28.png)`,
        }}
      >
        <div className="position-relative">
          {/* <img
            src={marketingData?.marketing_image}
            alt="Campaign"
            className="img-fluid"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "contain",
            }}
          /> */}
          <button
            onClick={handleClose}
            className="btn-close position-absolute bg-white text-black"
            aria-label="Close"
            style={{
              top: "10px",
              right: "10px",
              borderRadius: "50%",
            }}
          />
          <div className="campaign-sidepart">
            <div
              style={{
                background: "rgba(199, 130, 90, 0.5)",
                padding: "3.72rem 1rem 3.7rem 1rem",
              }}
            >
              <div className="campaign-sidepart-top">
                <p>
                  GET <span>10%</span> OFF
                </p>
                <p>ON YOUR FIRST ORDER</p>
              </div>
              <div className="campaign-sidepart-middle">
                <p>Sign up for the latest</p>
                <p>Andor Luxury news and offers</p>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault(); // Prevents the default form submission behavior.
                  console.log(e);
                  console.log(e.target?.email?.value);
                  if(e.target?.email?.value){
                    handleSubscribe(e.target?.email?.value)
                  }
                  // handleFormSubmit(); // Replace this with your API call function.
                }}
              >
                <input
                  placeholder="Email Address"
                  type="email"
                  required={true}
                  name="email"
                  data-uw-rm-form="fx"
                  aria-label="Email Address"
                  data-uw-hidden-control="hidden-control-element"
                  // onChange={(el) => {
                  //   console.log(el);
                  // }}
                />

                <div className="campaign-sidepart-disclaimer">
                  By submitting this form and signing up for emails, you consent
                  to receive marketing newsletters (e.g. promos, cart reminders)
                  from Andor luxury. at the email address provided. Consent is
                  not a condition of purchase. Email frequency varies.
                  Unsubscribe at any time by clicking the unsubscribe link or
                  contact us at{" "}
                  <a href="mailto:info@andorluxury.com">info@andorluxury.com</a>{" "}
                  <a
                    href="/privacy-policy"
                    data-uw-rm-brl="PR"
                    data-uw-original-href="/privacy-policy"
                  >
                    Privacy Policy
                  </a>{" "}
                  &amp;{" "}
                  <a
                    href="/terms-and-conditions"
                    data-uw-rm-brl="PR"
                    data-uw-original-href="/terms-and-conditions"
                  >
                    Terms
                  </a>
                  .
                </div>
                <div className="button-wrapper">
                  <button>SUBMIT</button>
                  <button
                    className="btn btn-theme btn-white-outline btn-white-outline-hover"
                    onClick={handleClose}
                  >
                    NO THANKS
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Campaign;
