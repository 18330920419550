import React from "react";
import "../../assets/css/aboutus.css";
import CardSection from "./cardSection";
import AbountCounter from "./abount-counter";
import ContactBanner from "./contact-banner";
import { imageURL2 } from "../../images";
import { CustomContainer } from "../common/global-display";
import GlobalTitle from "../common/global-title";

const Aboutus2 = () => {
  return (
    <div className="aboutus-new-main mt-5 my-6">
      <CustomContainer>
        {/* <CustomContainer className="mb-0 mt-0"> */}
        {/* <CustomContainer className="mb-0 mt-0"> */}
        <div className="my-7 d-md-none">
          <h1 className="my-3 text-center">OUR ANDOR STORY</h1>
          <h2
            className="my-3 text-center"
            style={{
              fontFamily: "'Castoro', serif",
              fontWeight: "500",
            }}
          >
            About Us.
          </h2>
        </div>
        {/* <GlobalTitle title="About Us." /> */}
        <div className="my-6">
          <h5 className="mb-20  text-center">
            {" "}
            We reinterpret diamond jewelry as a symbol of grace and morality
            rather than merely an ornament. 100% conflict-free lab-grown
            diamonds, expertly created to exemplify ageless Elegance and
            uniqueness, are featured in our collection. Every piece uses
            sustainable luxury to celebrate life's most spectacular moments,
            making sure that every sparkle demonstrates our dedication to fine
            craftsmanship and ethical sourcing. Explore our dedication at Andor
            Luxury and decorate yourself with jewelry that exudes integrity and
            meaning in addition to beauty.
          </h5>
          <h5 className="text-center">
            {" "}
            Celebrate your engagement and wedding moments with Andor Luxury,
            where diamonds, with their genuine brilliance and timeless allure,
            eloquently tell the story of your unique journey together. Each
            piece embodies elegance, capturing the essence of your love and
            commitment, making your special day truly unforgettable.
          </h5>
        </div>
        {/* </CustomContainer> */}

        <CardSection
          imgurl={imageURL2.image_65}
          width="78%"
          height="350px"
          sideText={
            <>
              <h2 className=" text-primary my-3">
                Sustainable Style:
                <br />
                Our promise
              </h2>
              <p className="">
                At Andor Luxury, we believe true luxury shines brighter with
                ethical practices. That's why we offer 100% conflict-free
                labgrown diamonds, meticulously crafted with minimal
                environmental impact. Your Andor treasure arrives nestled in an
                eco-friendly box, ensuring every aspect of your experience
                reflects sustainable luxury. Celebrate life's moments with
                dazzling brilliance and a conscience for a brighter future –
                choose Andor Luxury.
              </p>
            </>
          }
          cardPosition="left"
        />

        <AbountCounter />

        <div className="my-6">
          <div
            // style={{ position: "relative" }}
            className=" relative gemologists-wrapper d-md-flex align-items-center justify-content-center mb-5"
          >
            <div
              className="medium-up--one-half"
              style={{ position: "relative" }}
            >
              <div>
                <img
                  src={imageURL2.image_66}
                  className=" d-md-block ml-auto mr-auto "
                  alt
                  // width={248}
                  // height={372}
                />
              </div>
            </div>
            <div className="grid__item-content">
              <div className="content">
                <h2
                  className="my-3 text-primary"
                  style={{
                    fontFamily: "'Castoro', serif",
                    // fontWeight: "500",
                  }}
                >
                  Expert Gemologists
                </h2>
                <p>
                  Our team is as dedicated to you as you are to yours. They've
                  curated and inspected our diamonds and products to ensure
                  quality and craftsmanship is in every detail. Any questions?
                  We're available to help.
                </p>
              </div>
            </div>
          </div>
        </div>

        <CardSection
          imgurl={imageURL2.image_68}
          sideText={
            <>
              <h3 className=" text-primary">
                Exquisitely Crafted :<br />
                Our Designs
              </h3>
              <p>You are unique, and your sparkle should be too.</p>
              <p>
                Andor Luxury offers a curated collection of handcrafted designs,
                featuring modern twists on timeless classics, to ignite your
                brilliance. But for the truly individual, we offer complete
                customization – from diamond selection to setting design, our
                expert craftspeople bring your vision to life.
              </p>
              <p>These aren't just diamonds, they're a reflection of you.</p>
            </>
          }
        />
        {/* <AboutBgwithtext /> */}
      </CustomContainer>
      {/* </CustomContainer> */}
      <ContactBanner />
    </div>
  );
};

export default Aboutus2;
