import "./BuyRingSize.css";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import GlobalTitle from "../common/global-title";
import { CustomContainer } from "../common/global-display";
import { PinnedColumns, StaticTable } from "../../constants/ring-size-data";

export default function TableRingSize({ margin = false, anchorLinkID }) {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");
  return (
    <CustomContainer className={"buy-ring-size mb-5 mt-0"}>
      <GlobalTitle
        title="Ring Size Chart"
        description="All of our rings are crafted in standard US sizes. View the chart
            below to see how US sizes map to finger circumference and convert to
            international sizes. Engagement rings, wedding bands, and fashion
            rings all follow the same size format."
      />
      {isAboveDesktop && <StaticTable />}
      {!isAboveDesktop && (
        <div className="table-wrapper">
          <div className="scrollable">
            <StaticTable />
          </div>
          <div className="pinned">
            <PinnedColumns />
          </div>
        </div>
      )}
    </CustomContainer>
  );
}
