import React, { useEffect, useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { currencyIcon } from "../../constants/function";
import formatCurrency from "../../helper/currencyFormater";

const SearchArea = ({
  isSticky,
  setIsFocus,
  handleSearchChange,
  search,
  // searchResultsRef,
  countryCurrency,
  searchResults,
  closeOffcanvas,
  setSearch,
  cartData,
  handleSearchClick,
  path,
  isHeaderTextWhite,
}) => {
  const navigate = useNavigate();
  const searchResultsRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target)
      ) {
        setSearch("");
        closeOffcanvas();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchResultsRef]);

  return (
    <>
      <div
        className={`d-lg-flex d-none ${
          isHeaderTextWhite ? "header-search-start" : "header-search"
        }  fixed_search-hide ml-1 position-relative`}
      >
        <i className="fa-solid fa-magnifying-glass"></i>
        <Form.Control
          type="text"
          placeholder="Search"
          className="me-2"
          aria-label="Search"
          value={search}
          onChange={handleSearchChange}
          onFocus={() => setIsFocus(true)}
          onBlur={() => {
            setTimeout(() => {
              setIsFocus(false);
            }, 500);
          }}
        />

        {search !== "" && (
          <i className="fa fa-times " style={{ cursor: "pointer" }}></i>
        )}
      </div>
      {search !== "" && (
        <div
          ref={searchResultsRef}
          className="d-lg-block d-none position-absolute shadow p-3 mb-5 bg-body rounded"
          style={{
            top: "65px",
            width: "90%",
            overflow: "auto",
            height: "auto",
          }}
        >
          <div>
            {searchResults.length > 0 ? (
              <div>
                <Row className="g-3">
                  {searchResults.map((item, index) => {
                    const price =
                      countryCurrency == "INR"
                        ? item?.product_price
                        : countryCurrency == "USD"
                          ? item?.usa_price
                          : countryCurrency == "GBP"
                            ? item?.uk_price
                            : countryCurrency == "CAD"
                              ? item?.canada_price
                              : item?.usa_price;
                    const formatedPrice = formatCurrency(price);
                    return (
                      <Col
                        key={index}
                        lg={3}
                        md={4}
                        style={{
                          cursor: "pointer",
                          fontSize: "18px",
                        }}
                        onClick={() => {
                          let formattedName = item?.title.toLowerCase();
                          formattedName = formattedName.replace(/\s+/g, "-");
                          navigate(`/${formattedName}/${item?.sku}`, {
                            state: {
                              slug: "",
                            },
                          });

                          setSearch("");
                          closeOffcanvas();
                        }}
                      >
                        <img
                          src={item?.media_arr[0]}
                          style={{
                            width: "100%",
                          }}
                        />
                        <div>
                          <div>{item?.title}</div>
                          <div
                            style={{
                              color: "grey",
                              fontSize: "16px",
                            }}
                          >
                            {item?.caption}
                          </div>
                          <div>
                            {currencyIcon(
                              cartData?.[0]?.currency ?? countryCurrency,
                            )}
                            {""}
                            {formatedPrice}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  {/* <div
                          style={{
                            margin: "20px",
                            cursor: "pointer",
                            color: "#ffffff",
                            background: "#e77e50",
                            padding: "5px 10px",
                            borderRadius: "25px",
                          }}
                          onClick={handleSearchClick}
                        >
                          View More
                        </div> */}
                  <div>
                    <Button
                      className="btn btn-theme btn-outline text-secondary m-0 btn-hoverable p-2"
                      onClick={handleSearchClick}
                    >
                      View More
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <>No search result found</>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SearchArea;
