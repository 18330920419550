import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getFavouriteList,
  removeFavoriteList,
  resetFavorite,
} from "../../features/cart/cartSlice";
// import { LiaRupeeSignSolid } from "react-icons/lia";
import { IoMdClose } from "react-icons/io";
import formatCurrency from "../../helper/currencyFormater";
// import { FaHeart } from "react-icons/fa6";
// import toast from "react-hot-toast";
import { currencyIcon } from "../../constants/function";

const Wishlist = ({ activeKey }) => {
  const navigate = useNavigate();
  const countryCurrency = localStorage.getItem("currency");
  const wishlistItems = useSelector((state) => state.cart.wishList);
  const wishlistReducer = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const [isFirstTime, setIsFirstTime] = useState(true);
  useEffect(() => {
    if (activeKey == "wishlist" && isFirstTime) {
      dispatch(getFavouriteList());
      setIsFirstTime(false);
    }
  }, [activeKey]);

  const [list, setList] = useState([]);

  useEffect(() => {
    setList(wishlistItems);
  }, [wishlistItems]);

  const handleClick = (formattedName, sku) => {
    navigate(`/${formattedName}/${sku}`);
  };

  const handleRemove = (id) => {
    // if (!isLogin())
    //   return toast.error("Oops, Please login to Remove to wishlist");
    // eslint-disable-next-line no-restricted-globals
    const isOk = confirm(
      "Are you sure you want to remove from your wishlist item?",
    );
    isOk && dispatch(removeFavoriteList(id));
  };

  useEffect(() => {
    if (!!wishlistReducer?.favoriteRemoveSuccess) {
      dispatch(resetFavorite());
      dispatch(getFavouriteList());
    }
  }, [wishlistReducer.favoriteRemoveSuccess]);

  return (
    <>
      <div className="wishlist-container my-5">
        <div className="product-container">
          <div className="row gx-4 product-listing listingpage-container">
            {list?.length > 0 ? (
              list?.map((item) => {
                // const hoverImg = item?.product?.featured_image?.[1]
                //   ? item?.product?.featured_image?.[1]?.original_url
                //   : item?.product?.featured_image?.[0]?.original_url;
                const hoverImg = item?.product?.featured_image
                  ? item?.product?.featured_image?.[1]
                    ? item?.product?.featured_image?.[1]?.original_url
                    : item?.product?.featured_image?.[0]?.original_url
                  : item?.product?.hoverImageUrl
                    ? item?.product?.hoverImageUrl
                    : item?.product?.imgUrl;
                let formattedName = item?.product?.title?.toLowerCase();
                // Replace all white spaces with hyphens
                formattedName = formattedName?.replace(/\s+/g, "-");
                return (
                  <div
                    key={item?.product_id}
                    className="p-3 col-lg-3 col-md-4 col-sm-6 col-6"
                  >
                    <div>
                      <div className="card product-card">
                        <div className="product-display">
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              handleClick(formattedName, item?.product?.sku)
                            }
                          >
                            <img
                              src={
                                item?.product?.featured_image?.[0]
                                  ?.original_url ?? item?.product?.imgUrl
                              }
                              className="img-fluid"
                              alt="product"
                            />
                            <img
                              src={hoverImg}
                              className="img-fluid hover"
                              alt="product"
                            />
                          </div>
                          {/* <div className="product-actions">
                            <button
                              type="button"
                              className="btn"
                              onClick={() => handleRemove(item?.id)}
                            >
                              <FaHeart color="#e77e50" />
                            </button>
                          </div> */}
                          <div className="wishcard-actions">
                            <IoMdClose
                              onClick={() => handleRemove(item?.product_id)}
                              size={25}
                              color="#e77e50"
                            />
                            {/* </button> */}
                          </div>
                        </div>
                        <div className="card-body">
                          {/* <h5 className="text-primary">{"type"}</h5> */}
                          <h2>
                            {/* <Link to={`/`} className="text-black"> */}
                            <Link
                              to={`/${formattedName}/${item?.product?.sku}`}
                              className="text-black"
                            >
                              {/* <Link to={`/${category}/${id}`} className="text-black"> */}
                              {item?.product?.title}
                            </Link>
                          </h2>
                          <div
                            className="product-text-center"
                            style={{
                              fontSize: "20px",
                            }}
                          >
                            {currencyIcon(countryCurrency)}{" "}
                            {/* <LiaRupeeSignSolid /> */}
                            {formatCurrency(
                              countryCurrency == "INR"
                                ? formatCurrency(item?.product?.product_price)
                                : countryCurrency == "USD"
                                  ? formatCurrency(item?.product?.usa_price)
                                  : countryCurrency == "GBP"
                                    ? formatCurrency(item?.product?.uk_price)
                                    : countryCurrency == "CAD"
                                      ? formatCurrency(
                                          item?.product?.canada_price,
                                        )
                                      : (formatCurrency(
                                          item?.product?.usa_price,
                                        ) ??
                                        formatCurrency(item?.product?.price) ??
                                        formatCurrency(
                                          item?.product?.product_price,
                                        )),
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center py-4">
                <h3>Your wish list is empty. </h3>
                <p className="no-data-text">
                  Start adding items to see them here!
                </p>
              </div>
            )}
          </div>

          <div className="">
            <Row>
              <Col className="text-start">
                <div
                  variant="link"
                  className="text-decoration-none fs-5 primary-color cursor-pointer"
                  // style={{ cursor: "pointer", color: "#e77e50" }}
                  onClick={() => {
                    // navigate("/profile");
                    window.history.back();
                  }}
                >
                  &lt; Back
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Wishlist;
