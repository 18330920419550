import React from "react";
import { Card } from "react-bootstrap";

const AddressCard = ({
  name,
  id,
  city,
  state,
  address1,
  address2,
  postal_code,
  country,
  setSelectedAddress,
  onSelectAddress,
  selectedId,
}) => {
  return (
    <div className="col-md-6 col-lg-4 mt-0 mb-2">
      <div className="address-list">
        <input
          className="form-check-input mb-1"
          type="radio"
          name="flexRadioDefault"
          id={`add-${id}`}
          onChange={() => {
            setSelectedAddress(id);
            !!onSelectAddress && onSelectAddress();
          }}
          checked={selectedId === id}
        />
        <label
          className="card address-card mb-0"
          htmlFor={`add-${id}`}
          style={{
            height: "165px",
          }}
        >
          <div className="card-body">
            <Card.Title>{name}</Card.Title>
            <Card.Text className="mb-1">{address1}</Card.Text>
            <Card.Text className="mb-1">{address2}</Card.Text>
            <Card.Text className="mb-1">
              {city}, {state}, {country} {postal_code}
            </Card.Text>
          </div>
        </label>
      </div>
    </div>
  );
};

export default AddressCard;
