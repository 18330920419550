// export const CustomCol = ({
//     children,
//     xs,
//     sm,
//     md,
//     lg,
//     xl,
//     className = "",
//     total = 12,
//   }) => {
//     return (
//       <div
//         className={`   ${xs ? sm:w-${xs}/${total} : ""} ${
//           sm ? md:w-${sm}/${total} : ""
//         } ${md ? lg:w-${md}/${total} : ""} ${lg ? xl:w-${lg}/${total} : ""} ${
//           xl ? 2xl:w-${xl}/${total} : ""
//         } ${className}`}
//       >
//         {children}
//       </div>
//     );
//   };

export const CustomContainer = ({ children, className = "", style = {} }) => {
  // small-container fow cenetr max-width apva
  return (
    <div
      // className={container m-auto px-2 sm:px-4 lg:px-4 xl:px-24 2xl:px-32 3xl:px-24   ${className}}
      // className={container m-auto sm:px-4 lg:px-10 xl:px-40 2xl:px-40 3xl:px-40  4xl:px-48 max-[391px]:max-w-[340px] sm:max-w-470px md:max-w-500px lg:max-w-725px xl:max-w-880px 2xl:max-w-1100px 3xl:max-w-1250px 4xl:max-w-1600px ${className}}
      className={` ${className} my-6 container `}
      style={style}
    >
      {children}
    </div>
  );
};

//   export const CustomRow = ({ children, className = "" }) => {
//     return <div className={flex flex-wrap gap-2 ${className}}>{children}</div>;
//   };

//   export const CustomGrid = ({
//     xs = 0,
//     sm = 0,
//     md = 0,
//     lg = 0,
//     xl = 0,
//     xxl = 0,
//     xxxl = 0,
//     data = [],
//     Component,
//     className = "gap-8 ",
//   }) => {
//     const smGrid = {
//       0: "",
//       1: "sm:grid-cols-1",
//       2: "sm:grid-cols-2",
//       3: "sm:grid-cols-3",
//       4: "sm:grid-cols-4",
//       5: "sm:grid-cols-5",
//       6: "sm:grid-cols-6",
//     };
//     const xsGrid = {
//       0: "",
//       1: "xs:grid-cols-1",
//       2: "xs:grid-cols-2",
//       3: "xs:grid-cols-3",
//       4: "xs:grid-cols-4",
//       5: "xs:grid-cols-5",
//       6: "xs:grid-cols-6",
//     };
//     const mdGrid = {
//       0: "",
//       1: "md:grid-cols-1",
//       2: "md:grid-cols-2",
//       3: "md:grid-cols-3",
//       4: "md:grid-cols-4",
//       5: "md:grid-cols-5",
//       6: "md:grid-cols-6",
//     };
//     const lgGrid = {
//       0: "",
//       1: "lg:grid-cols-1",
//       2: "lg:grid-cols-2",
//       3: "lg:grid-cols-3",
//       4: "lg:grid-cols-4",
//       5: "lg:grid-cols-5",
//       6: "lg:grid-cols-6",
//     };
//     const xlGrid = {
//       0: "",
//       1: "xl:grid-cols-1",
//       2: "xl:grid-cols-2",
//       3: "xl:grid-cols-3",
//       4: "xl:grid-cols-4",
//       5: "xl:grid-cols-5",
//       6: "xl:grid-cols-6",
//     };
//     const xxlGrid = {
//       0: "",
//       1: "2xl:grid-cols-1",
//       2: "2xl:grid-cols-2",
//       3: "2xl:grid-cols-3",
//       4: "2xl:grid-cols-4",
//       5: "2xl:grid-cols-5",
//       6: "2xl:grid-cols-6",
//     };
//     const xxxlGrid = {
//       0: "",
//       1: "3xl:grid-cols-1",
//       2: "3xl:grid-cols-2",
//       3: "3xl:grid-cols-3",
//       4: "3xl:grid-cols-4",
//       5: "3xl:grid-cols-5",
//       6: "3xl:grid-cols-6",
//     };
//     const xxxxlGrid = {
//       0: "",
//       1: "4xl:grid-cols-1",
//       2: "4xl:grid-cols-2",
//       3: "4xl:grid-cols-3",
//       4: "4xl:grid-cols-4",
//       5: "4xl:grid-cols-5",
//       6: "4xl:grid-cols-6",
//     };
//     return (
//       <div
//         // className={`grid grid-cols-1 ${xsGrid[xs]} ${smGrid[sm]}  ${mdGrid[md]}  ${lgGrid[lg]}  ${xlGrid[xl]}  ${xxlGrid[xxl]}  ${xxxlGrid[xxxl]} ${className} `}
//         className={`grid grid-cols-1 ${xsGrid[xs]} ${smGrid[sm]}  ${
//           mdGrid[md]
//         }  ${lgGrid[lg]}  ${xlGrid[xl]}  ${xxlGrid[xxl]}  ${xxxlGrid[xxxl]} ${
//           xxxxlGrid[xxxl || xxl || xl || lg || md || sm || xs || 0]
//         }  ${className}`}
//       >
//         {/* Single loop to render grid items for all breakpoints */}
//         {/* {data.slice(0, xs + sm + md + lg + xl + xxl + xxxl).map((item, index) => (
//           <Component key={index} data={item} />
//         ))} */}
//         {data?.map((item, index) => (
//           <Component key={index} index={index} data={item} />
//         ))}
//       </div>
//     );
//   };

//   export const CustomButtonGrid = ({ children, className = "" }) => {
//     return (
//       <div
//         className={flex flex-wrap justify-center mt-5 gap-x-5 sm:gap-x-20 gap-y-5 md:mt-10 ${className}}
//       >
//         {children}
//       </div>
//     );
//   };

//   export const HeadingTags = ({ htag = 2, children, ...rest }) => {
//     switch (htag) {
//       case 1:
//         return <h1 {...rest}>{children}</h1>;
//       case 2:
//         return <h2 {...rest}>{children}</h2>;
//       case 3:
//         return <h3 {...rest}>{children}</h3>;
//       case 4:
//         return <h4 {...rest}>{children}</h4>;
//       case 5:
//         return <h5 {...rest}>{children}</h5>;
//       case 6:
//         return <h6 {...rest}>{children}</h6>;
//       default:
//         return <p {...rest}>{children}</p>;
//     }
//   };
