import React, { useState } from "react";
import canada from "../../assets/images/canada.png";
import india from "../../assets/images/india.png";
import uk from "../../assets/images/uk.png";
import us from "../../assets/images/us.png";
import { changeCartCurrency, currencyToKey } from "../../constants/function";
import { RingSizes } from "../product/productUtility";
import "./CountrySelector.css"; // Import the CSS file for styling

const CountrySelector = ({ className = "" }) => {
  const [currency, setCurrency] = useState(
    localStorage.getItem("currency") || "INR",
  );

  const handleSelect = async (value) => {
    let cart = JSON.parse(localStorage.getItem("cart")) || [];

    cart = await cart?.map((product) => {
      const oldCurrency = currency;
      const currentSize = product.product.Productsize;
      const convertNumber = currencyToKey(oldCurrency);
      const covertNewNumber = currencyToKey(value);
      const oldSizeIndex = RingSizes?.[convertNumber]?.findIndex(
        (size) => size?.value === currentSize,
      );
      let newSize = currentSize;

      if (oldSizeIndex !== -1) {
        // const newSize = RingSizes[covertNewNumber][oldSizeIndex]?.value;

        for (
          let i = oldSizeIndex;
          i < RingSizes?.[covertNewNumber]?.length;
          i++
        ) {
          if (RingSizes?.[covertNewNumber]?.[i]?.value) {
            newSize = RingSizes?.[covertNewNumber]?.[i]?.value;
            break;
          }
        }
        return {
          ...product,
          product: {
            ...product.product,
            Productsize: newSize || currentSize,
          },
        };
      }

      return product;
    });

    localStorage.setItem("cart", JSON.stringify(cart));
    changeCartCurrency(value);
    localStorage.setItem("currency", value);
    setCurrency(value);
    window.location.reload(); // Refresh the page if needed
  };

  return (
    <>
      <div className={`custom-select-wrapper  ${className}`}>
        <div className={`custom-select ${className}`}>
          <div className={`custom-select-trigger ${className}`}>
            <span className="d-flex align-items-center">
              {currency === "GBP" && <img src={uk} alt="UK" />}
              {currency === "USD" && <img src={us} alt="US" />}
              {currency === "INR" && <img src={india} alt="India" />}
              {currency === "CAD" && <img src={canada} alt="Canada" />}
              {currency}
            </span>
          </div>
          <div className="custom-options">
            <span
              className="custom-option "
              onClick={() => handleSelect("GBP")}
            >
              <img src={uk} alt="UK" /> GBP
            </span>
            <span className="custom-option" onClick={() => handleSelect("USD")}>
              <img src={us} alt="US" /> USD
            </span>
            <span className="custom-option" onClick={() => handleSelect("INR")}>
              <img src={india} alt="India" /> INR
            </span>
            <span className="custom-option" onClick={() => handleSelect("CAD")}>
              <img src={canada} alt="Canada" /> CAD
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CountrySelector;
