import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { singinUrl } from "../../constants/urls";
import { useNavigate } from "react-router";

const initialState = {
  user: {},
  isLoading: false,
  error: null,
  success: false,
};

export const userSingin = createAsyncThunk("user/login", async (user) => {
  try {
    const res = await axios.post(singinUrl, user);
    localStorage.setItem("token", res.data.token);
    localStorage.setItem("user", JSON.stringify(res.data.user));
    localStorage.setItem("user_name", res.data.user.first_name);
    return res.data;
  } catch (error) {
    console.error("error in login: ", error);
    throw error.response.data.error;
  }
});

const singin = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userSingin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userSingin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.success = true;
    });

    builder.addCase(userSingin.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export default singin.reducer;
