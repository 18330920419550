import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { headerHoverEffect } from "../../constants/function";
import { baseUrl } from "../../constants/urls";
import {
  getCartDetails,
  getFavouriteList,
} from "../../features/cart/cartSlice";
import {
  fetchNavbarContent,
  setEngagementData,
  setJewelryData,
  setWeddingData,
} from "../../features/navbar/navbarSlice";
import CartIcon from "../cutomIconButton/cartIcon";
import WishlistIcon from "../cutomIconButton/wishlistIcon";
import CartSidebar from "../headerArea/cartSidebar";
import CategoryMenu from "../headerArea/categoryMenu";
import SearchArea from "../headerArea/searchArea";
import SearchMobileArea from "../headerArea/searchMobileArea";
import UpSideButton from "../UpSideButton";
import CountrySelector from "./CountrySelector";
import { CustomContainer } from "../common/global-display";

const Header = ({ hide }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const wishlistItems = useSelector((state) => state.cart.wishList);
  const [cartData, setCartData] = useState([]);
  useEffect(() => {
    AOS.init();
  }, []);
  const [sidemenu, setSidemenu] = useState(false);
  const sidemenuClose = () => setSidemenu(false);
  const [wishListData, setWishListData] = useState([]);

  useEffect(() => {
    setWishListData(wishlistItems);
  }, [wishlistItems]);

  useEffect(() => {
    setCartData(cartItems);
  }, [cartItems]);

  useEffect(() => {
    dispatch(getCartDetails());
    dispatch(getFavouriteList());
  }, []);

  const sidemenuShow = () => {
    dispatch(getCartDetails());
    setSidemenu(true);
  };

  // const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  // Initialize refs for elements
  const elTopOffsetRef = useRef(null);
  const elHeightRef = useRef(null);

  const navbarData = useSelector((state) => state.navbar.contents.data);

  const searchResultsRef = useRef(null);
  const location = useLocation();
  const path = location.pathname;
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if user has scrolled down 100px from the top
      setIsBottom(window.scrollY > 100);
    };
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);
    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    location.pathname === "/" &&
      isBottom &&
      window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target)
      ) {
        setSearch("");
        closeOffcanvas();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchResultsRef]);

  //   Fetching all data for navbar
  useEffect(() => {
    dispatch(fetchNavbarContent());
  }, [dispatch]);

  //   Filtering the data for engagement
  useEffect(() => {
    if (navbarData) {
      const engagementData = navbarData.find(
        (item) => item.name === "Engagement"
      );
      const weddingData = navbarData.find((item) => item.name === "Wedding");

      const jewelryData = navbarData.find((item) => item.name === "Jewelry");

      // Dispatching level2 Data in globar sotre in navslice
      const engagementLevel2 = engagementData.level1[0].level2;
      const weddingLevel1 = weddingData.level1;
      const jewelryLevel1 = jewelryData.level1;
      dispatch(setEngagementData(engagementLevel2));
      dispatch(setWeddingData(weddingLevel1));
      dispatch(setJewelryData(jewelryLevel1));
    }
  }, [navbarData]);

  // Setting Engagement states

  const closeOffcanvas = () => {
    // Find the element with the 'offcanvas-backdrop' class
    const backdrop = document.querySelector(".offcanvas-backdrop");
    if (backdrop) {
      backdrop.classList.remove("show");
      backdrop.click();
    }

    // Additionally, if you need to hide the offcanvas itself
    const offcanvas = document.querySelector(".navbar-offcanvas");
    if (offcanvas) {
      // offcanvas.remove();
      offcanvas.classList.remove("show");
    }
    // document.body.style.overflow = "auto";
    document.body.removeAttribute("class");
    document.body.removeAttribute("style");
    document.body.removeAttribute("data-rr-ui-modal-open");

    var button = document.getElementById("nav_toggle_button");
    button.classList.add("collapsed");
  };

  const [isSticky, setIsSticky] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const cleanUp = headerHoverEffect({
      isHovered,
      setIsSticky,
      setIsHovered,
      location,
      isBottom,
    });

    // Clean up event listeners on component unmount
    return () => {
      cleanUp && cleanUp();
    };
  }, [isHovered, window.location.pathname]);
  //   const isHomePage = window.location.pathname === "/";
  // const isScrolled = !isBottom;
  // const isHeaderTextWhite = (
  //   isHomePage &&
  //   !isScrolled &&
  //   !isHovered &&
  //   isBottom
  // );
  const isHomePage = window.location.pathname === "/";
  const isScrolled = !isBottom;

  let isHeaderTextWhite = isHomePage && isBottom && !isScrolled && !isHovered;
  // console.log("FilterHeader | isHeaderTextWhite:", isHeaderTextWhite,window.location.pathname);
  if (isHomePage) {
    if (isHovered) {
      isHeaderTextWhite = false;
    } else if (isBottom) {
      isHeaderTextWhite = false;
    } else if (isSticky) {
      isHeaderTextWhite = false;
    } else {
      isHeaderTextWhite = true;
    }
  }

  //search
  // console.log(path, isHovered, isBottom, isSticky);
  // search
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (search == "") {
      setSearchResults([]);
    }
  }, [search]);

  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const fetchSearchData = async ({ value, type }) => {
    // type 0 and 1 0 is default and if click on view more button than pass 1
    try {
      const response = await axios.post(
        `${baseUrl}/api/search?field=${value}&search_type=${type}`
      );
      const data = await response.data.products;
      setSearchResults(data); // Assuming the API returns an array of results
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const debouncedFetchData = debounce(
    () =>
      fetchSearchData({
        type: 0,
        value: search,
      }),
    300
  );

  // useEffect to call fetchData as the user types
  useEffect(() => {
    if (search.length > 0) {
      // Only fetch if there is a query
      debouncedFetchData(search);
    } else {
      setSearchResults([]); // Clear results if query is empty
    }
    // Cleanup function to cancel the debounce on component unmount
    return () => {
      debouncedFetchData.cancel();
    };
  }, [search]);
  const countryCurrncy = localStorage.getItem("currency");
  const [countryCurrency, setCountryCurrency] = useState("INR");
  // const  countryCurrency = localStorage.getItem("currency");

  useEffect(() => {
    if (!localStorage.getItem("currency")) return;
    setCountryCurrency(localStorage.getItem("currency"));
  }, [countryCurrncy]);

  const handleSearchClick = () => {
    // console.log("re")
    closeOffcanvas();
    navigate(`/search-result-page?q=${search}`);
    setSearch("");
  };

  return (
    <>
      <header
        className={`header-section header-section-hover-maintainer ${
          isSticky ? "sticky" : ""
        } ${isHeaderTextWhite ? "" : "header-section-white"}`}
        style={{
          marginTop: 0,
          zIndex: "100",
          // background: isShown ? "white" : "",
        }}
        ref={elTopOffsetRef}
      >
        <CustomContainer className="mt-0 mb-0 text-center">
          <div onClick={scrollToTop} className="cursor-pointer">
            <Link
              to={"/"}
              target="_top"
              className={`${
                isHeaderTextWhite ? "text-white" : "text-primary"
              } pt-3 d-block`}
              style={{ height: "68px" }}
            >
              <h2
                className={`header_fixed-logo mb-0  text-logo logo-txt ${
                  isHeaderTextWhite ? "text-white" : "text-primary"
                }`}
                style={{ lineHeight: "20px" }}
              >
                ANDOR
              </h2>
              <span
                className={`header_fixed-logo  text-logo ${
                  isHeaderTextWhite ? "text-white" : "text-primary"
                }`}
                style={{
                  fontFamily: "Philosopher",
                }}
              >
                Luxury
              </span>
            </Link>
          </div>
        </CustomContainer>
        <Navbar
          expand="lg"
          className={
            `andor-menu py-lg-0 py-2 notFixed header-section-hover-maintainer `
            // ${sticky.isSticky ? " fixed" : "notFixed"}
          }
        >
          <CustomContainer className="mt-0 mb-0 header-section-hover-maintainer">
            {/* <Navbar.Toggle
              className="border-0 ps-0"
              aria-controls={`offcanvasNavbar-expand-lg`}
              id={"nav_toggle_button"}
            /> */}
            <div>
              <Navbar.Toggle
                className="border-0 ps-0"
                aria-controls="offcanvasNavbar-expand-lg"
                id={"nav_toggle_button"}
                // style={{ fontSize: "24px" }}
              >
                <i
                  className="fa fa-bars"
                  style={{
                    fontSize: "20px",
                    color: isHeaderTextWhite ? "white" : "gray",
                  }}
                ></i>
              </Navbar.Toggle>
              {/* <CountrySelector
                className={`d-lg-none ${
                  isHeaderTextWhite ? "text-white" : "text-primary"
                }`}
              /> */}
            </div>

            {/* <Searchbar className={""} /> */}
            {/* ======================Search Area==================== */}
            <div className="d-flex align-items-center">
              <CountrySelector
                className={`d-none d-lg-block ${
                  isHeaderTextWhite ? "text-white" : "text-primary"
                }`}
              />
              <SearchArea
                isSticky={isSticky}
                setIsFocus={setIsFocus}
                handleSearchChange={handleSearchChange}
                search={search}
                // searchResultsRef={searchResultsRef}
                countryCurrency={countryCurrency}
                searchResults={searchResults}
                closeOffcanvas={closeOffcanvas}
                setSearch={setSearch}
                cartData={cartData}
                handleSearchClick={handleSearchClick}
                path={path}
                isHeaderTextWhite={isHeaderTextWhite}
              />
            </div>
            <div className="d-lg-none gap-2 d-flex align-items-center header-buttons">
              <WishlistIcon
                isHeaderTextWhite={isHeaderTextWhite}
                wishListData={wishListData}
              />

              <CartIcon
                isHeaderTextWhite={isHeaderTextWhite}
                data={cartData}
                onClick={sidemenuShow}
              />
            </div>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-lg`}
              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
              placement="start"
              className="navbar-offcanvas"
            >
              <Offcanvas.Header
                className="position-relative pb-0"
                closeButton
                closeLabel={{ id: "testId" }}
              >
                {/* =============== Mobile screen LOGO and BUTTON =============== */}
                {/* <LogowithButtonMobileArea
                  wishListData={wishListData}
                  sidemenuShow={sidemenuShow}
                  cartData={cartData}
                /> */}
                {/* ============= Mobiel Search bar ============== */}
                <Offcanvas.Title
                  className="d-block w-100 text-center"
                  style={{
                    paddingLeft: "20px",
                  }}
                  id={`offcanvasNavbarLabel-expand-lg`}
                >
                  <SearchMobileArea
                    // isSticky={isSticky}
                    search={search}
                    handleSearchChange={handleSearchChange}
                    setIsFocus={setIsFocus}
                    isFocus={isFocus}
                    closeOffcanvas={closeOffcanvas}
                    countryCurrency={countryCurrency}
                    cartData={cartData}
                    searchResults={searchResults}
                    handleSearchClick={handleSearchClick}
                    // path={path}
                  />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                {/* ============= Mobiel Search bar ============== */}
                {/* <SearchMobileArea
                  // isSticky={isSticky}
                  search={search}
                  handleSearchChange={handleSearchChange}
                  setIsFocus={setIsFocus}
                  isFocus={isFocus}
                  closeOffcanvas={closeOffcanvas}
                  countryCurrency={countryCurrency}
                  cartData={cartData}
                  searchResults={searchResults}
                  handleSearchClick={handleSearchClick}
                  // path={path}
                /> */}

                {/* ============= Category Menu List ============= */}
                <CategoryMenu
                  isSticky={isSticky}
                  isHeaderTextWhite={isHeaderTextWhite}
                  closeOffcanvas={closeOffcanvas}
                  sidemenuClose={sidemenuClose}
                  wishListData={wishListData}
                  cartData={cartData}
                  sidemenuShow={sidemenuShow}
                />
                <div className="d-lg-none mx-1 relative w-100">
                  <CountrySelector
                    className={`d-lg-none w-100 ${"text-primary"}`}
                  />
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </CustomContainer>
        </Navbar>
      </header>
      <div ref={elHeightRef}>
        {/* ============= Main Cart Right SideBar Area ============= */}
        <CartSidebar
          sidemenu={sidemenu}
          sidemenuClose={sidemenuClose}
          cartData={cartData}
          countryCurrency={countryCurrency}
        />
      </div>

      <UpSideButton />
    </>
  );
};

export default Header;
