import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import GlobalTitle from "../common/global-title";
import { CustomContainer } from "../common/global-display";

const GetInspired = ({ inspiredDummyData }) => {
  const navigate = useNavigate();

  const cardData = inspiredDummyData?.map((categoryData, index) => {
    return (
      <Col key={index} xs={4} sm={4} md={4} lg={4} className="mb-4b p-0">
        <div key={index} className="px-3b">
          <div className="slider-card">
            <Link
              className="carousole-photo position-relative d-block"
              to={categoryData.link}
            >
              <img
                className="img-fluid cursor-pointer"
                src={categoryData.image}
                alt={categoryData.title}
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
                onClick={() => navigate(categoryData.link)}
              />
              <div className="overlay w-full h-full">shop now </div>
            </Link>
          </div>
        </div>
      </Col>
    );
  });
  return (
    <CustomContainer className="get-inspired grid">
      <GlobalTitle title="AS SEE ON" />
      <Row className=" col-12 col-md-6 mx-auto">{cardData}</Row>
    </CustomContainer>
  );
};

export default GetInspired;

// export const PrevArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <FiChevronLeft
//       className={className}
//       style={{
//         ...style,
//         cursor: "pointer",
//         fontSize: "24px",
//       }}
//       onClick={onClick}
//     />
//   );
// };

// export const NextArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <FiChevronRight
//       className={className}
//       style={{
//         ...style,
//         cursor: "pointer",
//         fontSize: "24px",
//       }}
//       onClick={onClick}
//     />
//   );
// };

// const responsive = [
//   {
//     breakpoint: 1024,
//     settings: {
//       slidesToShow: 4,
//       slidesToScroll: 4,
//     },
//   },
//   {
//     breakpoint: 769,
//     settings: {
//       slidesToShow: 3,
//       slidesToScroll: 3,
//     },
//   },
//   {
//     breakpoint: 600,
//     settings: {
//       slidesToShow: 2,
//       slidesToScroll: 2,
//     },
//   },
//   {
//     breakpoint: 480,
//     settings: {
//       slidesToShow: 1,
//       slidesToScroll: 1,
//     },
//   },
// ];

// export const ProductSlider = ({inspiredDummyData}) => {
//   const navigate = useNavigate();

//   const settings = {
//     infinite: false,
//     dots: false,
//     speed: 500,
//     slidesToShow: 4.5,
//     slidesToScroll: 4,
//     arrows: false,
//     // prevArrow: <PrevArrow />,
//     // nextArrow: <NextArrow />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 4,
//           slidesToScroll: 4,
//         },
//       },
//       {
//         breakpoint: 769,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 3,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };
//   let sliderRef = useRef(null);
//   const next = () => {
//     sliderRef.slickNext();
//   };
//   const previous = () => {
//     sliderRef.slickPrev();
//   };

//   return (
//     <div>
//  <p className="d-flex align-items-center " style={{justifyContent:"space-between"}}>
//             <a className="text-black text-decoration-underline me-3" href="#">
//               Shop Instagram
//             </a>
// <span style={{display:"flex", justifyContent:"space-between", gap:"10px"}}>

//             <PrevArrow onClick={previous}/>
//             <NextArrow onClick={next}/>
// </span>
//           </p>
//     <Slider   ref={slider => {
//           sliderRef = slider;
//         }} {...settings} className="get-inspired-slider">
//       {inspiredDummyData.map((categoryData, index) => {
//         return (
//           <div key={index} className="px-3" style={{ padding: "0 15px" }}>
//             <div className="slider-card">
//               <Link className="carousole-photo position-relative">
//                 <img
//                   className="img-fluid cursor-pointer"
//                   src={categoryData.image}
//                   alt={categoryData.title}
//                   style={{ width: "100%", height: "auto", objectFit: "cover" }}
//                   onClick={() => navigate(categoryData.link)}
//                   />
//                 <div className="overlay">shop now</div>
//               </Link>
//             </div>
//           </div>
//         );
//       })}
//     </Slider>

//       </div>
//   );
// };
