import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Home from "../component/Home";
import LoginPage from "../component/element/LoginPage";
import ForgotPassword from "../component/element/ForgotPassword";
import OrderPlaced from "../component/OrderPlaced";
import Category from "../component/Category";
import Engagement from "../component/Engagement";
import Wedding from "../component/wedding";
import Account from "../component/Account";
import About from "../component/About";
import Contact from "../component/Contact";
import RequestForm from "../component/contact/request-form";
import Terms from "../component/pages/Terms";
import FAQ from "../component/FAQ";
import Cart from "../component/product/Cart";
import Checkout from "../component/product/Checkout";
import Blog from "../component/blog";
import BlogDetail from "../component/blog/Detail";
import SignUp from "../component/element/SignUp";
import RingStyle from "../component/jewelryGuide/RingStyle";
import PerfactRing from "../component/jewelryGuide/perfectRing";
import RightMetal from "../component/jewelryGuide/rightMetal";
import RingSize from "../component/jewelryGuide/ringSize";
import BuyRingSize from "../component/BuyRingSize";
import Privacy from "../component/pages/Privacy";
import Shipping from "../component/pages/Shipping";
import Refund from "../component/pages/Refund";
import EngagementCollection from "../component/Collections/EngagementCollection";
import WeddingCollection from "../component/Collections/WeddingCollection";
import JewelryCollection from "../component/Collections/JewelryCollection";
import SearchResult from "../component/product/SearchResult";
import OurPolicy from "../component/pages/OurPolicy";
import Detail from "../component/product/Detail";
import ProductList from "../component/product/Index";
import WhislistPage from "../component/product/WhislistPage";
import ProfilePage from "../component/pages/Profile/Profile";
import EditProfile from "../component/pages/Profile/EditProfile/EditProfile";
import WhishlistDetailsPage from "../component/product/WhishlistDetailsPage";
import Custom404 from "../component/404";
import ComingSoon from "../component/Coming-Soon";
import AppLayout from "../component/layout/appLayout";

const RoutePage = () => {
  const ProtectedRoute = ({ children }) => {
    if (!localStorage.getItem("token")) {
      return <Navigate to="/login" replace />;
    }
    return children ? children : <Outlet />;
  };

  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        {" "}
        {/* Wrapping all routes with AppLayout */}
        <Route path="/" exact element={<Home />} />
        <Route path="/login" exact element={<LoginPage />} />
        <Route path="/forgot-password" exact element={<ForgotPassword />} />
        <Route path="/order-placed" exact element={<OrderPlaced />} />
        <Route path="/jewelry" exact element={<Category />} />
        <Route path="/engagement" exact element={<Engagement />} />
        <Route path="/wedding" exact element={<Wedding />} />
        <Route path="/account" exact element={<Account />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/contact" exact element={<Contact />} />
        <Route path="/contact/:contact-form" exact element={<RequestForm />} />
        <Route path="/terms-and-conditions" exact element={<Terms />} />
        <Route path="/faq" exact element={<FAQ />} />
        <Route path="/cart" exact element={<Cart />} />
        <Route path="/checkout" exact element={<Checkout />} />
        <Route path="/blog" exact element={<Blog />} />
        <Route path="/blog-detail" exact element={<BlogDetail />} />
        <Route path="/signup" exact element={<SignUp />} />
        <Route path="/engagement-ring-style" exact element={<RingStyle />} />
        <Route
          path="/find-perfect-engagement-ring"
          exact
          element={<PerfactRing />}
        />
        <Route path="/find-right-metal" exact element={<RightMetal />} />
        <Route path="/find-ring-size" exact element={<RingSize />} />
        <Route
          path="/buying-guide/ring-size/"
          exact
          element={<BuyRingSize />}
        />
        <Route path="/privacy-policy" exact element={<Privacy />} />
        <Route path="/return" exact element={<Refund />} />
        <Route path="/shipping-policy" exact element={<Shipping />} />
        <Route
          path="/engagement-collection"
          exact
          element={<EngagementCollection />}
        />
        <Route
          path="/wedding-collection"
          exact
          element={<WeddingCollection />}
        />
        <Route
          path="/jewelry-collection"
          exact
          element={<JewelryCollection />}
        />
        <Route path="/search-result-page" exact element={<SearchResult />} />
        <Route path="/our-policy" exact element={<OurPolicy />} />
        <Route path="/:title/:sku" exact element={<Detail />} />
        <Route path="/:category" exact element={<ProductList />} />
        <Route path="/wishlist" exact element={<WhislistPage />} />
        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/profile" exact element={<ProfilePage />} />
          <Route path="/edit-profile" exact element={<EditProfile />} />
          <Route
            path="/wishlist/:id"
            exact
            element={<WhishlistDetailsPage />}
          />
        </Route>
        <Route path="/404" exact element={<Custom404 />} />
        <Route path="/coming-soon" exact element={<ComingSoon />} />
      </Route>
    </Routes>
  );
};

export default RoutePage;
