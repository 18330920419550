import React from "react";
import { Col } from "react-bootstrap";
import ShippingSvg from "../../assets/svgs/ShippingSvg";
import DimondSvg from "../../assets/svgs/DimondSvg";
import ReturnSvg from "../../assets/svgs/ReturnSvg";
import SavingSvg from "../../assets/svgs/SavingSvg";
import { Link } from "react-router-dom";
import GlobalTitle from "../common/global-title";
import { CustomContainer } from "../common/global-display";

const EngagementNoDealBreaker = ({ title = "GUARANTEE" }) => {
  const DealBreakers = [
    {
      icon: (
        <div
          style={{
            margin: "16px 0px",
            height: "40px",
          }}
        >
          <DimondSvg />
        </div>
      ),
      title: "Life time Warranty",
      link: "/our-policy#lifetime",
    },
    {
      icon: (
        <div
          style={{
            margin: "16px 0px",
            height: "40px",
          }}
        >
          <ReturnSvg />
        </div>
      ),
      title: "Hassle Free",
      link: "/our-policy#dimonds",
    },
    {
      icon: (
        <div
          style={{
            margin: "16px 0px",
            height: "40px",
          }}
        >
          <SavingSvg />
        </div>
      ),
      title: "Free resizing",
      link: "/our-policy#crafting",
    },
    {
      icon: (
        <div
          style={{
            margin: "16px 0px",
            height: "40px",
          }}
        >
          <ShippingSvg />
        </div>
      ),
      title: "Conflict free diamonds",
      link: "/our-policy#dimonds",
    },
  ];
  return (
    <CustomContainer>
      {!!title && <GlobalTitle title={title} />}
      {/* {!!title && <h2 className="text-center  pt-4  mb-3">{title}</h2>} */}
      <CustomContainer className=" small-container product-container-small mb-4 pt-2 mt-0">
        <div className="row ">
          {DealBreakers?.map((el) => {
            return (
              <Col xs={6} md={3} className=" text-center">
                <Link to={el?.link}>
                  {el?.icon}
                  <h6 className="text-secondary">{el?.title}</h6>
                </Link>
              </Col>
            );
          })}
        </div>
      </CustomContainer>
    </CustomContainer>
  );
};

export default EngagementNoDealBreaker;
