import React from "react";
import GlobalTitle from "../common/global-title";
import { CustomContainer } from "../common/global-display";

const Shipping = () => {
  return (
    <>
      <CustomContainer>
        <CustomContainer className="static-page">
          <GlobalTitle title="Shipping Policy" />
          <div className="">
            <p>
              We offer FREE shipping on every order only within India. Shipping
              time is approximately one (1) weeks for ‘Available in Stock’ items
              and approximately two (2) weeks for ‘Make to order’ items. Your
              order will be shipped to you fully insured. We urge all customers
              to inspect the package for any damage or tamper before receiving
              or signing for receipt. For our Indian customers, after your item
              has been packaged, it will be shipped and delivered free via the
              following carrier.
            </p>
            <ul>
              <li>Blue dart</li>
            </ul>
            <h3 className="sub-hd1 mb-2 pt-3">PACKAGING</h3>
            <p>
              Every jewellery package from us arrives at your doorstep in a
              durable, tamper-proof packing. Whether it is a jewellery or
              solitaire, your item is delivered to you in an exclusive{" "}
              <a href="https://www.andorluxury.com/">Andor Luxury</a> box along
              with the relevant certificates.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">FREE GIFT PACKAGING</h3>
            <p>
              In case your purchase is intended as a gift, we offer free gift
              packing, along with a gift message of your choice. Your gift will
              be delivered in distinctive Andor Luxury gift packing. So whether
              you order a gift to give to your loved ones personally, or want us
              to deliver it, the recipient is sure to be impressed.
            </p>
          </div>
        </CustomContainer>
      </CustomContainer>
    </>
  );
};

export default Shipping;
