import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Slider from "react-slick";
import SlideCard from "../Slider/slideCard";
import "./CardSlider.css"; // Assuming you have a CSS file for the styles

const CardSlider = ({
  image = "https://www.prmal.com/cdn/shop/files/0519_PRMAL4137_600x.progressive.jpg?v=1714372644",
  data = [],
  points = [],
  setShowModals,
  // showModal,
  isAboveDesktop,
  onAddToFavourite,
  handleRemove,
  refresher,
}) => {
  const sliderRef = useRef(null);
  const modalSliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (showModal && modalSliderRef.current) {
      modalSliderRef.current.slickGoTo(currentSlide);
    }
  }, [showModal, currentSlide]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipe: false,
    draggable: true,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    appendDots: (dots) => (
      <div className="dot-custom">
        <ul className="p-0 mb-0">{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className={`custom-dot ${currentSlide === i ? "active" : ""}`}
        onClick={() => handleDotClick(i)}
      >
        <span>{i + 1}</span>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const modalSliderSettings = {
    ...sliderSettings,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    customPaging: (i) => (
      <div
        className={`custom-dot ${currentSlide === i ? "active" : ""}`}
        onClick={() => handleDotClick(i)}
      >
        <span>{i + 1}</span>
      </div>
    ),
  };

  const handleDotClick = (i) => {
    setCurrentSlide(i);
    sliderRef.current.slickGoTo(i);
    if (showModal && modalSliderRef.current) {
      modalSliderRef.current.slickGoTo(i);
    }
  };

  const handleProductClick = () => {
    const lookSlider = document.querySelector(".look-slider");
    if (lookSlider) {
      lookSlider.style.pointerEvents = "none"; // Disable outer slider interactions
      const rect = lookSlider.getBoundingClientRect();
      // lookSlider.style.transform = `translate3d(0px, ${
      //   rect?.height - rect.bottom + 50
      // }px, 0px) scale(1.8, 1.6)`;
      lookSlider.style.transform = `translate3d(0px, ${
        rect?.height - rect.bottom
      }px, 0px) scale(1.2)`;
      lookSlider.style.transition = "transform 0.4s ease-in-out";
    }

    const headerSection = document.querySelector(".header-section");
    if (headerSection) {
      headerSection.style.transform = "translateY(-70px)";
      headerSection.style.transition = "transform 0.2s ease-in-out 0.2s";
    }
    setShowModals(true);

    setShowModal(true);
  };

  const handleCloseModal = () => {
    const lookSlider = document.querySelector(".look-slider");
    if (lookSlider) {
      lookSlider.style.transform = "";
      lookSlider.style.pointerEvents = "auto"; // Re-enable outer slider interactions
    }

    const headerSection = document.querySelector(".header-section");
    if (headerSection) {
      headerSection.style.transform = "";
      headerSection.style.transition = "";
    }

    setShowModal(false);
    setShowModals(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <Row
        className="flex align-items-center justify-content-center gx-5"
        style={{ rowGap: "10px" }}
      >
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center justify-content-md-end"
        >
          <div
            className="image-container left-dots"
            style={{ aspectRatio: "3/2" }}
          >
            <img
              className="left-dots"
              width={window.screen.width < 600 ? 250 : 365}
              height={window.screen.width < 600 ? 380 : 500}
              src={image}
              style={{ aspectRatio: "3/5", objectFit: "cover" }}
              alt="collection"
            />
            {points.map((elPoint, i) => (
              <div
                key={i}
                className={`dot ${currentSlide === i ? "active" : ""}`}
                onClick={() => {
                  handleDotClick(i);
                  if (!isAboveDesktop) {
                    handleProductClick();
                  }
                }}
                style={{
                  left: "165px",
                  top: "45%",
                  ...elPoint,
                }}
              ></div>
            ))}
          </div>
        </Col>
        <Col
          xs={12}
          md={5}
          className="product-card d-flex justify-content-center justify-content-md-start"
        >
          <div
            className="d-none d-md-block"
            style={{ width: "260px", height: "420px" }}
          >
            <Slider ref={sliderRef} {...sliderSettings}>
              {data.map((item, index) => (
                <div key={index} className="px-3">
                  <SlideCard item={item} />
                </div>
              ))}
            </Slider>
          </div>
          <div className="d-flex d-md-none mt-4">
            {!showModal && (
              <button
                onClick={handleProductClick}
                className="btn btn-theme-1 btn-black-outline text-black view-product-btn"
                style={{ width: "100%", borderRadius: "0px" }}
              >
                VIEW PRODUCT
              </button>
            )}
            <Modal
              show={showModal}
              dialogClassName="modal-50"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="slide-up-modal"
            >
              <Modal.Header className="gap-5">
                <button
                  type="button"
                  className="btn-close p-0 m-0"
                  onClick={handleCloseModal}
                  aria-label="Close"
                />
                <Modal.Title
                  className="text-center"
                  style={{ justifyContent: "space-between" }}
                >
                  SHOP THE LOOK
                </Modal.Title>
              </Modal.Header>
              <div className="modal-content-custo h-100">
                <Slider ref={modalSliderRef} {...modalSliderSettings}>
                  {data.map((item, index) => (
                    <div key={index} className="px-3">
                      {/* <ProductCard
                      produtInfo={item}
                      key={item.id}
                      item={item}
                      onAddToFavourite={onAddToFavourite}
                      handleRemove={handleRemove}
                    /> */}
                      <SlideCard item={item} refresher={refresher} />
                    </div>
                  ))}
                </Slider>
              </div>
            </Modal>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CardSlider;
