import Loader from "../UI/Loader";
import SlideCard from "./slideCard";
import SliderComponent from "./sliderComponent";
function ImageSliderNew({ data, getOnclick, productKey, title }) {
  const responsive = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ];

  return (
    <>
      {data?.length == 0 ? (
        <div className="text-center" style={{ height: "400px" }}>
          <Loader />
          {/* <strong>No data Available</strong> */}
        </div>
      ) : (
        <div className="slider-container ">
          {/* <CustomContainer className=""> */}
          <SliderComponent
            // title={"Related Products"}
            title={title}
            slidesToShow={data.length > 4 ? 4 : data?.length}
            slidesToScroll={data.length > 4 ? 4 : data?.length}
            responsive={responsive}
            buttonPosY="40%"
            data={
              data?.map((item) => {
                return (
                  <SlideCard
                    item={item}
                    getOnclick={getOnclick}
                    productKey={productKey}
                  />
                );
              }) ?? []
            }
          />
          {/* </CustomContainer> */}
        </div>
      )}
    </>
  );
}

export default ImageSliderNew;
