import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Dropdown, Nav } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import UserFill from "../../assets/svgs/UserFill";
import LoginForm from "../auth/LoginForm";
import CartIcon from "../cutomIconButton/cartIcon";
import WishlistIcon from "../cutomIconButton/wishlistIcon";
import CountrySelector from "../element/CountrySelector";
import EngagementCategory from "./category/engagementCategory";
import JewelryCategory from "./category/jewelryCategory";
import WeddingCategory from "./category/weddingCategory";
import { CustomContainer } from "../common/global-display";

const CategoryMenu = ({
  isSticky,
  closeOffcanvas,
  sidemenuClose,
  wishListData,
  sidemenuShow,
  cartData,
  isHeaderTextWhite,
}) => {
  const [isShown, setIsShown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location?.pathname;
  const regUser = localStorage.getItem("user");
  const handleProfileClick = () => {
    navigate("/profile");
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.screen.width < 991);

  useEffect(() => {
    setIsSmallScreen(window.screen.width < 991);
  }, []);

  return (
    <>
      <Nav className="justify-content-center flex-grow-1 pe-lg-3 header-section-hover-maintainer">
        {/* ===================== Engagement Category ===================== */}
        <EngagementCategory
          isHeaderTextWhite={isHeaderTextWhite}
          setIsShown={setIsShown}
          isShown={isShown}
          isSticky={isSticky}
          closeOffcanvas={closeOffcanvas}
          isSmallScreen={isSmallScreen}
          path={path}
        />

        {/* ===================== Wedding Category ===================== */}
        <WeddingCategory
          isHeaderTextWhite={isHeaderTextWhite}
          setIsShown={setIsShown}
          isShown={isShown}
          isSticky={isSticky}
          closeOffcanvas={closeOffcanvas}
          isSmallScreen={isSmallScreen}
          path={path}
        />

        {/* ===================== Jewelry Category ===================== */}
        <JewelryCategory
          isHeaderTextWhite={isHeaderTextWhite}
          setIsShown={setIsShown}
          isShown={isShown}
          isSticky={isSticky}
          closeOffcanvas={closeOffcanvas}
          isSmallScreen={isSmallScreen}
          path={path}
        />
        <Dropdown
          className="full-menu-dropdown nav-item"
          onClick={() => {
            setIsShown(!isShown ? "company" : false);
          }}
          onMouseEnter={() => setIsShown("company")}
          onMouseLeave={() => setIsShown(false)}
          show={isShown === "company"}
        >
          <Dropdown.Toggle className="p-0 btn border-0 bg-transparent nav-link d-lg-none text-center w-100">
            <span
              // className={`${isHeaderTextWhite ? "text-white" : ""} `}
              onClick={() => {
                navigate("/wedding");
                closeOffcanvas();
              }}
              // className="btn-close"
            >
              COMPANY
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className={classNames({
              isMenuShow: !!isSmallScreen && isShown === "company",
            })}
          >
            <CustomContainer className="mt-0 mb-0">
              <div className="mt-2 mb-3">
                <div
                  className="mobile-side-bar-list"
                  onClick={() => {
                    sidemenuClose();
                    closeOffcanvas();
                    navigate("/about");
                  }}
                >
                  {" "}
                  Our story
                </div>
              </div>
              <div className="mt-2 mb-3">
                <div
                  className="mobile-side-bar-list"
                  onClick={() => {
                    sidemenuClose();
                    closeOffcanvas();
                    navigate("/contact");
                  }}
                >
                  {" "}
                  CONTACT US
                </div>
              </div>
            </CustomContainer>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className=" account-menu-dropdown nav-item">
          <Dropdown.Toggle className="p-0 btn border-0 bg-transparent nav-link d-lg-none text-center w-100 no-caret">
            <div
              onClick={() => {
                sidemenuClose();
                closeOffcanvas();
                // navigate("/profile");
              }}
            >
              {/* ===================== Jewelry Category ===================== */}
              {/* <JewelryCategory
                    setIsShown={setIsShown}
                    isShown={isShown}
                    isSticky={isSticky}
                    closeOffcanvas={closeOffcanvas}
                    isSmallScreen={isSmallScreen}
                    path={path}

                /> */}

              <Dropdown className="full-menu-dropdown account-menu-dropdown nav-item">
                <Dropdown.Toggle className="p-0 btn border-0 bg-transparent nav-link d-lg-none text-center w-100 no-caret">
                  <div
                    onClick={() => {
                      sidemenuClose();
                      closeOffcanvas();
                      navigate("/profile");
                    }}
                    className="user-icon menu-slide-icon"
                  >
                    <span className="menu-icon">
                      <UserFill
                        className="user-icon-in"
                        strokeColor={"#e77e50"}
                        onClick={() => {
                          if (regUser !== null) {
                            handleProfileClick();
                          }
                        }}
                      />
                    </span>
                    ACCOUNT
                  </div>
                </Dropdown.Toggle>
              </Dropdown>
              <Dropdown className="full-menu-dropdown account-menu-dropdown nav-item">
                <Dropdown.Toggle className="p-0 btn border-0 bg-transparent nav-link d-lg-none text-center w-100 no-caret">
                  {/* <div
                            onClick={() => {
                                sidemenuClose();
                                closeOffcanvas();
                                navigate("/profile");
                            }}
                        >
                            ACCOUNT
                        </div> */}
                  <WishlistIcon
                    // isHeaderTextWhite={isHeaderTextWhite}
                    wishListData={wishListData}
                    countStyle={{ left: "0", top: "10%" }}
                    title="WHISHLIST"
                    onClick={() => {
                      sidemenuClose();
                      closeOffcanvas();
                    }}
                  />
                </Dropdown.Toggle>
              </Dropdown>
              <Dropdown className="full-menu-dropdown account-menu-dropdown nav-item">
                <Dropdown.Toggle className="p-0 btn border-0 bg-transparent nav-link d-lg-none text-center w-100 no-caret">
                  <CartIcon
                    data={cartData}
                    onClick={sidemenuShow}
                    countStyle={{ left: "0", top: "20%" }}
                    title="MY BAG"
                  />
                </Dropdown.Toggle>
              </Dropdown>
            </div>

            <div className="d-none d-lg-flex gap-3 header-buttons justify-content-between align-items-center mt-5 mt-md-0">
              <Dropdown autoClose="outside" align={"end"} className="login-drp">
                <Dropdown.Toggle
                  className="d-none p-0 d-lg-block user-icon"
                  variant="success"
                  id="dropdown-basic"
                >
                  <UserFill
                    className="user-icon-in"
                    strokeColor={isHeaderTextWhite ? "white" : "#e77e50"}
                    onClick={() => {
                      if (regUser !== null) {
                        handleProfileClick();
                      }
                    }}
                  />
                  <small
                    className={`${regUser !== null ? "ps-2" : "d-none"} small ${
                      isHeaderTextWhite ? "text-white" : "text-primary"
                    } `}
                    onClick={() => {
                      if (regUser !== null) {
                        handleProfileClick();
                      }
                    }}
                  >
                    {regUser !== null
                      ? `Hi, ${localStorage.getItem("user_name")} `
                      : ""}
                  </small>
                </Dropdown.Toggle>

                {regUser ? null : (
                  <Dropdown.Menu className="auth-menu">
                    <LoginForm sm={true} />
                  </Dropdown.Menu>
                )}
              </Dropdown>

              <CartIcon
                isHeaderTextWhite={isHeaderTextWhite}
                data={cartData}
                onClick={sidemenuShow}
              />

              <CountrySelector
                className={`d-none d-lg-block ${
                  isHeaderTextWhite ? "text-white" : "text-primary"
                }`}
              />
            </div>
          </Dropdown.Toggle>
        </Dropdown>
      </Nav>

      <div className="d-none d-lg-flex gap-3 header-buttons justify-content-between align-items-center mt-5 mt-md-0">
        <Dropdown autoClose="outside" align={"end"} className="login-drp">
          <Dropdown.Toggle
            className="d-none p-0 d-lg-flex user-icon ustify-content-center align-items-center"
            variant="success"
            id="dropdown-basic"
          >
            <UserFill
              className="user-icon-in"
              strokeColor={isHeaderTextWhite ? "white" : "#e77e50"}
              onClick={() => {
                if (regUser !== null) {
                  handleProfileClick();
                }
              }}
            />
            <small
              className={`${regUser !== null ? "ps-2" : "d-none"} small ${
                isHeaderTextWhite ? "text-white" : "text-primary"
              } `}
              onClick={() => {
                if (regUser !== null) {
                  handleProfileClick();
                }
              }}
            >
              {regUser !== null
                ? `Hi, ${localStorage.getItem("user_name")} `
                : ""}
            </small>
          </Dropdown.Toggle>

          {regUser ? null : (
            <Dropdown.Menu className="auth-menu">
              <LoginForm sm={true} />
            </Dropdown.Menu>
          )}
        </Dropdown>

        {/* ===========================LAPTOP SCREEN ONLY================================ */}
        <WishlistIcon
          isHeaderTextWhite={isHeaderTextWhite}
          wishListData={wishListData}
        />
        <CartIcon
          isHeaderTextWhite={isHeaderTextWhite}
          data={cartData}
          onClick={sidemenuShow}
        />
      </div>
    </>
  );
};

export default CategoryMenu;
