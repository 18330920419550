import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import { currencyIcon } from "../../constants/function";
import formatCurrency from "../../helper/currencyFormater";
import ProductCartSidebar from "../cart/ProductCartSidebar";
import SignIn from "../element/SignIn";

const CartSidebar = ({
  sidemenu,
  sidemenuClose,
  cartData,
  countryCurrency,
}) => {
  const [show, setShow] = useState(false);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (cartData.length > 0) {
      const totalAmount = cartData.reduce((acc, curr) => {
        const qty = curr.qty;
        const price = curr?.amount;
        const itemTotal = qty * price;

        return acc + itemTotal;
      }, 0);
      // Assuming setTotal is a state updater function
      setTotal(totalAmount);
    }
  }, [cartData]);

  return (
    <>
      <SignIn
        show={show}
        onHide={() => {
          setShow(false);
        }}
      />
      <Offcanvas
        show={sidemenu}
        onHide={sidemenuClose}
        placement="end"
        className="sidemenu"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className=" w-100"> <h3 className="text-center w-100">Your Bag</h3></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
         
          <hr />

          <div
            className="cartItems-container overflow-auto hide-scrollbar"
            style={{ height: "60vh" }}
          >
            {/* for geting error */}
            {cartData &&
              cartData?.length > 0 &&
              cartData?.map((item) => {
                const { sku, shop_by_style } = item?.product;
                const produtInfo = {
                  name: item?.product?.title,
                  caption: item?.product?.caption,
                  // sku: item?.product?.sku,
                  type: item?.product?.Necklaces,
                  imgUrl: item?.product?.featured_image[0]?.original_url,
                  hoverImageUrl: item?.product?.featured_image[1]?.original_url,
                  id: item?.product?.id,
                  currency: item?.currency,
                  // price:
                  //   countryCurrency == "INR"
                  //     ? formatCurrency(item?.product?.product_price)
                  //     : countryCurrency == "USD"
                  //     ? formatCurrency(item?.product?.usa_price)
                  //     : countryCurrency == "GBP"
                  //     ? formatCurrency(item?.product?.uk_price)
                  //     : formatCurrency(item?.product?.usa_price),
                  price: item?.amount,
                  sku: sku,
                  style: shop_by_style,
                  // style : item?.product?.caption,
                  qty: item.qty,
                  productCartId: item.id,
                  ...item?.product,
                };

                return (
                  <ProductCartSidebar
                    {...produtInfo}
                    fullDeatials={{
                      ...item,
                      ...item?.product,
                      // size: item?.product?.Productsize,
                    }}
                    sidemenuClose={sidemenuClose}
                  />
                );
              })}
            {cartData?.length === 0 && (
              <div className="text-center mt-5">
                <p>Your Bag is Empty!</p>
                <Link to={"/"}>
                  <span
                    className="remove-sidebar-btn"
                    onClick={() => {
                      sidemenuClose();
                    }}
                  >
                    Let's Start Shopping
                  </span>
                </Link>
              </div>
            )}
          </div>
          {cartData && cartData?.length > 0 && (
            <div className="">
              <hr />
              <div className="d-flex justify-content-between">
                <p style={{ marginRight: "10px" }}>SUBTOTAL: </p>
                <p className="d-flex align-items-center ">
                  {currencyIcon(cartData?.[0]?.currency ?? countryCurrency)}{" "}
                  {/* {"₹"}<LiaRupeeSignSolid /> */}
                  <div>{` ${formatCurrency(Number(total).toFixed(2))}`}</div>
                </p>
              </div>
              <div className="d-flex justify-content-center mt-2">
                <Link
                  // to={isLogin() ? "/checkout" : "/login"}
                  // to={isLogin() ? "/cart" : "/login"}
                  to={`/cart`}
                  // to={"/checkout"}
                  onClick={() => {
                    sidemenuClose();
                  }}
                  style={{
                    margin : 0,
                  }}
                  className="btn-theme bg-primary border-0 text-white btn btn-primary btn-full"
                >
                  Checkout
                </Link>
              </div>
              {/* <div className="d-flex w-full align-items-center justify-content-center mt-2 text-center">
                  <Link
                    // to={isLogin() ? "/cart" : "/login"}
                    to={"/cart"}
                  >
                    <a
                      className="remove-sidebar-btn text-center "
                      onClick={() => {
                        sidemenuClose();
                      }}
                    >
                      View you bag
                    </a>
                  </Link>
                </div> */}
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CartSidebar;
