import React, { useState } from "react";
import classNames from "classnames";
import { IoIosCloseCircleOutline, IoMdAttach } from "react-icons/io";

const FileUpload = ({ name, handleChange, errors, touched, disabled }) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const onFileChange = (event) => {
    setLoading(true);
    const selectedFiles = Array.from(event.target.files);

    // Simulate file upload
    setTimeout(() => {
      setLoading(false);
      if (selectedFiles.length) {
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
        handleChange(event);
      }
    }, 1000); // Simulating a 1 second upload time
  };

  const removeFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  return (
    <div>
      <input
        id={name}
        type="file"
        name={name}
        onChange={onFileChange}
        className="hidden-file-input"
        disabled={disabled}
        multiple
      />
      <label
        htmlFor={name}
        className={classNames("form-control file-input-label", {
          "error-border": errors[name] && touched[name],
        })}
      >
        {loading ? (
          <span>Uploading...</span>
        ) : (
          <span>Add file or drop files here</span>
        )}
      </label>
      {files.length > 0 && (
        <ul className="file-list row">
          {files.map((file, index) => (
            <li key={index} className="col-12 col-md-6  ">
              <div className="file-list-item px-2">
                <div className="">
                  <IoMdAttach className="" style={{ marginRight: "10px" }} />
                  <span className="">{file.name}</span>
                </div>
                <IoIosCloseCircleOutline
                  color="red"
                  size={18}
                  className="cursor-pointer"
                  onClick={() => removeFile(file.name)}
                />
                {/* <button type="button" >
                                    Remove
                                </button> */}
              </div>
            </li>
          ))}
        </ul>
      )}
      <style jsx>{`
        .hidden-file-input {
          display: none;
        }
        .file-input-label {
          display: inline-block;
          padding: 10px 20px;
          border: 2px dashed #ccc;
          border-radius: 5px;
          text-align: center;
          cursor: pointer;
          background-color: #f9f9f9;
        }
        .file-input-label.error-border {
          border-color: red;
        }
        .file-input-label:hover {
          background-color: #f0f0f0;
        }
        .file-list {
          list-style: none;
          padding: 0;
          margin: 10px 0 0;
        }
        .file-list-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 0;

          svg {
            cursor: pointer;
          }
        }
        .file-list-item:hover {
          background-color: #ffede4;
        }
        .remove-button {
          margin-left: 10px;
          padding: 5px 10px;
          background-color: red;
          color: white;
          border: none;
          border-radius: 3px;
          cursor: pointer;
        }
        .remove-button:hover {
          color: darkred;
        }
      `}</style>
    </div>
  );
};

export default FileUpload;
