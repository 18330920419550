import React from "react";
import GlobalTitle from "../common/global-title";
import { CustomContainer } from "../common/global-display";

const MeasureRingatHome = () => {
  return (
    <CustomContainer className="mt-0 mb-0">
      <GlobalTitle title="How to Measure Ring Size at Home" />
      <div className="row justify-content-center mt-2">
        <div className="col-12 col-md-10 col-lg-8">
          <div className="text-muted">
            <p>
              The three easiest ways to measure your ring size without our free
              sizer are:
            </p>
            <ul>
              <li>The string method</li>
              <li>Using a printable ring sizer</li>
              <li>
                Measuring an existing ring to determine a size that already fits
              </li>
            </ul>
            <p>
              These methods require our Ring Sizer & Conversion Chart. To print
              the chart for methods two or three, set page scaling to "100" in
              your print settings and use 8.5 x 11-inch standard paper.
            </p>
          </div>
        </div>
      </div>
    </CustomContainer>
  );
};

export default MeasureRingatHome;
