import React from "react";
import { Container } from "react-bootstrap";
import { imageURL2 } from "../../images";
import { CustomContainer } from "../common/global-display";

const HeroRingSize = () => {
  return (
    <CustomContainer className="mt-0">
      <div className="w-100 mb-5 ">
        <div className="bg-light mx-auto relative min-1440px:flex min-1440px:h-full flex-col mb-14 lg:mb-20">
          <div
            className="w-100 inline-block overflow-hidden h-full relative max-1023px:aspect-6/4 max-1023px:block"
            style={{
              maxHeight: "60vh",
            }}
          >
            <div className="w-100 inline-block overflow-hidden h-full relative">
              <picture>
                <img
                  loading="eager"
                  fetchpriority="high"
                  src={imageURL2?.image_135}
                  alt="Model wearing an assortment of yellow gold rings"
                  className="w-100 h-100 object-fit-cover "
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </CustomContainer>
  );
};

export default HeroRingSize;
