import * as Yup from "yup";

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const updateUserdetails = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  mobile: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]+$/, "Phone number must be only digits"),
  birthday_date: Yup.string().required("Birthday date is required"),
  birthday_month: Yup.string().required("Birthday month is required"),
});

export const passwordChangeSchema = Yup.object().shape({
  old_password: Yup.string().required("Old Password is required"),
  new_password: Yup.string()
    .required("New Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[a-zA-Z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
      "Password must have at least one uppercase letter, one lowercase letter, one number, and one special symbol",
    ),
  confirmPassword: Yup.string()
    .required("Please confirm your new password")
    .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
});

export const registerUserdetailsSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  mobile: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]+$/, "Phone number must be only digits"),
  birthday_date: Yup.string().required("Birthday date is required"),
  birthday_month: Yup.string().required("Birthday month is required"),
  password: Yup.string()
    .required(" Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[a-zA-Z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
      "Password must have at least one uppercase letter, one lowercase letter, one number, and one special symbol",
    ),
  isCheck: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
});

export const userAddressListSchema = Yup.object().shape({
  firstname: Yup.string().required("First Name is required"),
  lastname: Yup.string().required("Last Name is required"),
  // name: Yup.string().required("Name is required"),
  address1: Yup.string().required("Address Line 1 is required"),
  address2: Yup.string(),
  // address2: Yup.string().required("Address Line 2 is required"),
  city: Yup.string().required("City is required"),
  postal_code: Yup.string().required("Postal code is required"),
  country_id: Yup.string().required("Country is required"),
  state_id: Yup.string().required("State is required"),
});
